import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Routes from '../common/constants/routes';
import PaxInsertFormContainer from '../pax/components/PaxInsertFormContainer';
import PaxUpdateFormContainer from '../pax/components/PaxUpdateFormContainer';
import PaxCabinCategoryUpgrade from '../pax/components/PaxCabinCategoryUpgradeContainer';
import VoyageManagementContainer from '../voyage/components/VoyageManagementContainer';
import AllotmentInsertContainer from '../allotment/components/AllotmentInsertContainer';
import AllotmentUpdateContainer from '../allotment/components/AllotmentUpdateContainer';
import AllotmentSubmitted from '../allotment/components/AllotmentSubmitted';
import PaxDetailsSubmitted from '../pax/components/PaxDetailsSubmitted';
import ManageAllotmentsContainer from '../allotment/components/ManageAllotmentsContainer';
import CheckAvailabilityContainer from '../allotment/components/CheckAvailabilityContainer';
import CabinAssignmentContainer from '../allotment/components/CabinAssignmentContainer';
import NotFound404 from '../common/components/NotFound404';
import ManageBookingsContainer from '../bookings/components/ManageBookingsContainer';
import OverviewPage from '../bookings/components/OverviewPage';
import ProtectedRoute from '../common/components/ProtectedRoute';
import Role from '../common/enums/Role';
import { useUser } from '../common/contexts/UserContext';
import PreTravelInformationContainer from '../voyage/components/PreTravelInformationContainer';
import PassengerDetailsContainer from '../voyage/components/PassengerDetailsContainer';

const RouteSwitch: React.FC = () => {
  const user = useUser();

  if (user === undefined) return null;

  return (
    <Switch>
      <ProtectedRoute
        isAuthorized={user.hasRole(Role.OperationsManager)}
        exact
        path={Routes.VoyageManagement.route}
        component={VoyageManagementContainer}
      />
      <ProtectedRoute
        isAuthorized={user.hasRole(
          Role.SalesConsultant,
          Role.OperationsManager
        )}
        exact
        path={Routes.CheckAvailability.route}
        component={CheckAvailabilityContainer}
      />
      <ProtectedRoute
        isAuthorized={user.hasRole(Role.OperationsManager)}
        exact
        path={Routes.AllotmentManagement.route}
        component={ManageAllotmentsContainer}
      />
      <ProtectedRoute
        isAuthorized={user.hasRole(Role.OperationsManager)}
        exact
        path={Routes.AllotmentInsert.route}
        component={AllotmentInsertContainer}
      />
      <ProtectedRoute
        isAuthorized={user.hasRole(Role.OperationsManager)}
        exact
        path={Routes.AllotmentUpdate.route}
        component={AllotmentUpdateContainer}
      />
      <ProtectedRoute
        isAuthorized={user.hasRole(Role.OperationsManager)}
        exact
        path={Routes.AllotmentSubmitted.route}
        component={AllotmentSubmitted}
      />
      <ProtectedRoute
        isAuthorized={user.hasRole(
          Role.SalesConsultant,
          Role.OperationsManager
        )}
        exact
        path={Routes.PaxInsert.route}
        component={PaxInsertFormContainer}
      />
      <ProtectedRoute
        isAuthorized={user.hasRole(
          Role.SalesConsultant,
          Role.OperationsManager
        )}
        exact
        path={Routes.PaxUpdate.route}
        component={PaxUpdateFormContainer}
      />
      <ProtectedRoute
        isAuthorized={user.hasRole(Role.OperationsManager)}
        exact
        path={Routes.PaxCabinCategoryUpgrade.route}
        component={PaxCabinCategoryUpgrade}
      />
      <ProtectedRoute
        isAuthorized={user.hasRole(
          Role.SalesConsultant,
          Role.OperationsManager
        )}
        exact
        path={Routes.PaxSubmitted.route}
        component={PaxDetailsSubmitted}
      />
      <ProtectedRoute
        isAuthorized={user.hasRole(Role.OperationsManager)}
        exact
        path={Routes.CabinAssignment.route}
        component={CabinAssignmentContainer}
      />
      <ProtectedRoute
        isAuthorized={user.hasRole(
          Role.SalesConsultant,
          Role.OperationsManager
        )}
        exact
        path={Routes.ManageBookings.route}
        component={ManageBookingsContainer}
      />
      <ProtectedRoute
        isAuthorized={user.hasRole(
          Role.SalesConsultant,
          Role.OperationsManager
        )}
        exact
        path={Routes.PreTravelInformation.route}
        component={PreTravelInformationContainer}
      />
      <ProtectedRoute
        isAuthorized={user.hasRole(
          Role.OE_OfficeBasedTeam,
          Role.OE_GroundTeam,
          Role.OE_LandDoctors
        )}
        exact
        path={Routes.PassengerDetails.route}
        component={PassengerDetailsContainer}
      />
      <ProtectedRoute
        isAuthorized={user.hasRole(
          Role.SalesConsultant,
          Role.OperationsManager
        )}
        exact
        path={Routes.Overview.route}
        component={OverviewPage}
      />
      <Route component={NotFound404} />
    </Switch>
  );
};

export default RouteSwitch;
