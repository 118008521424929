import React, { useMemo } from 'react';
import FancyTable from '../../common/components/FancyTable';
import { Column, TableState, Filters } from 'react-table';
import createFullName from '../../common/helpers/createFullName';
import { Container } from '@material-ui/core';
import formatDate from '../../common/helpers/formatDate';
import { IconButton, Tooltip } from '@material-ui/core';
import Routes from '../../common/constants/routes';
import { Link as RouterLink } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import IVoyage from '../../common/types/IVoyage';
import { brandLabels } from '../../pax/enums/brand';
import MultipleSelectFilter from '../../common/components/MultipleSelectFilter';
import PaxExtraServiceSearchResult from '../../common/types/PaxExtraServiceSearchResult';
import PaxExtraServiceStatus, {
  paxExtraServiceStatusLabels,
} from '../../pax/enums/paxExtraServiceStatus';
import IPaxExtraService from '../../common/types/IPaxExtraService';
import { FormPageTab } from '../../pax/components/PaxListForm';
import { useUser } from '../../common/contexts/UserContext';

type Props = {
  pes: PaxExtraServiceSearchResult[];
  voyages: IVoyage[];
  onFetchData: ({
    filters,
    pageIndex,
    pageSize,
  }: {
    filters?: Filters<PaxExtraServiceSearchResult>;
    pageIndex?: number;
    pageSize?: number;
  }) => Promise<void>;
  totalRecords?: number;
  pageCount?: number;
};

const statusDate = ({
  status,
  cancelledDate,
  confirmedDate,
  optionExpiryDate,
  expiredOptionDate,
}: Pick<
  IPaxExtraService,
  | 'status'
  | 'confirmedDate'
  | 'cancelledDate'
  | 'expiredOptionDate'
  | 'optionExpiryDate'
>): Date | null | undefined => {
  switch (status) {
    case PaxExtraServiceStatus.Cancelled:
      return cancelledDate;
    case PaxExtraServiceStatus.Confirmed:
      return confirmedDate;
    case PaxExtraServiceStatus.Option:
      return optionExpiryDate;
    case PaxExtraServiceStatus.ExpiredOption:
      return expiredOptionDate;
    default:
      return;
  }
};

const paxExtraServiceStatusStatusDateTooltips: Readonly<Record<
  PaxExtraServiceStatus,
  string
>> = {
  [PaxExtraServiceStatus.Option]: 'Option Expiry Date',
  [PaxExtraServiceStatus.Confirmed]: 'Confirmed Date',
  [PaxExtraServiceStatus.Cancelled]: 'Cancelled Date',
  [PaxExtraServiceStatus.ExpiredOption]: 'Expired Option Date',
};

const ExtraServiceOverview: React.FC<Props> = ({
  pes,
  voyages,
  onFetchData,
  totalRecords,
  pageCount,
}) => {
  const user = useUser();
  const columns: Column<PaxExtraServiceSearchResult>[] = useMemo(
    () => [
      {
        Header: 'Voyage',
        accessor: ({ voyageId }) =>
          voyages.find((v) => v.id === voyageId)?.tripCode,
      },
      {
        Header: 'Activity',
        accessor: 'extraServiceName',
      },
      {
        Header: 'Brand',
        accessor: 'brand',
        Cell: ({ value }) => brandLabels[value],
        Filter: (x) =>
          user.brands.length > 1 ? MultipleSelectFilter(x, user.brands) : null,
        filter: (rows, _columnIds) => rows, // Disable filter as filter is server-side
      },
      {
        Header: 'Booking Ref',
        accessor: 'bookingReference',
        disableGlobalFilter: false,
      },
      {
        Header: 'Name',
        accessor: ({ firstName, middleName, surname }) =>
          createFullName(firstName, middleName, surname),
        disableGlobalFilter: false,
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value }) => (value ? paxExtraServiceStatusLabels[value] : ''),
        Filter: (x) =>
          MultipleSelectFilter(
            x,
            Object.values(PaxExtraServiceStatus),
            PaxExtraServiceStatus.Option
          ),
        filter: (rows, _columnIds) => rows, // Disable filter as filter is server-side
      },
      {
        Header: 'Status Date',
        id: 'statusDate',
        accessor: statusDate,
        Cell: ({
          value,
          row: {
            values: { status },
          },
        }: {
          value: Date | null | undefined;
          row: { values: { status: PaxExtraServiceStatus } };
        }) => {
          return (
            value && (
              <Tooltip title={paxExtraServiceStatusStatusDateTooltips[status]}>
                <span>{formatDate(value)}</span>
              </Tooltip>
            )
          );
        },
        sortType: 'datetime',
        disableSortBy: false,
      },
      {
        Header: 'Edit',
        accessor: 'groupId',
        Cell: ({ value }) => (
          <Tooltip title="Edit">
            <IconButton
              aria-label="edit"
              color="inherit"
              component={RouterLink}
              to={Routes.PaxUpdate.getLocation(
                value,
                FormPageTab.ExtraServices
              )}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        ),
      },
    ],
    [user.brands, voyages]
  );

  const initialState: Partial<TableState<
    PaxExtraServiceSearchResult
  >> = useMemo(
    () => ({
      sortBy: [{ id: 'statusDate', desc: false }],
      pageSize: 25,
    }),
    []
  );

  const defaultColumn = useMemo(
    () => ({
      Filter: <React.Fragment />,
      disableSortBy: true,
    }),
    []
  );

  return (
    <Container maxWidth="lg">
      <FancyTable
        columns={columns}
        data={pes}
        initialState={initialState}
        defaultColumn={defaultColumn}
        onFetchData={onFetchData}
        totalRecords={totalRecords}
        pageCount={pageCount}
        manualSortBy
      />
    </Container>
  );
};

export default ExtraServiceOverview;
