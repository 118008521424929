import { createStyles } from '@material-ui/core/styles';

export default createStyles({
  sectionContainer: {
    textAlign: 'left',
  },
  sectionUpgradedContainer: {
    textAlign: 'left',
    paddingTop: '35px',
  },
  voyageContainer: {
    textAlign: 'left',
    paddingBottom: '15px',
  },
  paxTableHeader: {
    backgroundColor: '#EFF6FF',
  },
  saveButton: {
    margin: '20px 15px 5px 5px',
  },
  tooltip: {
    padding: '0 12px 0 12px',
  },
  singleSupplementContainer: {
    padding: '0 10px 0 10px',
  },
  removeUpgradeButton: {
    paddingBottom: '15px',
  },
  paxCellCapitalize: {
    'text-transform': 'capitalize',
  },
});
