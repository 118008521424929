import { createStyles } from '@material-ui/core/styles';

export const transitionDuration = 300;

export default createStyles({
  inputField: {
    width: '200px',
  },
  selectFieldsContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flexWrap: 'wrap',
    'margin-bottom': '25px',
  },
  dragAndDropContainer: {
    display: 'flex',
    'justify-content': 'space-between',
    'max-width': '800px',
    margin: '0 auto;',
  },
  dragAndDropSectionContainer: {
    marginLeft: '10px',
    marginRight: '10px',
  },
  disabledDragAndDrop: {
    'pointer-events': 'none',
    opacity: '0.4',
  },
  saveButton: {
    margin: '20px 15px 5px 5px',
  },
  cabinNumberTitle: {
    color: 'Brown',
  },
  sharingGroupContainer: {
    margin: '0px 0px 10px 0px',
  },
  singleSupplementContainer: {
    fontSize: 'small',
    color: 'khaki',
    textAlign: 'right',
  },
  allomentContainer: {
    fontSize: 'small',
    color: 'khaki',
    textAlign: 'left',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  updateCabinCategoryContainer: {
    margin: '10px 0px 0px 0px',
  },
  updateCabinCategoryLinkContainer: {
    padding: '5px 0 0 0',
    fontSize: 'small',
    textAlign: 'right',
  },
  subHeaderPaper: {
    width: '100%',
    textAlign: 'left',
    paddingLeft: '15px',
    paddingRight: '15px',
  },
  cabinAssignmentPaxDetails: {
    transition: `opacity ${transitionDuration}ms ease-in-out`,
  },
  cabinAssignmentPaxDetailsEntering: {
    opacity: 0.85,
  },
  cabinAssignmentPaxDetailsEntered: {
    opacity: 0.85,
  },
  cabinAssignmentPaxDetailsExiting: {
    opacity: 1,
  },
  cabinAssignmentPaxDetailsExited: {
    opacity: 1,
  },
  cabinAssignmentPaxDetailsUnmounted: {
    opacity: 1,
  },
  cabinDetailsTypography: {
    textAlign: 'center',
    paddingBottom: '5px',
  },
  announcementIcon: {
    marginLeft: '10px',
    color: 'red',
  },
});
