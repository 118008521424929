import { createStyles } from '@material-ui/core/styles';

export default createStyles({
  additionalAuditChange: {
    visibility: 'hidden'
  },
  columnName: {
    fontWeight: 'bold'
  }
});
