import React from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@material-ui/core';
import env from '../../core/env';
import formatDate from '../../common/helpers/formatDate';
import IAuditRecord from '../../common/types/IAuditRecord';
import styles from './PaxAuditHistory.styles';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { orderBy } from 'lodash';

interface IProps {
  auditHistory: IAuditRecord[];
}

const format = (value: string | Date | null) => {
  if(value === null) {
    return '-';
  }

  if(value instanceof Date) {
    return formatDate(value, env.REACT_APP_DATETIME_FORMAT);
  }

  return value;
}

const useStyles = makeStyles(styles);

const PaxAuditHistory: React.FC<IProps> = ({ auditHistory }) => {
  const classes = useStyles();

  return (
    <div>
      <Table
        size="small"
        aria-label="Allotment availability summary"
        stickyHeader
      >
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>User</TableCell>
            <TableCell>Entity</TableCell>
            <TableCell>Identifier</TableCell>
            <TableCell>Action</TableCell>
            <TableCell>Value</TableCell>
            <TableCell>Original</TableCell>
            <TableCell>New</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {
          orderBy(auditHistory, (v) => v.date, 'desc').flatMap(ah => {
            const key = `${ah.date.getTime()}-${ah.table}-${ah.primaryKey}`;

            if(ah.changes === null || ah.changes.length === 0) {
              return (
                <TableRow key={key}>
                  <TableCell>{formatDate(ah.date, env.REACT_APP_DATETIME_FORMAT)}</TableCell>
                  <TableCell>{ah.by}</TableCell>
                  <TableCell>{ah.table}</TableCell>
                  <TableCell>{ah.primaryKey}</TableCell>
                  <TableCell>{ah.action}</TableCell>
                  <TableCell>-</TableCell>
                  <TableCell>-</TableCell>
                  <TableCell>-</TableCell>
                </TableRow>
              );
            }

            return (
              ah.changes.map((c, index) => {
                const auditRecordClasses = classNames({
                  [classes.additionalAuditChange]: index !== 0
                });
  
                return (
                  <TableRow key={`${key}-${c.columnName}`}>
                    <TableCell><span className={auditRecordClasses}>{formatDate(ah.date, env.REACT_APP_DATETIME_FORMAT)}</span></TableCell>
                    <TableCell><span className={auditRecordClasses}>{ah.by}</span></TableCell>
                    <TableCell><span className={auditRecordClasses}>{ah.table}</span></TableCell>
                    <TableCell><span className={auditRecordClasses}>{ah.primaryKey}</span></TableCell>
                    <TableCell><span className={auditRecordClasses}>{ah.action}</span></TableCell>
                    <TableCell className={classes.columnName}>{c.columnName}</TableCell>
                    <TableCell>{format(c.originalValue)}</TableCell>
                    <TableCell>{format(c.newValue)}</TableCell>
                  </TableRow>
                );
              })
            )
          })
        }
        </TableBody>
      </Table>
    </div>
  );
};

export default PaxAuditHistory;
