import { createStyles } from '@material-ui/core/styles';

export default createStyles({
  paxExtraServicesContainer: {
    margin: '15px 0px 15px',
  },
  paxExtraServicesTable: {
    minWidth: 650,
    whiteSpace: 'pre',
  },
  paxExtraServicesTitle: {
    padding: '10px 0 10px 0',
  },
  paxExtraServicesItemTitle: {
    backgroundColor: '#EFF6FF',
  },
});
