import * as React from 'react';
import {
  AppBar,
  Toolbar,
  Button,
  withStyles,
  WithStyles,
  Typography,
} from '@material-ui/core';
import auth0Client from '../authentication/auth0Client';
import styles from './NavigationBar.styles';
import NavigationMenu from './NavigationMenu';
import env from '../../core/env';

interface IProps extends WithStyles<typeof styles> {}

const NavigationBar: React.FC<IProps> = ({ classes }) => {
  return (
    <div>
      <AppBar position="static">
        <Toolbar className={classes.navBar}>
          <NavigationMenu />
          <Typography variant="h6" component="h1">
            {env.REACT_APP_NAME}
          </Typography>
          <Button color="inherit" onClick={() => auth0Client.logout!({})}>
            Log out
          </Button>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default withStyles(styles)(NavigationBar);
