import React, { useMemo } from 'react';
import { Paper, Grid, Typography, Container } from '@material-ui/core';
import IPax from '../../../common/types/IPax';
import PaxStatus, {
  paxStatusOpts,
  isTakingCapacity,
} from '../../enums/paxStatus';
import { genderOptions } from '../../enums/gender';
import {
  FormTextField,
  FormSelectField,
  FormDateField,
  FormCountryField,
} from '../../../common/forms';
import styles from './PaxListForm.styles';
import IVoyage from '../../../common/types/IVoyage';
import { MINIMUM_TRAVEL_AGE } from '../../../common/constants/constants';
import calculatePaxAgeOnTravelDate from '../../helpers/calculatePaxAgeOnTravelDate';
import { addDays } from 'date-fns';
import Role from '../../../common/enums/Role';
import { useUser } from '../../../common/contexts/UserContext';
import { Field, useFormState, useField } from 'react-final-form';
import formatDate from '../../../common/helpers/formatDate';
import env from '../../../core/env';
import { makeStyles } from '@material-ui/styles';
import ICabinCategory from '../../../common/types/ICabinCategory';
import ISharingGroup from '../../../common/types/ISharingGroup';
import IUser from '../../../common/types/IUser';
import { cabinCat } from '../../../common/helpers/cabinCat';

const useStyles = makeStyles(styles);

interface IProps {
  paxKey: string;
  paxIndex: number;
  voyage: IVoyage;
  paxFormClasses: string | undefined;
  disabled?: boolean;
}

const requiredWhenPreviouslySet = (
  initialPax?: Partial<IPax>,
  currentPax?: IPax,
  initialFieldValue?: Date | string | null
) => {
  const previouslyEmpty =
    initialPax?.status === PaxStatus.Confirmed && !initialFieldValue;

  return !previouslyEmpty && currentPax?.status === PaxStatus.Confirmed;
};

const isFull = (pax: IPax[], cabinCategory?: ICabinCategory) =>
  (pax.filter((p) => p && isTakingCapacity(p.status)).length ?? 0) >=
  (cabinCategory?.cabinCapacity ?? 0);

const isGenderRequired = (isFull: boolean) => (
  { bookedSingleSupplement }: Pick<ISharingGroup, 'bookedSingleSupplement'>,
  pax?: IPax
) => {
  if (!pax) return false;
  else if (pax.status === PaxStatus.Confirmed) return true;
  else if (!isTakingCapacity(pax.status) || bookedSingleSupplement)
    return false;
  else return !isFull;
};

const validStatusChange = (
  newStatus?: PaxStatus,
  existingStatus?: PaxStatus
) => {
  return (
    (existingStatus === undefined && newStatus === PaxStatus.Option) ||
    (newStatus === PaxStatus.Confirmed &&
      existingStatus !== PaxStatus.Cancelled) ||
    newStatus === existingStatus
  );
};

const paxStatusOptions = (user: IUser) => (initialPaxStatus?: PaxStatus) =>
  user.hasRole(Role.OperationsManager)
    ? paxStatusOpts
    : paxStatusOpts.map(({ value, label }) => ({
        value,
        label,
        disabled: !validStatusChange(value as PaxStatus, initialPaxStatus),
      }));

const paxTooYoung = ({ departureDate }: IVoyage) => (pax?: IPax) => {
  const paxAgeOnTravelDate = calculatePaxAgeOnTravelDate(pax, departureDate);
  return paxAgeOnTravelDate !== undefined
    ? paxAgeOnTravelDate < MINIMUM_TRAVEL_AGE
    : false;
};

const StatusDate: React.FC<Pick<IProps, 'paxKey' | 'disabled'>> = ({
  paxKey,
  disabled,
}) => {
  const classes = useStyles();
  const user = useUser();
  const {
    input: { value: pax },
  } = useField<IPax>(paxKey);

  const optionExpiryDate = useMemo(
    () => addDays(new Date(), +env.REACT_APP_OPTION_EXPIRY_DAYS),
    []
  );
  const today = useMemo(() => new Date(), []);

  let fieldName = '';
  let fieldLabel = '';
  let disableFuture = true;
  let initialValue = today;

  switch (pax?.status) {
    case PaxStatus.Option: {
      fieldName = `${paxKey}.optionExpiryDate`;
      fieldLabel = 'Option Expiry Date';
      disableFuture = false;
      initialValue = optionExpiryDate;
      break;
    }
    case PaxStatus.Confirmed: {
      fieldName = `${paxKey}.confirmedDate`;
      fieldLabel = 'Confirmed Date';
      break;
    }
    case PaxStatus.ExpiredOption: {
      fieldName = `${paxKey}.expiredOptionDate`;
      fieldLabel = 'Expired Option Date';
      break;
    }
    case PaxStatus.Cancelled: {
      fieldName = `${paxKey}.cancelledDate`;
      fieldLabel = 'Cancelled Date';
      break;
    }
    default:
      return null;
  }

  return (
    <FormDateField
      name={fieldName}
      label={fieldLabel}
      initialValue={initialValue}
      required
      disableFuture={disableFuture}
      className={classes.inputField}
      disabled={disabled || !user?.hasRole(Role.OperationsManager)}
    />
  );
};

const PaxForm: React.FC<IProps> = ({
  paxKey,
  paxIndex,
  paxFormClasses,
  disabled = false,
  voyage,
}) => {
  const classes = useStyles();
  const focusedBirthDate = useMemo(() => new Date(), []);
  const user = useUser();

  const { values: sharingGroup } = useFormState<ISharingGroup>();

  const cabinCategory = cabinCat(voyage)(sharingGroup.bookedCabinCategoryId);

  const cabinFull = isFull(sharingGroup.pax, cabinCategory);
  const genderRequired = (pax?: IPax) =>
    isGenderRequired(cabinFull)(sharingGroup, pax);

  return (
    <Container maxWidth="md">
      <Paper variant="outlined" className={paxFormClasses}>
        <Field<IPax | undefined> name={paxKey}>
          {({ meta, input }) => {
            return (
              <React.Fragment>
                <Typography align="left">
                  Passenger {paxIndex + 1}{' '}
                  {!!input.value?.id && `(Id: ${input.value.id})`}
                </Typography>
                <Grid container direction="column" spacing={1}>
                  <Grid
                    container
                    direction="row"
                    spacing={1}
                    justify="flex-start"
                    md={12}
                    item
                  >
                    <Grid item md={4}>
                      <FormTextField
                        name={`${paxKey}.bookingReference`}
                        label="Booking Reference"
                        required
                        maxLength={20}
                        className={classes.inputField}
                        disabled={disabled}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    spacing={1}
                    justify="flex-start"
                    md={12}
                    item
                  >
                    <Grid item md={4}>
                      <FormSelectField
                        name={`${paxKey}.status`}
                        label="Status"
                        options={paxStatusOptions(user)(meta.initial?.status)}
                        className={classes.inputField}
                        required
                      />
                    </Grid>
                    <Grid item md={4}>
                      <StatusDate paxKey={paxKey} disabled={disabled} />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    spacing={1}
                    justify="flex-start"
                    md={12}
                    item
                  >
                    <Grid item md={4}>
                      <FormTextField
                        name={`${paxKey}.firstName`}
                        label="First Name"
                        requiredWhen={{
                          FieldName: `${paxKey}.status`,
                          FieldValue: PaxStatus.Confirmed,
                        }}
                        maxLength={100}
                        className={classes.inputField}
                        disabled={disabled}
                      />
                    </Grid>
                    <Grid item md={4}>
                      <FormTextField
                        name={`${paxKey}.middleName`}
                        label="Middle Name"
                        maxLength={100}
                        className={classes.inputField}
                        disabled={disabled}
                      />
                    </Grid>
                    <Grid item md={4}>
                      <FormTextField
                        name={`${paxKey}.surname`}
                        label="Surname"
                        requiredWhen={{
                          FieldName: `${paxKey}.status`,
                          FieldValue: PaxStatus.Confirmed,
                        }}
                        maxLength={100}
                        className={classes.inputField}
                        disabled={disabled}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    spacing={1}
                    justify="flex-start"
                    md={12}
                    item
                  >
                    <Grid item md={4}>
                      <FormSelectField
                        name={`${paxKey}.gender`}
                        label="Gender"
                        options={genderOptions}
                        className={classes.inputField}
                        required={genderRequired(input.value)}
                        key={
                          genderRequired(input.value)
                            ? 'genderRequired'
                            : 'genderNotRequired'
                        }
                        disabled={disabled}
                      />
                    </Grid>
                    <Grid item md={4}>
                      <FormDateField
                        name={`${paxKey}.dateOfBirth`}
                        label={'Date of Birth'}
                        disableFuture
                        className={classes.inputField}
                        hasWarning={paxTooYoung(voyage)(input.value)}
                        warningText="This passenger does not meet the minimum age requirement and must be approved to travel"
                        disabled={disabled}
                        initialFocusedDate={focusedBirthDate}
                        required={requiredWhenPreviouslySet(
                          meta.initial,
                          input.value,
                          meta.initial?.dateOfBirth
                        )}
                        key={
                          requiredWhenPreviouslySet(
                            meta.initial,
                            input.value,
                            meta.initial?.countryOfResidence
                          )
                            ? 'dobRequired'
                            : 'dobNotRequired'
                        }
                      />
                    </Grid>
                    <Grid item md={4}>
                      <FormCountryField
                        name={`${paxKey}.countryOfResidence`}
                        label="Country of Residence"
                        className={classes.inputField}
                        disabled={disabled}
                        // Validation only included in the UI to allow the data upload to not provide the country of residence, ticket OLA-647
                        required={requiredWhenPreviouslySet(
                          meta.initial,
                          input.value,
                          meta.initial?.countryOfResidence
                        )}
                        includeNoneOption={
                          input.value?.status !== PaxStatus.Confirmed
                        }
                        // Need to re render if required is manipulated
                        // https://final-form.org/docs/react-final-form/types/FieldProps#validate
                        key={
                          requiredWhenPreviouslySet(
                            meta.initial,
                            input.value,
                            meta.initial?.countryOfResidence
                          )
                            ? 'countryRequired'
                            : 'countryNotRequired'
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    spacing={1}
                    justify="flex-start"
                    md={12}
                    item
                  >
                    <Grid item md={4}>
                      <FormTextField
                        name={`${paxKey}.salesConsultant`}
                        label="Sales Consultant"
                        maxLength={100}
                        className={classes.inputField}
                        disabled={disabled}
                      />
                    </Grid>
                  </Grid>
                  {meta.initial && (
                    <Grid item>
                      <div className={classes.paxAuditGrid}>
                        {meta.initial.createdDate && (
                          <Typography
                            variant="subtitle2"
                            color="textSecondary"
                            className={classes.paxAuditText}
                          >
                            <b>Created</b>:{' '}
                            {`${meta.initial.createdBy} ${formatDate(
                              meta.initial.createdDate,
                              env.REACT_APP_DATETIME_FORMAT
                            )}`}
                          </Typography>
                        )}
                        {meta.initial.updatedDate && (
                          <Typography
                            variant="subtitle2"
                            color="textSecondary"
                            className={classes.paxAuditText}
                          >
                            <b>Updated</b>:{' '}
                            {`${meta.initial.updatedBy} ${formatDate(
                              meta.initial.updatedDate,
                              env.REACT_APP_DATETIME_FORMAT
                            )}`}
                          </Typography>
                        )}
                      </div>
                    </Grid>
                  )}
                </Grid>
              </React.Fragment>
            );
          }}
        </Field>
      </Paper>
    </Container>
  );
};

export default PaxForm;
