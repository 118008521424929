import React, { useState, useEffect } from 'react';
import { useApi } from '../../common/contexts/ApiContext';
import { useSnackbar } from '../../common/contexts/SnackbarContext';
import ISharingGroup from '../../common/types/ISharingGroup';
import IPaxOperationalInfoForm from '../types/IPaxOperationalInfoForm';
import { FormState } from 'final-form';
import PaxService from '../../services/PaxService';
import SharingGroupOperationalInfoForm from './SharingGroupOperationalInfoForm';
import ISharingGroupOperationalInfo from '../../common/types/ISharingGroupOperationalInfo';
import ICabinCategory from '../../common/types/ICabinCategory';
import pluralize from 'pluralize';
import IPaxExtraService from '../../common/types/IPaxExtraService';
import IExtraService from '../../common/types/IExtraService';

interface IProps {
  voyageId: number;
  sharingGroup: ISharingGroup;
  onChange: (state: FormState<IPaxOperationalInfoForm>) => void;
  cabinCategory: ICabinCategory;
  paxExtraServices: IPaxExtraService[];
  extraServices: IExtraService[];
}

const SharingGroupOperationalInfoUpdateFormContainer: React.FC<IProps> = ({
  voyageId,
  sharingGroup,
  onChange,
  cabinCategory,
  paxExtraServices,
  extraServices,
}) => {
  const [initialValues, setInitialValues] = useState<IPaxOperationalInfoForm>();
  const snackbar = useSnackbar();
  const { wrapper } = useApi();
  useEffect(() => {
    const load = async () => {
      const existingInfo = await wrapper(
        PaxService.getOperationalInfo(sharingGroup.groupId)
      );
      setInitialValues({ data: existingInfo });
    };

    load();
    // wrapper is causing effect to trigger (should fix)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sharingGroup.groupId, sharingGroup.pax.length]);

  const onSubmit = async (operationalInfo: IPaxOperationalInfoForm) => {
    const updatedInfo = await wrapper(
      PaxService.insertUpdateOperationalInfo(
        sharingGroup.groupId,
        operationalInfo.data as ISharingGroupOperationalInfo
      )
    );

    setInitialValues({ data: updatedInfo });
    snackbar.showMessage(
      `${pluralize(
        'Passenger',
        updatedInfo.pax.length
      )} operational info updated succesfully!`,
      { autoHideDuration: 8000, disableClickaway: true }
    );
  };

  return (
    <SharingGroupOperationalInfoForm
      initialValues={initialValues}
      onChange={onChange}
      onSubmit={onSubmit}
      pax={sharingGroup.pax}
      cabinCategory={cabinCategory}
      brand={sharingGroup.brand}
      voyageId={voyageId}
      paxExtraServices={paxExtraServices}
      extraServices={extraServices}
    />
  );
};

export default SharingGroupOperationalInfoUpdateFormContainer;
