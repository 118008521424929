import { LocationDescriptorObject, LocationState } from 'history';
import { generatePath } from 'react-router';
import { FormPageTab } from '../../pax/components/PaxListForm';

const Routes = {
  PaxInsert: {
    route: '/voyage/:voyageId(\\d+)/allotment/:allotmentId(\\d+)/pax',
    getLocation: function (
      voyageId: number,
      allotmentId: number,
      cabinCategoryId?: number
    ): LocationDescriptorObject<LocationState> {
      const search = new URLSearchParams();

      if (cabinCategoryId) {
        search.set('cabinCategoryId', cabinCategoryId.toString());
      }

      return {
        pathname: generatePath(this.route, {
          voyageId: voyageId.toString(),
          allotmentId: allotmentId.toString(),
        }),
        search: search.toString(),
      };
    },
  },
  PaxSubmitted: { route: '/paxSubmitted' },
  PaxUpdate: {
    route:
      '/pax/:guid([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})',
    getLocation: function (
      guid: string,
      tab?: FormPageTab
    ): LocationDescriptorObject<LocationState> {
      return {
        pathname: generatePath(this.route, { guid }),
        hash: tab ? FormPageTab[tab] : undefined,
      };
    },
  },
  PaxCabinCategoryUpgrade: {
    route: '/paxCabinCategoryUpgrade',
    getLocation: function (
      voyageId?: number,
      sharingGroupId?: string
    ): LocationDescriptorObject<LocationState> {
      const search = new URLSearchParams();

      if (voyageId) {
        search.set('voyageId', voyageId.toString());
      }

      if (sharingGroupId) {
        search.set('sharingGroupId', sharingGroupId.toString());
      }

      return {
        pathname: this.route,
        search: search.toString(),
      };
    },
  },
  VoyageManagement: {
    route: '/voyage/manage',
    getLocation: function (
      voyageId?: number,
      cabinCategoryId?: number,
      extraServiceId?: number
    ): LocationDescriptorObject<LocationState> {
      const search = new URLSearchParams();
      let hash: string | undefined;

      if (voyageId) {
        search.set('voyageId', voyageId.toString());
      }

      if (cabinCategoryId) {
        hash = `cabinCategoryId_${cabinCategoryId}`;
      }

      if (extraServiceId) {
        hash = `extraService_${extraServiceId}`;
      }

      return {
        pathname: this.route,
        search: search.toString(),
        hash,
      };
    },
  },
  CheckAvailability: { route: '/' },
  AllotmentManagement: {
    route: '/voyage/:voyageId(\\d+)/allotments',
    getLocation: function (
      voyageId: number
    ): LocationDescriptorObject<LocationState> {
      return {
        pathname: generatePath(this.route, { voyageId: voyageId.toString() }),
      };
    },
  },
  AllotmentInsert: {
    route: '/voyage/:voyageId(\\d+)/allotments/create',
    getLocation: function (
      voyageId: number
    ): LocationDescriptorObject<LocationState> {
      return {
        pathname: generatePath(this.route, { voyageId: voyageId.toString() }),
      };
    },
  },
  AllotmentUpdate: {
    route: '/voyage/:voyageId(\\d+)/allotments/:allotmentId(\\d+)',
    getLocation: function (
      voyageId: number,
      allotmentId: number
    ): LocationDescriptorObject<LocationState> {
      return {
        pathname: generatePath(this.route, {
          voyageId: voyageId.toString(),
          allotmentId: allotmentId.toString(),
        }),
      };
    },
  },
  AllotmentSubmitted: { route: '/allotmentSubmitted' },
  CabinAssignment: {
    route: '/voyage/:voyageId(\\d+)/cabinAssignment',
    getLocation: function (
      voyageId: number
    ): LocationDescriptorObject<LocationState> {
      const search = new URLSearchParams();

      return {
        pathname: generatePath(this.route, { voyageId: voyageId.toString() }),
        search: search.toString(),
      };
    },
  },
  ManageBookings: { route: '/manageBookings' },
  PreTravelInformation: { route: '/preTravelInformation' },
  PassengerDetails: { route: '/passengerDetails' },
  Overview: { route: '/overview' },
};

export default Routes;
