import IPax from '../../common/types/IPax';
import { differenceInYears } from 'date-fns';

export default function calculatePaxAgeOnTravelDate(
  pax?: Partial<IPax>,
  date?: Date
): number | undefined {
  return pax?.dateOfBirth && date
    ? differenceInYears(date, pax.dateOfBirth)
    : undefined;
}
