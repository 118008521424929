import { createStyles } from '@material-ui/core/styles';

export default createStyles({
  buttons: {
    margin: '20px 0px',
  },
  button: {
    margin: '0px 2px',
  },
  inputField: {
    width: '200px',
  },
  voyageDropDown: {
    width: '380px',
  },
  submitError: {
    textAlign: 'center',
  },
  cabinCategoriesContainer: {
    display: 'flex',
    flexFlow: 'column',
  },
  cabinCategory: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cabinCategoryLabel: {
    width: '300px',
  },
});
