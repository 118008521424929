enum Gender {
  Male = 'male',
  Female = 'female',
}

export const genderOptions: Array<{ value: string; label: string }> = [
  { value: Gender.Male, label: 'Male' },
  { value: Gender.Female, label: 'Female' },
];

export default Gender;
