import * as React from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  WithStyles,
  withStyles,
  IconButton,
  ListItemIcon,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import Routes from '../constants/routes';
import styles from './NavigationMenu.styles';
import { Link as RouterLink } from 'react-router-dom';
import ConditionalRenderer from './ConditionalRenderer';
import { IWithUser, withUser } from '../contexts/UserContext';
import Role from '../enums/Role';

interface IState {
  menuOpen: boolean;
}

interface IProps extends WithStyles<typeof styles>, IWithUser {}

class NavigationMenu extends React.Component<IProps, IState> {
  readonly state: IState = { menuOpen: false };

  showMenu = (open: boolean) => {
    this.setState({ menuOpen: open });
  };

  renderLink = (linkText: string, to: string, icon?: React.ReactElement) => {
    return (
      <ListItem
        button
        component={RouterLink}
        to={to}
        onClick={() => this.showMenu(false)}
      >
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={linkText} />
      </ListItem>
    );
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <IconButton
          color="inherit"
          aria-label="Menu"
          onClick={() => this.showMenu(true)}
        >
          <MenuIcon />
        </IconButton>
        <Drawer open={this.state.menuOpen} onClose={() => this.showMenu(false)}>
          <List className={classes.navMenu}>
            <ConditionalRenderer
              shouldRender={this.props.user.hasRole(
                Role.SalesConsultant,
                Role.OperationsManager
              )}
            >
              {this.renderLink('Overview', Routes.Overview.route)}
            </ConditionalRenderer>
            <ConditionalRenderer
              shouldRender={this.props.user.hasRole(
                Role.SalesConsultant,
                Role.OperationsManager
              )}
            >
              {this.renderLink(
                'Check Availability',
                Routes.CheckAvailability.route
              )}
            </ConditionalRenderer>
            <ConditionalRenderer
              shouldRender={this.props.user.hasRole(
                Role.SalesConsultant,
                Role.OperationsManager
              )}
            >
              {this.renderLink('Manage Bookings', Routes.ManageBookings.route)}
            </ConditionalRenderer>
            <ConditionalRenderer
              shouldRender={this.props.user.hasRole(Role.OperationsManager)}
            >
              {this.renderLink(
                'Voyage Management',
                Routes.VoyageManagement.route
              )}
            </ConditionalRenderer>
            <ConditionalRenderer
              shouldRender={this.props.user.hasRole(
                Role.SalesConsultant,
                Role.OperationsManager
              )}
            >
              {this.renderLink(
                'Pre Travel Information',
                Routes.PreTravelInformation.route
              )}
            </ConditionalRenderer>
            <ConditionalRenderer
              shouldRender={this.props.user.hasRole(
                Role.OE_GroundTeam,
                Role.OE_LandDoctors,
                Role.OE_OfficeBasedTeam
              )}
            >
              {this.renderLink(
                'Passenger Details',
                Routes.PassengerDetails.route
              )}
            </ConditionalRenderer>
          </List>
        </Drawer>
      </React.Fragment>
    );
  }
}

export default withUser(withStyles(styles)(NavigationMenu));
