import { format, parseISO } from 'date-fns';
import env from '../../core/env';

export default function formatDate(
  date: number | Date | string,
  formatStr: string = env.REACT_APP_DATE_FORMAT,
  upperCase: boolean = true
) {
  if (typeof date === 'string') {
    date = parseISO(date);
  }

  var formatted = format(date, formatStr, {
    useAdditionalDayOfYearTokens: true,
    useAdditionalWeekYearTokens: true,
  });

  if (upperCase) {
    formatted = formatted.toLocaleUpperCase();
  }

  return formatted;
}
