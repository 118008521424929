import React from 'react';
import styles from './Filters.styles';
import { WithStyles, Paper, withStyles } from '@material-ui/core';
import CustomSelectField from './CustomSelectField';

export interface IFilters {
  statuses: string[];
}

interface IProps extends WithStyles<typeof styles> {
  handleStatusChange: React.ChangeEvent<any> | any;
  statusesOptions: { value: string; label: string }[];
  filters: IFilters;
}

const Filters: React.FC<IProps> = ({
  classes,
  filters,
  handleStatusChange,
  statusesOptions,
}) => {
  return (
    <Paper className={classes.filtersPaper} elevation={3}>
      <div className={classes.filtersText}>Filters</div>
      <div>
        <CustomSelectField
          label="Status"
          value={filters.statuses}
          onChange={handleStatusChange}
          options={statusesOptions}
        />
      </div>
    </Paper>
  );
};

export default withStyles(styles)(Filters);
