import React, { useEffect } from 'react';
import { Typography } from '@material-ui/core';
import setDocumentTitle from '../helpers/setDocumentTitle';

interface IProps {
  label: string;
}

const PageTitle: React.FC<IProps> = ({ label }) => {
  useEffect(() => {
    setDocumentTitle(label);
    return () => setDocumentTitle();
  }, [label]);

  return (
    <Typography variant="h5" component="h2" gutterBottom>
      {label}
    </Typography>
  );
};

export default PageTitle;
