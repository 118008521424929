import { createStyles } from '@material-ui/core/styles';

export default createStyles({
  paxContainer: {
    margin: '15px 0px 15px',
  },
  paxTable: {
    minWidth: 650,
    whiteSpace: 'pre',
  },
  paxSectionTitle: {
    padding: '10px 0 10px 0',
  },
  paxRowHeader: {
    backgroundColor: '#EFF6FF',
  },
  paxCell: {
    border: 'none',
  },
  paxCellCapitalize: {
    border: 'none',
    'text-transform': 'capitalize',
  },
  paxNotesColumn: {
    maxWidth: '300px',
    overflowWrap: 'normal',
    whiteSpace: 'normal',
  },
  cabinCategoryDetails: {
    'scroll-margin-top': '3rem',
  },
  scrollToTopButton: {
    size: 'small',
    width: '1rem',
    height: '1rem',
  },
});
