import React from 'react';
import IVoyage from '../../common/types/IVoyage';
import VoyageSelect from '../../common/components/VoyageSelect';
import PageHeader from '../../common/components/PageHeader';
import { Grid, Button, makeStyles, Menu, MenuItem } from '@material-ui/core';
import styles from './PassengerDetails.styles';
import { useUser } from '../../common/contexts/UserContext';
import Role from '../../common/enums/Role';
import ExternalUserType, {
  externalUserTypeLabels,
} from '../../common/enums/ExternalUserType';
import SaveIcon from '@material-ui/icons/Save';
import { Share } from '@material-ui/icons';

const useStyles = makeStyles(styles);
interface IProps {
  voyages: IVoyage[];
  onChange: (event: React.ChangeEvent<any> | any) => void;
  onDownloadCSV: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  onExternalUserGenerateDownloadLink: (
    externalUserType: ExternalUserType
  ) => (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;

  selectedVoyage?: IVoyage;
}

const PassengerDetails: React.FC<IProps> = ({
  voyages,
  onChange,
  onDownloadCSV,
  onExternalUserGenerateDownloadLink,
  selectedVoyage,
}) => {
  const classes = useStyles();
  const user = useUser();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <PageHeader
        title="Passenger Details"
        description="Select a voyage to be able to download the passenger details."
      />
      <Grid
        spacing={3}
        container
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Grid item xs="auto">
          <VoyageSelect
            voyages={voyages}
            onChange={onChange}
            selectedVoyageId={selectedVoyage?.id}
          />
        </Grid>
        <Grid item xs="auto">
          <Button
            color="primary"
            variant="contained"
            disabled={selectedVoyage === undefined}
            type="submit"
            size="small"
            className={classes.actionButtons}
            onClick={onDownloadCSV}
            startIcon={<SaveIcon />}
          >
            Download
          </Button>
        </Grid>
        {user.hasRole(Role.OperationsManager) && (
          <Grid item xs="auto">
            <Button
              color="default"
              variant="contained"
              disabled={selectedVoyage === undefined}
              type="submit"
              size="small"
              className={classes.actionButtons}
              onClick={handleClick}
              startIcon={<Share />}
            >
              Share
            </Button>
            <Menu
              id="share-menu"
              anchorEl={anchorEl}
              keepMounted
              open={!!anchorEl}
              onClose={handleClose}
            >
              {Object.entries(externalUserTypeLabels).map(([value, label]) => (
                <MenuItem
                  key={value}
                  onClick={onExternalUserGenerateDownloadLink(
                    value as ExternalUserType
                  )}
                >
                  {label}
                </MenuItem>
              ))}
            </Menu>
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
};

export default PassengerDetails;
