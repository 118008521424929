import * as React from 'react';
import { TextField, MenuItem, withStyles, WithStyles } from '@material-ui/core';
import ICabinCategory from '../../common/types/ICabinCategory';
import styles from './BaseSelect.styles';
import IAllotmentAllocation from '../types/IAllotmentAllocation';
import getAvailabilityText from '../../common/helpers/getAvailabilityText';

interface IProps extends WithStyles<typeof styles> {
  selectedCabinCategoryId: number | undefined;
  cabinCategories: ICabinCategory[] | undefined;
  disabledCabinCategories?: ICabinCategory[];
  displayAvailability?: boolean;
  allotmentAllocations?: IAllotmentAllocation[];
  onChange: (event: React.ChangeEvent<any> | any) => void;
  displayLabel?: boolean;
}

type CabinCategoryOption = {
  value: number | string;
  label: string;
};

const getCabinCategorySelectOptions = (
  cabinCategories: ICabinCategory[] | undefined,
  displayAvailability: boolean,
  allotmentAllocations?: IAllotmentAllocation[]
): CabinCategoryOption[] => {
  if (cabinCategories !== undefined) {
    return cabinCategories
      .sort((a, b) =>
        a.name.localeCompare(b.name, 'en', {
          numeric: true,
        })
      )
      .map((cc) => {
        const availability =
          displayAvailability && allotmentAllocations
            ? allotmentAllocations.find((aa) => aa.cabinCategoryId === cc.id)
                ?.availability
            : undefined;

        return {
          value: cc.id,
          label: `${cc.name} ${
            !availability ? '' : '(' + getAvailabilityText(availability) + ')'
          }`,
        };
      });
  }

  return [{ value: '', label: 'Please select a voyage' }];
};

const CabinCategorySelect: React.FC<IProps> = ({
  selectedCabinCategoryId,
  cabinCategories,
  disabledCabinCategories = [],
  displayAvailability = false,
  allotmentAllocations = [],
  onChange,
  classes,
  displayLabel,
}) => {
  var options = getCabinCategorySelectOptions(
    cabinCategories,
    displayAvailability,
    allotmentAllocations
  );

  var label = displayLabel || displayLabel! === false ? '' : 'Cabin Category';

  return (
    <TextField
      select
      label={label}
      value={selectedCabinCategoryId || ''}
      className={classes.inputField}
      onChange={onChange}
    >
      {options.map((option) => (
        <MenuItem
          key={option.label}
          value={option.value}
          disabled={
            disabledCabinCategories &&
            disabledCabinCategories.some((cc) => cc.id === option.value)
          }
        >
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default withStyles(styles)(CabinCategorySelect);
