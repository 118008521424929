import { isAfter, endOfDay } from 'date-fns';
import formatDate from '../../helpers/formatDate';

export default function isBeforeDate(value: Date | null, maxDate?: Date) {
  if (value) {
    if (maxDate && isAfter(value, endOfDay(maxDate))) {
      return `Date must be on or before ${formatDate(maxDate)}`;
    }
  }
}
