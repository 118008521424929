import React from 'react';
import {
  withStyles,
  WithStyles,
  Button,
  Grid,
  Paper,
  Tab,
  Tabs,
  Container,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import ISharingGroup from '../../common/types/ISharingGroup';
import styles from './VoyageManagement.styles';
import IVoyage from '../../common/types/IVoyage';
import IAllotment from '../../common/types/IAllotment';
import PaxDetailsTable from '../../common/components/PaxDetailsTable';
import VoyageSelect from '../../common/components/VoyageSelect';
import Routes from '../../common/constants/routes';
import { withRouter, RouteComponentProps } from 'react-router';
import PaxExtraServicesDetails from '../../common/components/PaxExtraServicesDetails';
import VoyagePaxDetailsSummary from './VoyagePaxDetailsSummary';
import IExtraService from '../../common/types/IExtraService';
import VoyageExtraServiceSummary from './VoyageExtraServiceSummary';
import VoyagePageTab from '../../allotment/enums/VoyagePageTab';
import TabPanel from '../../common/components/TabPanel';
import PageHeader from '../../common/components/PageHeader';
import ISharingGroupOperationalInfo from '../../common/types/ISharingGroupOperationalInfo';

interface IProps extends WithStyles<typeof styles> {
  voyages: IVoyage[];
  selectedVoyageAllotments: IAllotment[];
  sharingGroups: ISharingGroup[];
  selectedVoyageId: number | undefined;
  selectedVoyageExtraServices: IExtraService[];
  onSelectVoyage: (voyageId: number) => Promise<void>;
  onDownloadSalesExtract: () => Promise<void>;
  tabSelected: VoyagePageTab;
  handleTabChange: (
    e: React.ChangeEvent<any>,
    selectedTab: VoyagePageTab
  ) => Promise<void>;
  sharingGroupOperationalInfo: ISharingGroupOperationalInfo[];
}

type Props = IProps & RouteComponentProps;

class VoyageManagement extends React.Component<Props> {
  private handleChangeVoyage = async (e: any) => {
    const voyageId = e.target.value;

    // update pax list
    await this.props.onSelectVoyage(voyageId);
  };

  render() {
    const {
      voyages,
      selectedVoyageAllotments,
      sharingGroups,
      selectedVoyageId,
      onDownloadSalesExtract,
      selectedVoyageExtraServices,
      classes,
      tabSelected,
      handleTabChange,
      sharingGroupOperationalInfo,
    } = this.props;

    const selectedVoyage = voyages?.find((x) => x.id === selectedVoyageId);

    return (
      <React.Fragment>
        <PageHeader
          title="Voyage Management"
          description={[
            'Select a voyage to view passengers and check overall availability.',
            'From here you can also manage the allotments, assign passengers to cabins and upgrade passengers as necessary.',
          ]}
        />
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="flex-end"
              spacing={5}
            >
              <Grid item>
                <VoyageSelect
                  selectedVoyageId={selectedVoyageId}
                  voyages={voyages}
                  onChange={this.handleChangeVoyage}
                />
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  disabled={selectedVoyage === undefined}
                  color="primary"
                  className={classes.headerButton}
                  component={RouterLink}
                  to={
                    selectedVoyageId
                      ? Routes.AllotmentManagement.getLocation(selectedVoyageId)
                      : ''
                  }
                >
                  Manage Allotments
                </Button>

                <Button
                  variant="contained"
                  disabled={selectedVoyage === undefined}
                  color="primary"
                  className={classes.headerButton}
                  component={RouterLink}
                  to={
                    selectedVoyageId
                      ? Routes.CabinAssignment.getLocation(selectedVoyageId)
                      : ''
                  }
                >
                  Assign Cabins
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  onClick={onDownloadSalesExtract}
                  className={classes.headerButton}
                >
                  Sales Extract
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {selectedVoyage && (
          <React.Fragment>
            <Paper square>
              <Tabs
                value={tabSelected}
                onChange={handleTabChange}
                aria-label="Allotment Details"
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab label="Voyage" />
                <Tab label="Extra Services" />
              </Tabs>
            </Paper>

            <TabPanel value={tabSelected} index={0}>
              <VoyagePaxDetailsSummary
                allotments={selectedVoyageAllotments}
                voyage={selectedVoyage}
                sharingGroups={sharingGroups}
              />
              <PaxDetailsTable
                voyage={selectedVoyage}
                sharingGroups={sharingGroups}
                allotments={selectedVoyageAllotments}
                displayCabinCategory="current"
                showCabinInfo
                sharingGroupOperationalInfo={sharingGroupOperationalInfo}
              />
            </TabPanel>
            <TabPanel value={tabSelected} index={1}>
              <Container maxWidth="lg">
                <VoyageExtraServiceSummary
                  extraServices={selectedVoyageExtraServices}
                />
                <PaxExtraServicesDetails
                  extraServices={selectedVoyageExtraServices}
                  sharingGroups={sharingGroups}
                />
              </Container>
            </TabPanel>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(withStyles(styles)(VoyageManagement));
