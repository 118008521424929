import React from 'react';
import {
  Button,
  Container,
  AccordionSummary,
  Accordion,
  AccordionDetails,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IVoyage from '../../common/types/IVoyage';
import styles from './ManageAllotments.styles';
import Routes from '../../common/constants/routes';
import IAllotment from '../../common/types/IAllotment';
import AllotmentHeader from './AllotmentHeader';
import AllotmentDetails from './AllotmentDetails';
import PageHeader from '../../common/components/PageHeader';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';

interface IProps {
  voyage: IVoyage;
  voyageAllotments: IAllotment[];
  onReleaseAllotment: (allotmentId: number) => void;
}

const useStyles = makeStyles(styles);

const ManageAllotments: React.FC<IProps> = ({
  voyage,
  voyageAllotments,
  onReleaseAllotment,
}) => {
  const classes = useStyles();

  const allotments = voyageAllotments.filter((x) => !x.defaultAllotment);

  return (
    <React.Fragment>
      <PageHeader
        title="Manage Allotments"
        description={[
          'Create new allotments or update existing allotments.',
          'Remember that once an allotment is released it cannot be reinstated again.',
        ]}
        voyage={voyage}
      />
      <Container maxWidth="lg">
        <div className={classes.allotmentCreateButtonContainer}>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to={Routes.AllotmentInsert.getLocation(voyage.id)}
            className={classes.allotmentCreateButton}
          >
            Create Allotment
          </Button>
        </div>

        <div className={classes.allotmentContainer}>
          {allotments.length === 0
            ? 'No Allotments have been created for this voyage'
            : allotments.map((allotment) => (
                <div key={allotment.id} className={classes.allotment}>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <AllotmentHeader
                        allotment={allotment}
                        onReleaseAllotment={onReleaseAllotment}
                        showStatus={true}
                        showActionButtons={true}
                        voyageId={voyage.id}
                      />
                    </AccordionSummary>
                    <AccordionDetails>
                      <AllotmentDetails
                        allotment={allotment}
                        cabinCategories={voyage.cabinCategories}
                      />
                    </AccordionDetails>
                  </Accordion>
                </div>
              ))}
        </div>
      </Container>
    </React.Fragment>
  );
};

export default ManageAllotments;
