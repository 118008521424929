import ICabin from '../types/ICabin';

export default function getCabinDetailsText(cabin: ICabin) {
  let cabinDetails: string[] = [];

  cabinDetails.push(`${cabin.cabinBeddingConfiguration}`);

  if (cabin.cabinLivingSpaceType) {
    cabinDetails.push(`${cabin.cabinLivingSpaceType} Living`);
  }

  if (cabin.numberOfBathrooms > 1) {
    cabinDetails.push(`${cabin.numberOfBathrooms} Bathrooms`);
  }

  return cabinDetails.join(', ').trim();
}
