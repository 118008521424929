import React from 'react';
import InputBase from '@material-ui/core/InputBase';
import { Search } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import styles from './GlobalFilter.styles';

type Props = {
  preGlobalFilteredRows: unknown[];
  globalFilter: unknown;
  setGlobalFilter: (filterValue: unknown) => void;
};

const useStyles = makeStyles(styles);

const GlobalFilter: React.FC<Props> = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <Search />
      </div>
      <InputBase
        placeholder="Search..."
        value={globalFilter || ''}
        type="search"
        onChange={(e) => setGlobalFilter(e.target.value || undefined)}
        classes={{ root: classes.inputRoot, input: classes.inputInput }}
        inputProps={{ 'aria-label': 'search' }}
      />
    </div>
  );
};

export default GlobalFilter;
