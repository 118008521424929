import { createStyles } from '@material-ui/core/styles';

export default createStyles({
  inputField: {
    width: '200px',
  },
  headerButton: {
    margin: '0em 1em 0em 1em',
  },
});
