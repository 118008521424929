import BaseService from './BaseService';
import ISharingGroup from '../common/types/ISharingGroup';
import IAuditRecord from '../common/types/IAuditRecord';
import ICabinCategoryUpgrade from '../common/types/ICabinCategoryUpgrade';
import PaxSearchResult from '../common/types/PaxSearchResult';
import PaxSearchQuery from '../common/types/PaxSearchQuery';
import PaxExtraServiceSearchQuery from '../common/types/PaxExtraServiceSearchQuery';
import IPaxExtraService from '../common/types/IPaxExtraService';
import PaxExtraServiceStatus from '../pax/enums/paxExtraServiceStatus';
import convertToUTCDate from './helpers/convertToUTCDate';
import ISharingGroupOperationalInfo from '../common/types/ISharingGroupOperationalInfo';
import Role from '../common/enums/Role';
import PaxExtraServiceSearchResult from '../common/types/PaxExtraServiceSearchResult';
import Pagination from '../common/types/Pagination';
import PaginatedResult from '../common/types/PaginatedResult';
import { cloneDeep } from 'lodash';

class PaxService extends BaseService {
  resourceBase = '/pax';

  async getSharingGroup(guid: string): Promise<ISharingGroup> {
    return await this.get(`${this.resourceBase}/${guid}`);
  }

  async upgradeSharingGroupCabinCategory(
    guid: string,
    newCabinCategory: ICabinCategoryUpgrade
  ): Promise<ISharingGroup> {
    return await this.put(
      `${this.resourceBase}/${guid}/upgrade`,
      newCabinCategory
    );
  }

  async removeSharingGroupCabinCategoryUpgrade(
    guid: string
  ): Promise<ISharingGroup> {
    return await this.put(`${this.resourceBase}/${guid}/removeUpgrade`, null);
  }

  async getOperationalInfo(
    guid: string
  ): Promise<ISharingGroupOperationalInfo> {
    return await this.get(`${this.resourceBase}/${guid}/operationalInfo`);
  }

  async insertUpdateOperationalInfo(
    guid: string,
    operationalInfo: ISharingGroupOperationalInfo
  ): Promise<ISharingGroupOperationalInfo> {
    return await this.post(
      `${this.resourceBase}/${guid}/operationalInfo`,
      operationalInfo
    );
  }

  async getPaxExtraServices(guid: string): Promise<IPaxExtraService[]> {
    return await this.get(`${this.resourceBase}/${guid}/extraService`);
  }

  async insertUpdatePaxExtraServices(
    guid: string,
    paxExtraServices: IPaxExtraService[]
  ): Promise<IPaxExtraService[]> {
    const paxExtraServicesModified = this.removeUnnecessaryPaxExtraServiceFields(
      paxExtraServices
    );

    return await this.post(
      `${this.resourceBase}/${guid}/extraService`,
      this.convertForApi(paxExtraServicesModified)
    );
  }

  async searchPax(
    query?: Partial<PaxSearchQuery & Pagination>
  ): Promise<PaginatedResult<PaxSearchResult>> {
    const search = new URLSearchParams();

    query &&
      Object.entries(query).forEach(([key, value]) => {
        if (value instanceof Array)
          value.forEach((v: string) => search.append(key, v));
        else if (value instanceof Date) search.append(key, value.toISOString());
        else if (value) search.append(key, value.toString());
      });

    return await this.getWithPagination(
      `${this.resourceBase}/search?${search}`
    );
  }

  async searchPaxExtraServices(
    query?: Partial<PaxExtraServiceSearchQuery & Pagination>
  ): Promise<PaginatedResult<PaxExtraServiceSearchResult>> {
    const search = new URLSearchParams();

    query &&
      Object.entries(query).forEach(([key, value]) => {
        if (value instanceof Array)
          value.forEach((v: string) => search.append(key, v));
        else if (value instanceof Date) search.append(key, value.toISOString());
        else if (value) search.append(key, value.toString());
      });

    return await this.getWithPagination(
      `${this.resourceBase}/extraService/search?${search}`
    );
  }
  
  async getSharingGroupAuditHistory(guid: string): Promise<IAuditRecord[]> {
    return await this.get(`${this.resourceBase}/${guid}/audit`);
  }

  private removeUnnecessaryPaxExtraServiceFields(
    paxExtraServices: IPaxExtraService[]
  ): IPaxExtraService[] {
    var paxExtraServicesModified = cloneDeep(paxExtraServices);

    return paxExtraServicesModified.map((pes) => {
      const modifiedPes = pes;

      if (
        pes.status !== PaxExtraServiceStatus.Confirmed ||
        !this.user?.hasRole(Role.OperationsManager)
      )
        delete modifiedPes.confirmedDate;

      if (
        pes.status !== PaxExtraServiceStatus.Option ||
        !this.user?.hasRole(Role.OperationsManager)
      )
        delete modifiedPes.optionExpiryDate;

      if (
        pes.status !== PaxExtraServiceStatus.ExpiredOption ||
        !this.user?.hasRole(Role.OperationsManager)
      ) {
        delete pes.expiredOptionDate;
      }

      if (
        pes.status !== PaxExtraServiceStatus.Cancelled ||
        !this.user?.hasRole(Role.OperationsManager)
      ) {
        delete pes.cancelledDate;
      }

      return modifiedPes;
    });
  }

  private convertForApi(paxExtraServices: IPaxExtraService[]) {
    return paxExtraServices.map((pes) => ({
      ...pes,
      optionExpiryDate: convertToUTCDate(pes.optionExpiryDate),
      confirmedDate: convertToUTCDate(pes.confirmedDate),
    }));
  }
}

const service = new PaxService();

export default service;
