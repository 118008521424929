import React from 'react';
import { Link } from 'react-router-dom';
import Routes from '../../common/constants/routes';

const PaxDetailsSubmitted: React.FC = () => {
  return (
    <div>
      <p>Passenger details submitted successfully!</p>
      <Link to={Routes.PaxInsert.route}>
        <p>Add another passenger</p>
      </Link>
      <Link to={Routes.VoyageManagement.route}>
        <p>Return to voyages</p>
      </Link>
      <img
        src="https://illustoon.com/photo/2338.png"
        alt="ship"
        width="400px"
        height="400px"
      ></img>
    </div>
  );
};

export default PaxDetailsSubmitted;
