import React, { ReactNode } from 'react';
import { Field } from 'react-final-form';
import { TextField } from '@material-ui/core';
import { isPopulated, isAboveMin, isBelowMax } from './validators';
import TooltipIcon from '../components/TooltipIcon';

interface IProps {
  name: string;
  label: string;
  required?: boolean;
  disabled?: boolean;
  min?: number;
  max?: number;
  initialValue?: number;
  className?: string;
  toolTip?: ReactNode;
}

const FormNumberField: React.SFC<IProps> = (props: IProps) => {
  const {
    name,
    label,
    required = false,
    disabled = false,
    min,
    max,
    initialValue,
    className,
    toolTip,
  } = props;
  return (
    <TooltipIcon title={toolTip}>
      <Field
        name={name}
        initialValue={initialValue}
        validate={(value, allValues) => validate(value, props)}
      >
        {({ input, meta }) => {
          return (
            <TextField
              {...input}
              type={'number'}
              id={name}
              label={label}
              className={className}
              margin="normal"
              required={required}
              disabled={disabled}
              inputProps={{
                min: min,
                max: max,
              }}
              error={meta.touched && meta.error !== undefined}
              helperText={
                meta.touched &&
                meta.error && <React.Fragment>{meta.error}</React.Fragment>
              }
            />
          );
        }}
      </Field>
    </TooltipIcon>
  );
};

function validate(value: any, props: IProps) {
  const { required, min, max } = props;

  var error = undefined;
  if (!error && required) {
    error = isPopulated(value);
  }

  if (!error && min !== undefined) {
    error = isAboveMin(value, min);
  }

  if (!error && max !== undefined) {
    error = isBelowMax(value, max);
  }

  return error;
}

export default FormNumberField;
