enum ExternalUserType {
  CMI_Management_Team = 'CMI_Management_Team',
  HotelAndTour_Operator = 'HotelAndTour_Operator',
}

export const externalUserTypeLabels: Readonly<Record<
  ExternalUserType,
  string
>> = {
  [ExternalUserType.CMI_Management_Team]: 'CMI Management Team',
  [ExternalUserType.HotelAndTour_Operator]: 'H&T Operator',
};

export default ExternalUserType;
