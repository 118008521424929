import React, { ReactNode } from 'react';
import { Field } from 'react-final-form';
import { MenuItem, TextField, makeStyles } from '@material-ui/core';
import { isPopulated } from './validators';
import styles from './FormSelectField.styles';
import { FieldValidator } from 'final-form';
import TooltipIcon from '../components/TooltipIcon';

const useStyles = makeStyles(styles);
interface IProps {
  name: string;
  label?: string;
  options: Array<{
    value: string | number | undefined;
    label: ReactNode;
    disabled?: boolean;
  }>;
  className?: string;
  required?: boolean;
  disabled?: boolean;
  defaultValue?: any;
  onChange?: (
    event: React.ChangeEvent<string | number> | string | number
  ) => void;
  validator?: FieldValidator<string | number>;
  toolTip?: ReactNode;
}

const FormSelectField: React.FC<IProps> = ({
  name,
  label,
  options,
  required = false,
  disabled = false,
  defaultValue,
  className,
  onChange,
  validator,
  toolTip,
}) => {
  const classes = useStyles();

  const validate = validator
    ? validator
    : (value: string | number) => defaultValidate(value, required);

  return (
    <TooltipIcon title={toolTip}>
      <Field<string | number>
        name={name}
        defaultValue={defaultValue}
        validate={validate}
      >
        {({ input, meta }) => {
          const hasError = meta.touched && meta.error !== undefined;
          return (
            <TextField
              select
              {...input}
              id={name}
              label={label}
              margin="normal"
              required={required}
              disabled={disabled}
              onChange={(e) => {
                onChange && onChange(e.target.value);
                input.onChange(e);
              }}
              className={`${classes.select} ${className}`}
              SelectProps={{
                MenuProps: {
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                },
              }}
              error={hasError}
              helperText={
                hasError && <React.Fragment>{meta.error}</React.Fragment>
              }
            >
              {options.map((option, index) => (
                <MenuItem
                  key={index}
                  value={option.value}
                  disabled={option.disabled}
                >
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          );
        }}
      </Field>
    </TooltipIcon>
  );
};

function defaultValidate(
  value: string | number | null | undefined,
  required: boolean
) {
  let error = undefined;
  if (!error && required) {
    error = isPopulated(value);
  }

  return error;
}

export default FormSelectField;
