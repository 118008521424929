import React, { createContext, Component, useContext } from 'react';
import IUser from '../types/IUser';
import auth0Client from '../../common/authentication/auth0Client';

interface IProps {
  children: React.ReactNode;
}

export interface IUserContextState {
  user: IUser;
  updateUserContextState: (updatedState: Partial<IUserContextState>) => void;
}

export interface IWithUser {
  user: IUser;
}

export const UserContext = createContext<IUser>({
  userId: '',
  brands: [],
  hasBrand: () => false,
  hasRole: () => false,
});

export function withUser<P extends IWithUser>(
  Component: React.ComponentType<P>
) {
  return function WrappedComponent(
    props: Pick<P, Exclude<keyof P, keyof IWithUser>>
  ) {
    return (
      <UserContext.Consumer>
        {(user) => <Component {...(props as P)} user={user} />}
      </UserContext.Consumer>
    );
  };
}

export const useUser = () => useContext(UserContext);

class UserContextProvider extends Component<IProps, IUserContextState> {
  state: IUserContextState = {
    user: {
      userId: '',
      hasRole: (_any: unknown) => false,
      hasBrand: (_any: unknown) => false,
      brands: [],
    },
    updateUserContextState: () => {},
  };

  private _isMounted = false;

  updateUserContextState = async (updatedState: Partial<IUserContextState>) => {
    if (this._isMounted) {
      this.setState(Object(updatedState));
    }
  };

  async componentDidMount() {
    this._isMounted = true;

    const auth0User = await auth0Client.user;
    const claims = await auth0Client.getClaims();

    const user = {
      userId: auth0User.sub,
      brands: claims.Brands,
      hasRole: claims.HasRole,
      hasBrand: claims.HasBrand,
    };

    this.updateUserContextState({ user });
  }

  render() {
    return (
      this.state.user.userId && (
        <UserContext.Provider value={this.state.user}>
          {this.props.children}
        </UserContext.Provider>
      )
    );
  }
}

export default UserContextProvider;
