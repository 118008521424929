import { Search } from 'history';

export const parseNumberQuery = (search: Search, names: string[]) => {
  const urlSearchParams = new URLSearchParams(search);

  return names.map((name) => {
    const value = urlSearchParams.get(name);
    return value && !isNaN(+value) ? +value : undefined;
  });
};
