import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import VoyagesService from '../../services/VoyagesService';
import IVoyage from '../../common/types/IVoyage';
import ISharingGroup from '../../common/types/ISharingGroup';
import IAllotment from '../../common/types/IAllotment';
import CheckAvailability from './CheckAvailability';
import IExtraService from '../../common/types/IExtraService';
import { withApi, IWithApi } from '../../common/contexts/ApiContext';
import NotFoundError from '../../services/errors/NotFoundError';
import NotFound404 from '../../common/components/NotFound404';
import ForbiddenError from '../../services/errors/ForbiddenError';
import AccessDenied from '../../common/components/AccessDenied';

interface IState {
  voyages: IVoyage[];
  sharingGroups: ISharingGroup[];
  selectedVoyageId?: number;
  selectedVoyageAllotments: IAllotment[];
  selectedVoyageExtraServices: IExtraService[];
}

class CheckAvailabilityContainer extends React.Component<
  RouteComponentProps & IWithApi,
  IState
> {
  readonly state: IState = {
    voyages: [],
    selectedVoyageExtraServices: [],
    sharingGroups: [],
    selectedVoyageId: undefined,
    selectedVoyageAllotments: [],
  };
  private _isMounted = false;
  private apiWrapper = this.props.api.wrapper;

  async componentDidMount() {
    this._isMounted = true;

    await this.loadVoyages();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async loadVoyages() {
    const voyages = await this.apiWrapper(VoyagesService.getVoyages());

    this.updateState({ voyages });
  }

  getSharingGroupsForVoyage = async (voyageId: number) => {
    const sharingGroups = await this.apiWrapper(
      VoyagesService.getSharingGroups(voyageId)
    );
    this.updateState({ sharingGroups });
  };

  onSelectVoyage = async (selectedVoyageId: number) => {
    this.updateState({
      selectedVoyageAllotments: [],
      selectedVoyageId: undefined,
      selectedVoyageExtraServices: [],
    });

    const selectedVoyageAllotments = await this.apiWrapper(
      VoyagesService.getAllotmentsByVoyage(selectedVoyageId)
    );

    const selectedVoyageExtraServices = await this.apiWrapper(
      VoyagesService.getExtraServices(selectedVoyageId)
    );

    this.updateState({
      selectedVoyageAllotments,
      selectedVoyageId,
      selectedVoyageExtraServices,
    });
  };

  updateState(updatedState: any) {
    if (this._isMounted) {
      this.setState(updatedState);
    }
  }

  render() {
    const {
      voyages,
      sharingGroups,
      selectedVoyageAllotments,
      selectedVoyageExtraServices,
      selectedVoyageId,
    } = this.state;

    const { error } = this.props.api;
    if (error instanceof NotFoundError) return <NotFound404 />;
    if (error instanceof ForbiddenError) return <AccessDenied />;

    return (
      <CheckAvailability
        voyages={voyages}
        getSharingGroupsForVoyage={this.getSharingGroupsForVoyage}
        sharingGroups={sharingGroups}
        onSelectVoyage={this.onSelectVoyage}
        voyageId={selectedVoyageId}
        selectedVoyageAllotments={selectedVoyageAllotments}
        selectedVoyageExtraServices={selectedVoyageExtraServices}
      />
    );
  }
}

export default withApi(withRouter(CheckAvailabilityContainer));
