import React, { useEffect, useRef } from 'react';
import {
  useTable,
  useFilters,
  useSortBy,
  Column,
  TableState,
  useGlobalFilter,
  Filters,
  useAsyncDebounce,
  usePagination,
  SortingRule,
} from 'react-table';
import {
  Paper,
  CircularProgress,
  Typography,
  TableSortLabel,
  Toolbar,
  makeStyles,
  TablePagination,
} from '@material-ui/core';
import MUITable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useApi } from '../../common/contexts/ApiContext';
import GlobalFilter from './GlobalFilter';
import styles from './FancyTable.styles';

type Props<T extends object> = {
  title?: string;
  columns: Column<T>[];
  data: T[];
  initialState?: Partial<TableState<T>>;
  disableGlobalFilter?: boolean;
  defaultColumn?: Partial<Column<T>>;
  onFetchData?: ({
    filters,
    pageIndex,
    pageSize,
    sortBy,
  }: {
    filters?: Filters<T>;
    pageIndex?: number;
    pageSize?: number;
    sortBy?: SortingRule<T>[];
  }) => Promise<void>;
  totalRecords?: number;
  pageCount?: number;
  manualSortBy?: boolean;
};

const useStyles = makeStyles(styles);

const FancyTable = <T extends object>({
  title,
  columns,
  data,
  initialState,
  disableGlobalFilter = false,
  defaultColumn,
  onFetchData,
  pageCount: controlledPageCount,
  totalRecords,
  manualSortBy,
}: Props<T>) => {
  const skipPageResetRef = useRef(false);
  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
    gotoPage,
    setPageSize,
    state: { globalFilter, filters, pageIndex, pageSize, sortBy },
  } = useTable(
    {
      columns,
      data,
      initialState,
      disableGlobalFilter,
      defaultColumn,
      manualPagination: true,
      manualSortBy,
      pageCount: controlledPageCount,
      autoResetPage: !skipPageResetRef.current,
      autoResetExpanded: !skipPageResetRef.current,
      autoResetGroupBy: !skipPageResetRef.current,
      autoResetSelectedRows: !skipPageResetRef.current,
      autoResetSortBy: !skipPageResetRef.current,
      autoResetFilters: !skipPageResetRef.current,
      autoResetRowState: !skipPageResetRef.current,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { loading } = useApi();

  const classes = useStyles();

  const voidFn = () => undefined;

  const onFetchDataDebounced = useAsyncDebounce(onFetchData ?? voidFn, 500);

  useEffect(() => {
    const load = async () => {
      skipPageResetRef.current = true;
      await onFetchDataDebounced({ filters, pageIndex, pageSize, sortBy });
      skipPageResetRef.current = false;
    };

    load();
  }, [onFetchDataDebounced, filters, pageIndex, pageSize, sortBy]);

  if (loading && !onFetchData) return <CircularProgress />;

  // Render the UI for your table
  return (
    <Paper>
      {title && (
        <Toolbar>
          <Typography
            variant="h6"
            id="tableTitle"
            className={classes.tableTitle}
            noWrap
          >
            {title}
          </Typography>
          {!disableGlobalFilter && (
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              setGlobalFilter={setGlobalFilter}
              globalFilter={globalFilter}
            />
          )}
        </Toolbar>
      )}
      <MUITable
        {...getTableProps()}
        size="small"
        aria-label="a dense table"
        stickyHeader
        component="div"
      >
        <TableHead component="div">
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()} component="div">
              {headerGroup.headers.map((column) => (
                <TableCell
                  component="div"
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={classes.tableHeaderCell}
                >
                  {column.render('Header')}
                  {column.canSort && (
                    <TableSortLabel
                      active={column.isSorted}
                      direction={column.isSortedDesc ? 'desc' : 'asc'}
                    />
                  )}
                  {column.canFilter && column.render('Filter')}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody component="div">
          {rows.length > 0 ? (
            rows.map((row) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()} hover component="div">
                  {row.cells.map((cell) => {
                    return (
                      <TableCell
                        component="div"
                        {...cell.getCellProps({
                          className: cell.column.className,
                        })}
                      >
                        {cell.render('Cell')}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })
          ) : (
            <TableRow component="div">
              <TableCell component="div">
                <Typography>
                  <em>No results.</em>
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </MUITable>
      {!!totalRecords && !!rows.length && (
        <TablePagination
          rowsPerPage={pageSize}
          component="div"
          count={totalRecords}
          page={pageIndex}
          onPageChange={(_e, page) => gotoPage(page)}
          onRowsPerPageChange={({ target: { value } }) => setPageSize(+value)}
        />
      )}
    </Paper>
  );
};

export default FancyTable;
