import React from 'react';
import VoyagesService from '../../services/VoyagesService';
import AllotmentService from '../../services/AllotmentService';
import IVoyage from '../../common/types/IVoyage';
import IAllotment from '../../common/types/IAllotment';
import ManageAllotments from './ManageAllotments';
import AllotmentStatus from '../enums/allotmentStatus';
import { RouteComponentProps } from 'react-router-dom';
import NotFound404 from '../../common/components/NotFound404';
import NotFoundError from '../../services/errors/NotFoundError';
import { withApi, IWithApi } from '../../common/contexts/ApiContext';
import ForbiddenError from '../../services/errors/ForbiddenError';
import AccessDenied from '../../common/components/AccessDenied';
import {
  IWithSnackbar,
  withSnackbar,
} from '../../common/contexts/SnackbarContext';

interface RouteParams {
  voyageId: string;
}

interface IState {
  voyage?: IVoyage;
  voyageAllotments?: IAllotment[];
  isLoaded: boolean;
}

class ManageAllotmentsContainer extends React.Component<
  RouteComponentProps<RouteParams> & IWithApi & IWithSnackbar
> {
  readonly state: IState = {
    voyage: undefined,
    voyageAllotments: undefined,
    isLoaded: false,
  };
  private _isMounted = false;
  private apiWrapper = this.props.api.wrapper;

  async componentDidMount() {
    this._isMounted = true;

    await this.loadVoyage();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  updateState(updatedState: Partial<IState>) {
    if (this._isMounted) {
      this.setState(updatedState);
    }
  }

  async loadVoyage() {
    let voyage;
    let voyageAllotments;
    let selectedVoyageId;

    const { voyageId } = this.props.match.params;

    if (voyageId && !isNaN(+voyageId)) {
      selectedVoyageId = +voyageId;
      try {
        voyage = await this.apiWrapper(
          VoyagesService.getVoyageById(selectedVoyageId)
        );

        voyageAllotments = await this.apiWrapper(
          VoyagesService.getAllotmentsByVoyage(+voyageId)
        );
      } catch (ex) {
        if (!(ex instanceof NotFoundError)) throw ex;
      }

      this.updateState({
        voyage,
        voyageAllotments,
        isLoaded: true,
      });
    }
  }

  onReleaseAllotment = async (allotmentId: number) => {
    const { voyageAllotments } = this.state;
    if (window.confirm('Are you sure you want to release the allotment?')) {
      try {
        await this.apiWrapper(AllotmentService.releaseAllotment(allotmentId));

        this.props.snackbar.showMessage('Allotment released!');

        const releasedAllotment = voyageAllotments?.find(
          (x) => x.id === allotmentId
        );

        if (releasedAllotment)
          releasedAllotment.status = AllotmentStatus.Released;

        this.updateState({
          voyageAllotments,
        });
      } catch {
        this.props.snackbar.showMessage('Failed to release allotment', {
          severity: 'error',
        });
      }
    }
  };

  render() {
    const { error } = this.props.api;
    if (error instanceof NotFoundError) return <NotFound404 />;
    if (error instanceof ForbiddenError) return <AccessDenied />;

    const { voyage, voyageAllotments, isLoaded } = this.state;

    if (!isLoaded) return null;
    else if (voyage && voyageAllotments)
      return (
        <ManageAllotments
          voyage={voyage}
          voyageAllotments={voyageAllotments}
          onReleaseAllotment={this.onReleaseAllotment}
        />
      );
    else return <NotFound404 />;
  }
}

export default withSnackbar(withApi(ManageAllotmentsContainer));
