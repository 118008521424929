import React, { useEffect } from 'react';
import { Prompt } from 'react-router';

const handler = (e: BeforeUnloadEvent) => {
  e.preventDefault();
  e.returnValue = true;
};
const UnsavedChangesPrompt: React.FC<{ when: boolean; message: string }> = ({
  when,
  message,
}) => {
  useEffect(() => {
    if (when) window.addEventListener('beforeunload', handler);
    else window.removeEventListener('beforeunload', handler);

    return () => window.removeEventListener('beforeunload', handler);
  }, [when]);

  useEffect(() => {
    if (when) document.title = '*' + document.title;
    return () => {
      if (when) document.title = document.title.substring(1);
    };
  });

  return <Prompt when={when} message={message} />;
};

export default UnsavedChangesPrompt;
