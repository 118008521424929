import React, { ReactNode } from 'react';
import { WithStyles, withStyles, Tooltip, IconButton } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
import styles from './TooltipIcon.styles';
import classNames from 'classnames';

interface IProps extends WithStyles<typeof styles> {
  title?: ReactNode;
}

const TooltipIcon: React.FC<IProps> = (props) => {
  const { classes, children, title } = props;
  const toolTipClassNames = classNames({
    [classes.toolTip]: title !== undefined,
    [classes.disabledTooltip]: title === undefined,
  });
  return (
    <div className={classes.container}>
      {children}
      <Tooltip title={title ?? ''} className={toolTipClassNames}>
        <IconButton aria-label="help">
          <HelpOutline />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default withStyles(styles)(TooltipIcon);
