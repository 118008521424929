import React, { ReactNode } from 'react';
import { Field } from 'react-final-form';
import {
  Checkbox,
  FormControlLabel,
  WithStyles,
  withStyles,
  FormControl,
  FormHelperText,
} from '@material-ui/core';
import styles from './FormCheckboxField.styles';
import { isChecked } from './validators';
import { FieldValidator } from 'final-form';
import TooltipIcon from '../components/TooltipIcon';
import Warning from '../../common/forms/Warning';

interface IProps extends WithStyles<typeof styles> {
  name: string;
  label: string;
  initialValue?: boolean;
  disabled?: boolean;
  required?: boolean;
  validate?: FieldValidator<boolean>;
  className?: string;
  toolTip?: ReactNode;
  warning?: string;
}

const FormCheckboxField: React.FC<IProps> = (props: IProps) => {
  const {
    name,
    label,
    initialValue = false,
    disabled = false,
    required = false,
    validate: customValidate = () => undefined,
    className,
    toolTip,
    classes,
    warning = '',
  } = props;

  return (
    <TooltipIcon title={toolTip}>
      <Field
        name={name}
        type="checkbox"
        initialValue={initialValue}
        validate={(value, allValues, meta) =>
          validate(value, props) || customValidate(value, allValues, meta)
        }
      >
        {({ input, meta }) => {
          let { value, ...restInput } = input;
          const hasError = meta.touched && meta.error !== undefined;
          const hasWarning = warning !== undefined;

          return (
            <FormControl
              required
              error={hasError}
              className={classes.container}
            >
              <FormControlLabel
                {...restInput}
                label={label}
                className={`${classes.checkbox} ${className}`}
                control={
                  <Checkbox
                    id={name}
                    required={required}
                    checked={value}
                    color="primary"
                  />
                }
                disabled={disabled}
              />
              {hasError && <FormHelperText>{meta.error}</FormHelperText>}
              {hasWarning && (
                <FormHelperText>
                  <Warning>{warning}</Warning>
                </FormHelperText>
              )}
            </FormControl>
          );
        }}
      </Field>
    </TooltipIcon>
  );
};

function validate(value: any, props: IProps) {
  var error = undefined;
  if (!error && props.required) {
    error = isChecked(value);
  }

  return error;
}

export default withStyles(styles)(FormCheckboxField);
