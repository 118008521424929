const enumValueFromHash = <
  T extends string,
  TEnumValue extends string | number
>(
  enumVariable: { [key in T]: TEnumValue },
  defaultVal: TEnumValue
) => (hash: string): TEnumValue =>
  Object.entries<TEnumValue>(enumVariable).find(
    ([k]) =>
      k.localeCompare(hash.substring(1), undefined, {
        sensitivity: 'accent',
      }) === 0
  )?.[1] ?? defaultVal;

export default enumValueFromHash;
