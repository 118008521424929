import React, { ReactNode, useMemo } from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  WithStyles,
  withStyles,
  Typography,
} from '@material-ui/core';
import IExtraService from '../../common/types/IExtraService';
import createFullName from '../../common/helpers/createFullName';
import PaxExtraServiceStatus, {
  isTakingCapacity as pesIsTakingCapacity,
  paxExtraServiceStatusOpts,
} from '../enums/paxExtraServiceStatus';
import { FieldArray } from 'react-final-form-arrays';
import { Field } from 'react-final-form';
import { FormDateField, FormSelectField } from '../../common/forms';
import styles from './PaxExtraServices.styles';
import IPax from '../../common/types/IPax';
import PaxStatus, {
  isTakingCapacity as paxIsTakingCapacity,
} from '../enums/paxStatus';
import { addDays } from 'date-fns';
import { useUser } from '../../common/contexts/UserContext';
import Role from '../../common/enums/Role';
import env from '../../core/env';

const validStatusChange = (
  existingStatus?: PaxExtraServiceStatus,
  newStatus?: PaxExtraServiceStatus
): boolean => {
  return (
    (existingStatus === undefined &&
      newStatus === PaxExtraServiceStatus.Option) ||
    (newStatus === PaxExtraServiceStatus.Confirmed &&
      existingStatus !== PaxExtraServiceStatus.Cancelled) ||
    existingStatus === newStatus
  );
};

const StatusOptions = (status: PaxStatus, initialValueExists: boolean) => {
  let options: Array<{
    value: string | undefined;
    label: ReactNode;
    disabled?: boolean;
  }> = paxExtraServiceStatusOpts;

  if (!initialValueExists)
    options = [
      {
        value: undefined,
        label: <em>None</em>,
      },
      ...options,
    ];

  if (status !== PaxStatus.Confirmed)
    options.find(
      (o) => o.value === PaxExtraServiceStatus.Confirmed
    )!.disabled = true;
  else
    options.find(
      (o) => o.value === PaxExtraServiceStatus.Confirmed
    )!.disabled = false;

  return options;
};

interface IProps extends WithStyles<typeof styles> {
  extraServices: IExtraService[];
  paxFormClasses?: string;
  pax: IPax;
}

const PaxExtraServices: React.FC<IProps> = ({
  extraServices,
  paxFormClasses,
  classes,
  pax,
}) => {
  const fullName = createFullName(pax.firstName, pax.middleName, pax.surname);
  const today = useMemo(() => new Date(), []);
  const optionExpiryDate = useMemo(
    () => addDays(new Date(), +env.REACT_APP_OPTION_EXPIRY_DAYS),
    []
  );
  const user = useUser();

  const renderStatusDate = (
    paxExtraServiceStatus: PaxExtraServiceStatus | undefined,
    extraServiceFieldName: string
  ) => {
    if (paxExtraServiceStatus === undefined) return null;

    let fieldName = '';
    let fieldLabel = '';
    let disableFuture = true;
    let initialValue;
    let disabled = !user?.hasRole(Role.OperationsManager);

    switch (paxExtraServiceStatus) {
      case PaxExtraServiceStatus.Option: {
        fieldLabel = 'Option Expiry Date';
        fieldName = `${extraServiceFieldName}.optionExpiryDate`;
        disableFuture = false;
        initialValue = optionExpiryDate;
        break;
      }
      case PaxExtraServiceStatus.Confirmed: {
        fieldLabel = 'Confirmed Date';
        fieldName = `${extraServiceFieldName}.confirmedDate`;
        initialValue = today;
        break;
      }
      case PaxExtraServiceStatus.ExpiredOption: {
        fieldLabel = 'Expired Option Date';
        fieldName = `${extraServiceFieldName}.expiredOptionDate`;
        initialValue = today;
        disableFuture = true;
        disabled = !user?.hasRole(Role.OperationsManager);
        break;
      }
      case PaxExtraServiceStatus.Cancelled: {
        fieldLabel = 'Cancelled Date';
        fieldName = `${extraServiceFieldName}.cancelledDate`;
        initialValue = today;
        disableFuture = true;
        disabled = !user?.hasRole(Role.OperationsManager);
        break;
      }
    }

    return (
      <FormDateField
        name={fieldName}
        label={fieldLabel}
        required={true}
        disableFuture={disableFuture}
        className={classes.extraServiceDate}
        initialValue={initialValue}
        disabled={disabled}
      />
    );
  };

  return (
    <Paper className={paxFormClasses} variant="outlined">
      <Typography align="left">Extra Services - {fullName}</Typography>
      <Table
        className={classes.table}
        size="small"
        aria-label="a dense table"
        stickyHeader
      >
        <TableHead>
          <TableRow>
            <TableCell>Activity</TableCell>
            <TableCell align="right">Available</TableCell>
            <TableCell>Status</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <FieldArray name={`data[id_${pax.id}]`}>
            {({ fields }) => (
              <React.Fragment>
                {fields.map((name) => (
                  <TableRow key={name} className={classes.tableRow}>
                    <Field name={`${name}`}>
                      {({ input, meta }) => {
                        const extraService = extraServices.find(
                          (es) =>
                            es.extraServiceId === input.value['extraServiceId']
                        );
                        const status = input.value['status'];

                        // disabled if pax status is not taking capacity or
                        // extra service status has not been manipulated,
                        // there is no availability and
                        // current extra service status is not holding space (cancelled, expired option or null)
                        const disabled =
                          !paxIsTakingCapacity(pax.status) ||
                          (meta.pristine &&
                            (extraService === undefined ||
                              extraService?.available < 1) &&
                            (status === undefined ||
                              !pesIsTakingCapacity(status)));

                        let options = StatusOptions(
                          pax.status,
                          !!meta.initial['status']
                        );

                        if (!user.hasRole(Role.OperationsManager))
                          options = options.map(
                            ({ value, label, disabled }) => ({
                              value,
                              label,
                              disabled:
                                disabled ||
                                !validStatusChange(
                                  meta.initial['status'],
                                  value as PaxExtraServiceStatus | undefined
                                ),
                            })
                          );

                        return (
                          <React.Fragment key={input.value['extraServiceId']}>
                            <TableCell>{extraService?.name}</TableCell>
                            <TableCell align="right">
                              {extraService?.available}
                            </TableCell>
                            <TableCell>
                              <FormSelectField
                                name={`${name}.status`}
                                options={options}
                                className={classes.extraServiceSelect}
                                disabled={disabled}
                              />
                            </TableCell>
                            <TableCell
                              className={classes.extraServicesDateCell}
                            >
                              {renderStatusDate(status, name)}
                            </TableCell>
                          </React.Fragment>
                        );
                      }}
                    </Field>
                  </TableRow>
                ))}
              </React.Fragment>
            )}
          </FieldArray>
        </TableBody>
      </Table>
    </Paper>
  );
};

export default withStyles(styles)(PaxExtraServices);
