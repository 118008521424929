import createAuth0Client from '@auth0/auth0-spa-js';
import Auth0ClientSPA from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import authConfig, { IAuthConfig } from './auth0Config';
import history from '../../core/History';
import Brand, { isValidBrand } from '../../pax/enums/brand';
import Role from '../enums/Role';
import {
  getIdTokenClaimsOptions,
  IdToken,
  RedirectLoginOptions,
  GetTokenSilentlyOptions,
  GetTokenWithPopupOptions,
  LogoutOptions,
  RedirectLoginResult,
} from '@auth0/auth0-spa-js/dist/typings/global';

const brandClaimType = 'https://marine.peakdmc.com/brand';
const roleClaimType =
  'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';

interface UserClaims {
  'https://marine.peakdmc.com/brand'?: Brand[];
  'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'?: Role[];
}

export interface IUserHas {
  HasRole: (role: Role, ...restOfRoles: Role[]) => boolean;
  HasBrand: (brand: Brand, ...restOfBrands: Brand[]) => boolean;
}

class Auth0Client {
  private config: IAuthConfig;
  private auth0Client: Auth0ClientSPA | undefined;
  public user: any;
  public isAuthenticated: boolean;
  public getIdTokenClaims?(
    options?: getIdTokenClaimsOptions
  ): Promise<IdToken & UserClaims>;
  public loginWithRedirect?(options?: RedirectLoginOptions): Promise<void>;
  public getTokenSilently?(options?: GetTokenSilentlyOptions): Promise<any>;
  public getTokenWithPopup?(
    options?: GetTokenWithPopupOptions
  ): Promise<string>;
  public logout?(options?: LogoutOptions): void;

  public getClaims = async () => {
    if (!this.getIdTokenClaims) {
      return {
        HasRole: (_any: unknown) => false,
        HasBrand: (_any: unknown) => false,
        Brands: [],
      };
    }
    const userClaims = await this.getIdTokenClaims();

    return {
      HasRole: (role: Role, ...restOfRoles: Role[]) => {
        const roles = [role, ...restOfRoles];
        return roles.some((role) => userClaims[roleClaimType]?.includes(role));
      },
      HasBrand: (brand: Brand, ...restOfBrands: Brand[]) => {
        const brands = [brand, ...restOfBrands];
        return brands.some((brand) =>
          userClaims[brandClaimType]?.includes(brand)
        );
      },
      Brands: this.getBrands(userClaims[brandClaimType]),
    };
  };

  constructor(config: IAuthConfig) {
    this.config = config;
    this.auth0Client = undefined;
    this.isAuthenticated = false;
    this.user = undefined;
  }

  private getBrands = (brandClaims: Brand[] | undefined): Brand[] => {
    const brands = new Array<Brand>();

    if (!brandClaims) return brands;

    brandClaims.forEach((bsc) => {
      if (isValidBrand(bsc))
        brands.push(
          Object.entries(Brand).find((e) => e[0] === bsc)![1] as Brand
        );
    });

    return brands;
  };

  private onRedirectCallback({ appState }: RedirectLoginResult) {
    history.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.origin
    );
  }

  public async intitialise(cb: () => void) {
    this.auth0Client = await createAuth0Client(this.config);

    this.getIdTokenClaims = (...p) => this.auth0Client!.getIdTokenClaims(...p);
    this.loginWithRedirect = (...p) =>
      this.auth0Client!.loginWithRedirect(...p);
    this.getTokenSilently = (...p) => this.auth0Client!.getTokenSilently(...p);
    this.getTokenWithPopup = (...p) =>
      this.auth0Client!.getTokenWithPopup(...p);
    this.logout = (...p) => this.auth0Client!.logout(...p);

    if (window.location.search.includes('code=')) {
      const loginResult = await this.auth0Client.handleRedirectCallback();
      this.onRedirectCallback(loginResult);
    }

    this.isAuthenticated = await this.auth0Client.isAuthenticated();

    if (!this.isAuthenticated) {
      this.auth0Client.loginWithRedirect({
        appState: { targetUrl: window.location.pathname },
      });
    }

    if (this.isAuthenticated) {
      this.user = await this.auth0Client.getUser();
      cb();
    }
  }
  async handleRedirectCallback() {
    await this.auth0Client!.handleRedirectCallback();
    this.user = await this.auth0Client!.getUser();
    this.isAuthenticated = true;
  }
}

export default new Auth0Client(authConfig);
