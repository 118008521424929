import { SortingRule } from 'react-table';

type SortOrder = 'ascending' | 'descending';

const convertForApi = <T extends object>(
  rule?: SortingRule<T>
): SortOrder | undefined => {
  if (rule?.desc === undefined) return;
  else if (rule?.desc) return 'descending';
  else return 'ascending';
};

export default SortOrder;
export { convertForApi };
