import React, { useState } from 'react';
import { FilterProps } from 'react-table';
import {
  MenuItem,
  IconButton,
  Menu,
  Checkbox,
  ListItemText,
} from '@material-ui/core';
import { FilterList } from '@material-ui/icons';
import { xor } from 'lodash';

const updateFilter = (option: string | number) => (
  filterValue: (string | number)[] | undefined
) => {
  if (filterValue === undefined) return [option];
  const index = filterValue.indexOf(option);
  if (index === -1) return [...filterValue, option];
  else return filterValue.filter((o) => o !== option);
};

const MultipleSelectFilter = <T extends object>(
  { column: { filterValue, setFilter, render } }: FilterProps<T>,
  options: Array<string | number>,
  defaultOption?: string | number
) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [localFilter, setLocalFilter] = useState<Array<string | number>>(
    filterValue
  );

  const onClick = (option: string | number) => (
    _event: React.MouseEvent<HTMLLIElement, MouseEvent>
  ) => {
    setLocalFilter(updateFilter(option));
  };

  return (
    <React.Fragment>
      <IconButton
        onClick={(e) => setAnchorEl(e.currentTarget)}
        color={localFilter === undefined ? 'default' : 'secondary'}
        size="small"
      >
        <FilterList />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
          if (xor(filterValue, localFilter).length !== 0)
            setFilter(localFilter);
        }}
      >
        {options.map((option) => (
          <MenuItem key={option} value={option} onClick={onClick(option)}>
            <Checkbox
              checked={localFilter?.indexOf(option) > -1}
              color="primary"
              indeterminate={
                option === defaultOption && !localFilter?.length
                  ? true
                  : undefined
              }
            />
            <ListItemText primary={render('Cell', { value: option })} />
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
};

export default MultipleSelectFilter;
