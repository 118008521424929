import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import VoyagesService from '../../services/VoyagesService';
import FinanceService from '../../services/FinanceService';
import IVoyage from '../../common/types/IVoyage';
import ISharingGroup from '../../common/types/ISharingGroup';
import VoyageManagement from './VoyageManagement';
import IAllotment from '../../common/types/IAllotment';
import scrollToElementByAnchorTag from '../../common/helpers/scrollToElementByAchorTag';
import IExtraService from '../../common/types/IExtraService';
import VoyagePageTab from '../../allotment/enums/VoyagePageTab';
import { withApi, IWithApi } from '../../common/contexts/ApiContext';
import NotFoundError from '../../services/errors/NotFoundError';
import ForbiddenError from '../../services/errors/ForbiddenError';
import NotFound404 from '../../common/components/NotFound404';
import AccessDenied from '../../common/components/AccessDenied';
import ISharingGroupOperationalInfo from '../../common/types/ISharingGroupOperationalInfo';
interface IState {
  voyages: IVoyage[];
  sharingGroups: ISharingGroup[];
  selectedVoyageAllotments: IAllotment[];
  selectedVoyageId?: number;
  selectedVoyageExtraServices: IExtraService[];
  tabSelected: VoyagePageTab;
  sharingGroupOperationalInfo: ISharingGroupOperationalInfo[];
}

class VoyageManagementContainer extends React.Component<
  RouteComponentProps & IWithApi
> {
  readonly state: Readonly<IState> = {
    voyages: [],
    sharingGroups: [],
    selectedVoyageAllotments: [],
    selectedVoyageId: undefined,
    selectedVoyageExtraServices: [],
    tabSelected: VoyagePageTab.Voyage,
    sharingGroupOperationalInfo: [],
  };
  private _isMounted = false;
  private apiWrapper = this.props.api.wrapper;

  componentWillReceiveProps(nextProps: RouteComponentProps) {
    if (nextProps.location.search !== this.props.location.search) {
      const values = new URLSearchParams(nextProps.location.search);
      const voyageId = values.get('voyageId');

      this.setState({
        sharingGroups: this._isMounted ? this.state.sharingGroups : undefined,
        selectedVoyageAllotments: this._isMounted
          ? this.state.selectedVoyageAllotments
          : undefined,
        selectedVoyageId: voyageId && !isNaN(+voyageId) ? +voyageId : undefined,
      });
    }
  }

  async componentDidMount() {
    this._isMounted = true;

    await this.loadVoyages();

    if (this.navigationHasExtraServicesAnchor())
      this.UpdateStateAndScrollIfAnchor(VoyagePageTab.ExtraServices);

    if (this.navigationHasCabinCategoryAnchor())
      this.UpdateStateAndScrollIfAnchor(VoyagePageTab.Voyage);
  }

  private UpdateStateAndScrollIfAnchor(tabSelected: VoyagePageTab) {
    this.updateState({ tabSelected });
    scrollToElementByAnchorTag(this.props.location.hash);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async loadVoyages() {
    const values = new URLSearchParams(this.props.location.search);
    const voyageId = values.get('voyageId');

    const voyages = await this.apiWrapper(VoyagesService.getVoyages());
    this.updateState({ voyages });

    if (voyageId && !isNaN(+voyageId)) {
      await this.onSelectVoyage(+voyageId);
    }
  }

  private navigationHasExtraServicesAnchor(): boolean {
    return this.navigationHasAnchor('extraService_');
  }

  private navigationHasCabinCategoryAnchor(): boolean {
    return this.navigationHasAnchor('cabinCategoryId_');
  }

  private navigationHasAnchor(anchorText: string): boolean {
    return (
      !!this.props.location.hash &&
      this.props.location.hash.startsWith(anchorText, 1)
    );
  }

  private handleTabChange = async (
    e: React.ChangeEvent<any>,
    selectedTab: VoyagePageTab
  ) => {
    this.setState({ tabSelected: selectedTab });
  };

  onSelectVoyage = async (selectedVoyageId: number) => {
    const sharingGroups = await this.apiWrapper(
      VoyagesService.getSharingGroups(selectedVoyageId)
    );
    const selectedVoyageAllotments = await this.apiWrapper(
      VoyagesService.getAllotmentsByVoyage(selectedVoyageId)
    );

    const selectedVoyageExtraServices = await this.apiWrapper(
      VoyagesService.getExtraServices(selectedVoyageId)
    );

    const sharingGroupOperationalInfo = await this.apiWrapper(
      VoyagesService.getSharingGroupOperationalInfo(selectedVoyageId)
    );

    this.updateState({
      selectedVoyageId,
      sharingGroups,
      selectedVoyageAllotments,
      selectedVoyageExtraServices,
      sharingGroupOperationalInfo,
    });
  };

  onDownloadSalesExtract = async () => {
    await this.apiWrapper(FinanceService.getSalesExtract());
  };

  updateState(updatedState: Partial<IState>) {
    if (this._isMounted) {
      this.setState(updatedState);
    }
  }

  render() {
    const {
      voyages,
      sharingGroups,
      selectedVoyageAllotments,
      selectedVoyageId,
      selectedVoyageExtraServices,
      tabSelected,
      sharingGroupOperationalInfo,
    } = this.state;

    const { error } = this.props.api;
    if (error instanceof NotFoundError) return <NotFound404 />;
    if (error instanceof ForbiddenError) return <AccessDenied />;

    return (
      <VoyageManagement
        voyages={voyages}
        sharingGroups={sharingGroups}
        selectedVoyageAllotments={selectedVoyageAllotments}
        selectedVoyageId={selectedVoyageId}
        onSelectVoyage={this.onSelectVoyage}
        onDownloadSalesExtract={this.onDownloadSalesExtract}
        selectedVoyageExtraServices={selectedVoyageExtraServices}
        tabSelected={tabSelected}
        handleTabChange={this.handleTabChange}
        sharingGroupOperationalInfo={sharingGroupOperationalInfo}
      />
    );
  }
}

export default withApi(withRouter(VoyageManagementContainer));
