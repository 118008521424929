import { createStyles } from '@material-ui/core/styles';

export default createStyles({
  alertContainer: {
    justifyContent: 'center',
    width: '80%',
    margin: '10px auto 10px auto',
    padding: '4px 8px 4px 8px',
  },
  alertIcon: {
    marginBottom: '-4px',
    padding: '0 10px 0 10px',
  },
  alertErrorIcon: {
    color: 'red',
  },
  alertWarningIcon: {
    color: 'orange',
  },
  alertText: {
    fontSize: '0.875rem',
  },
  alertBody: {
    verticalAlign: 'bottom',
  },
});
