import { createStyles } from '@material-ui/core/styles';

export default createStyles({
  alert: {
    minWidth: '400px',
  },
  snackbar: {
    paddingTop: '64px',
  },
});
