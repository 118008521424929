export default function isBelowMaxLength(
  value: number | null | undefined,
  max: number
) {
  if (value !== undefined && value !== null && value > max) {
    return `Value must be ${max} or less`;
  }

  return undefined;
}
