import IPaxExtraService from '../../common/types/IPaxExtraService';
import ISharingGroup from '../../common/types/ISharingGroup';
import IExtraService from '../../common/types/IExtraService';
import { groupBy, flatten, values, Dictionary } from 'lodash';

export default interface IPaxExtraServicesForm {
  data?: Dictionary<IPaxExtraService[]>;
}

export const ConvertPaxExtraServicesFormToApi = (
  paxExtraServicesForm: IPaxExtraServicesForm
): IPaxExtraService[] => {
  const flattenedPaxExtraServices = flatten(values(paxExtraServicesForm.data));

  return flattenedPaxExtraServices.filter((pes) => pes.status);
};

export const PopulatePaxExtraServicesForm = (
  sharingGroup: ISharingGroup,
  paxExtraServices: IPaxExtraService[],
  extraServices: IExtraService[]
): IPaxExtraServicesForm => {
  let paxExtraServicesFormData: IPaxExtraService[] = [];

  sharingGroup.pax.forEach((p) => {
    const combinedPaxExtraServices: IPaxExtraService[] = extraServices
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((es) => {
        const paxExtraService = paxExtraServices.find(
          (pes) =>
            pes.paxId === p.id && pes.extraServiceId === es.extraServiceId
        );

        return {
          voyageId: es.voyageId,
          extraServiceId: es.extraServiceId,
          paxId: p.id,
          status: paxExtraService?.status,
          optionExpiryDate: paxExtraService?.optionExpiryDate ?? undefined,
          confirmedDate: paxExtraService?.confirmedDate ?? undefined,
          expiredOptionDate: paxExtraService?.expiredOptionDate ?? undefined,
          cancelledDate: paxExtraService?.cancelledDate ?? undefined,
        };
      });

    paxExtraServicesFormData.push(...combinedPaxExtraServices);
  });

  const paxExtraServicesForm: IPaxExtraServicesForm = {
    data: groupBy(paxExtraServicesFormData, (o) => 'id_' + o.paxId),
  };

  return paxExtraServicesForm;
};
