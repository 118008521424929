import IVoyage from '../types/IVoyage';

const cabinCat = (voyage?: IVoyage) => (cabinCategoryId?: number) =>
  voyage?.cabinCategories.find((cc) => cc.id === cabinCategoryId);

const cabinCatSort = (voyage?: IVoyage) => (
  cabinCategoryIdA: number,
  cabinCategoryIdB: number
) => {
  const cabinCatNameA = cabinCat(voyage)(cabinCategoryIdA)?.name ?? '';
  const cabinCatNameB = cabinCat(voyage)(cabinCategoryIdB)?.name ?? '';
  return cabinCatNameA.localeCompare(cabinCatNameB, 'en', {
    numeric: true,
  });
};

const voyage = (voyages: IVoyage[]) => (cabinCategoryId: number) =>
  voyages.find((v) =>
    v.cabinCategories.find((cc) => cc.id === cabinCategoryId)
  );

const cabinCatAllVoyages = (voyages: IVoyage[]) => (cabinCategoryId: number) =>
  voyages
    .flatMap((v) => v.cabinCategories)
    .find((cc) => cc.id === cabinCategoryId);

export { cabinCat, cabinCatSort, cabinCatAllVoyages, voyage };
