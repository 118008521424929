import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import AccessDenied from './AccessDenied';

interface IProps extends RouteProps {
  isAuthorized: boolean;
}

const ProtectedRoute: React.FC<IProps> = ({ isAuthorized, ...rest }) => {
  return isAuthorized ? <Route {...rest} /> : <AccessDenied />;
};

export default ProtectedRoute;
