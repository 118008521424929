import { createStyles } from '@material-ui/core/styles';

export default createStyles({
  container: {
    display: 'inline-flex',
    float: 'left',
  },
  toolTip: {
    marginTop: '25px',
    marginBottom: 'auto',
  },
  disabledTooltip: {
    extend: 'toolTip',
    visibility: 'hidden',
  },
});
