import regex from '../constants/regex';

export default function isoStringToDateReviver(value: any) {
  const isString = typeof value === 'string';
  if (value && isString && value.match(regex.ISO_STRING_DATE_REGEX)) {
    return new Date(value);
  }

  return value;
}
