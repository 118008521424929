enum PaxExtraServiceStatus {
  Option = 'option',
  Confirmed = 'confirmed',
  Cancelled = 'cancelled',
  ExpiredOption = 'expiredOption',
}

export const paxExtraServiceStatusLabels: Readonly<Record<
  PaxExtraServiceStatus,
  string
>> = {
  [PaxExtraServiceStatus.Option]: 'Option',
  [PaxExtraServiceStatus.Confirmed]: 'Confirmed',
  [PaxExtraServiceStatus.Cancelled]: 'Cancelled',
  [PaxExtraServiceStatus.ExpiredOption]: 'Expired Option',
};

export const paxExtraServiceStatusOpts = Object.entries(
  paxExtraServiceStatusLabels
).map(([value, label]) => {
  return { value, label };
});

export type TakingCapacity =
  | PaxExtraServiceStatus.Confirmed
  | PaxExtraServiceStatus.Option;
export type NotTakingCapacity = Exclude<PaxExtraServiceStatus, TakingCapacity>;

export const isTakingCapacity = (
  status: PaxExtraServiceStatus
): status is TakingCapacity => {
  return (
    status === PaxExtraServiceStatus.Confirmed ||
    status === PaxExtraServiceStatus.Option
  );
};

export default PaxExtraServiceStatus;
