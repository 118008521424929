import { createStyles } from '@material-ui/core/styles';

export default createStyles({
  loadingBar: {
    position: 'fixed',
    width: '100%',
    zIndex: 1000,
  },
  displayed: {
    visibility: 'visible',
  },
  hidden: {
    visibility: 'hidden',
  },
});
