import React, { useMemo } from 'react';
import IVoyage from '../../common/types/IVoyage';
import VoyageSelect from '../../common/components/VoyageSelect';
import IPaxForm, { IForm } from '../../common/types/IPaxForm';
import ISharingGroup from '../../common/types/ISharingGroup';
import createFullName from '../../common/helpers/createFullName';
import {
  Container,
  Grid,
  IconButton,
  Tooltip,
  makeStyles,
} from '@material-ui/core';
import { brandLabels } from '../../pax/enums/brand';
import { Column } from 'react-table';
import IPax from '../../common/types/IPax';
import { DoneAll, Done } from '@material-ui/icons';
import PageHeader from '../../common/components/PageHeader';
import { FileCopy } from '@material-ui/icons';
import env from '../../core/env';
import copy from 'copy-to-clipboard';
import { useSnackbar } from '../../common/contexts/SnackbarContext';
import styles from './PreTravelInformation.styles';
import FancyTable from '../../common/components/FancyTable';
import SelectCompletionFilter from '../../common/components/SelectCompletionFilter';
import { cabinCat, cabinCatSort } from '../../common/helpers/cabinCat';

const useStyles = makeStyles(styles);

type PaxRow = IPax & Omit<ISharingGroup, 'pax'> & IPaxForm;

interface IProps {
  voyages: IVoyage[];
  onChange: (event: React.ChangeEvent<any> | any) => void;
  selectedVoyage?: IVoyage;
  paxForms: IPaxForm[];
  sharingGroups: ISharingGroup[];
  forms: IForm[];
}

const PreTravelInformation: React.FC<IProps> = ({
  voyages,
  onChange,
  selectedVoyage,
  paxForms,
  sharingGroups,
  forms,
}) => {
  const classes = useStyles();
  const { showMessage } = useSnackbar();

  const defaultColumn = useMemo(
    () => ({
      Filter: <React.Fragment />,
      disableSortBy: true,
    }),
    []
  );

  const columns: Column<PaxRow>[] = useMemo(
    () => [
      {
        Header: 'Cabin Category',
        accessor: 'cabinCategoryId',
        id: 'cabinCategory',
        sortType: (a, b) =>
          cabinCatSort(selectedVoyage)(
            a.original.bookedCabinCategoryId,
            b.original.bookedCabinCategoryId
          ),
        Cell: ({ value }) => cabinCat(selectedVoyage)(value)?.name ?? null,
      },
      {
        Header: 'Brand',
        accessor: 'brand',
        Cell: ({ value }) => brandLabels[value],
      },
      {
        Header: 'Booking Ref',
        accessor: 'bookingReference',
      },
      {
        Header: 'Name',
        accessor: ({ firstName, middleName, surname }) =>
          createFullName(firstName, middleName, surname),
      },
      ...forms.map<Column<PaxRow>>((f) => ({
        Header: f.name,
        accessor: ({ forms }) => {
          const form = forms.find((g) => g.id === f.id);
          if (!!form?.submissionDate) return true;
          if (form?.submissionDate === undefined || form?.optional) return null;
          if (form?.submissionDate === null) return false;
          return null;
        },
        Cell: ({ value }: { value: boolean | null }) => {
          if (value === null) return null;
          else if (value) return <Done />;
          else return '-';
        },
        className: classes.centeredCell,
        Filter: SelectCompletionFilter,
        filter: (rows, columnIds, filterValue) =>
          rows.filter((r) =>
            columnIds.some(
              (id) =>
                r.values[id] === filterValue ||
                filterValue === undefined ||
                (r.values[id] === null && filterValue)
            )
          ),
      })),
      {
        Header: 'Complete',
        id: 'complete',
        accessor: ({ forms }) =>
          forms.every((f) => f.submissionDate !== null || f.optional),
        Cell: ({ value }: { value: boolean }) => (value ? <DoneAll /> : '-'),
        Filter: SelectCompletionFilter,
        className: classes.centeredCell,
        filter: (rows, columnIds, filterValue) =>
          rows.filter((r) =>
            columnIds.some(
              (id) =>
                filterValue === undefined || r.values[id] === !!filterValue
            )
          ),
      },
      {
        Header: 'Pax Forms Link',
        id: 'paxLink',
        accessor: ({ firstName, middleName, surname, guid }) => {
          const url = new URL(guid, env.REACT_APP_FORMS_URL);
          url.searchParams.set(
            'passengerName',
            createFullName(firstName, middleName, surname).toString()
          );
          return url.toString();
        },
        Cell: ({ value }: { value: string }) => (
          <Tooltip title="Copy to clipboard">
            <IconButton
              aria-label="copyToClipboard"
              color="inherit"
              onClick={() => {
                copy(value);
                showMessage('Link copied!');
              }}
            >
              <FileCopy />
            </IconButton>
          </Tooltip>
        ),
        className: classes.centeredCell,
      },
    ],
    [forms, classes.centeredCell, selectedVoyage, showMessage]
  );

  const data: PaxRow[] = useMemo(() => {
    let rows: PaxRow[] = [];
    sharingGroups.forEach((sharingGroup) => {
      const { pax, ...sg } = sharingGroup;
      pax.forEach((p) => {
        const pForms = paxForms.find((pf) => pf.guid === p.paxGuid);
        if (pForms) rows.push({ ...p, ...sg, ...pForms });
      });
    });
    return rows;
  }, [paxForms, sharingGroups]);

  const initialState = useMemo(
    () => ({
      sortBy: [
        {
          id: 'cabinCategory',
        },
      ],
    }),
    []
  );

  return (
    <React.Fragment>
      <PageHeader
        title="Pre Travel Information"
        description="Select a voyage to show the status of passenger pre travel information collection."
      />
      <Grid direction="column" spacing={3} container>
        <Grid item>
          <VoyageSelect
            voyages={voyages}
            onChange={onChange}
            selectedVoyageId={selectedVoyage?.id}
          />
        </Grid>
        {selectedVoyage && (
          <Grid item>
            <Container maxWidth="xl">
              <FancyTable
                columns={columns}
                data={data}
                initialState={initialState}
                defaultColumn={defaultColumn}
                disableGlobalFilter
              />
            </Container>
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
};

export default PreTravelInformation;
