import { createStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';

export default createStyles({
  filtersPaper: {
    margin: '15px 0px 15px 5px',
    padding: '15px 20px 15px 20px',
    textAlign: 'left',
    backgroundColor: grey[50],
  },
  filtersText: {
    padding: '5px 0px 20px 0px',
    fontWeight: 'bold',
  },
});
