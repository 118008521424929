import { createStyles } from '@material-ui/core/styles';

export default createStyles({
  container: {
    display: 'block',
  },
  checkbox: {
    paddingTop: '27px',
  },
});
