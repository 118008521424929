import React from 'react';
import FormSelectAutoCompleteField, {
  IProps as IBaseProps,
} from './FormSelectAutoCompleteField';
import countries from 'i18n-iso-countries';
import en from 'i18n-iso-countries/langs/en.json';

countries.registerLocale(en);

const getOptions = (includeNoneOption: boolean) => {
  let options = [];
  if (includeNoneOption)
    options.push({ value: null, label: '', view: <em>None</em> });
  options.push(
    ...Object.entries(countries.getNames('en', { select: 'official' }))
      .map(([value, label]) => {
        return { value: value.toLowerCase(), label };
      })
      .sort((a, b) => a.label.localeCompare(b.label, 'en'))
  );

  return options;
};
interface IProps {
  includeNoneOption: boolean;
}

const FormCountryField: React.FC<
  IProps &
    Pick<
      IBaseProps,
      | 'name'
      | 'label'
      | 'className'
      | 'required'
      | 'disabled'
      | 'defaultValue'
      | 'toolTip'
    >
> = ({ includeNoneOption = false, ...props }) => {
  return (
    <FormSelectAutoCompleteField
      options={getOptions(includeNoneOption)}
      {...props}
    />
  );
};

export default FormCountryField;
