import React from 'react';
import VoyagesService from '../../services/VoyagesService';
import PaxService from '../../services/PaxService';
import IVoyage from '../../common/types/IVoyage';
import CabinAssignment from './CabinAssignment';
import ICabinAssignment from '../../common/types/ICabinAssignment';
import { withRouter, RouteComponentProps } from 'react-router';
import IAllotment from '../../common/types/IAllotment';
import NotFound404 from '../../common/components/NotFound404';
import NotFoundError from '../../services/errors/NotFoundError';
import { withApi, IWithApi } from '../../common/contexts/ApiContext';
import ForbiddenError from '../../services/errors/ForbiddenError';
import AccessDenied from '../../common/components/AccessDenied';
import ISharingGroupCombined from '../../common/types/ISharingGroupCombined';
import {
  IWithSnackbar,
  withSnackbar,
} from '../../common/contexts/SnackbarContext';

interface IState {
  voyage?: IVoyage;
  voyageAllotments: IAllotment[];
  voyageId?: number;
  cabinCategoryId?: number;
  isLoaded: boolean;
  enhancedSharingGroups: ISharingGroupCombined[];
}

interface RouteParams {
  voyageId?: string;
}

class CabinAssignmentContainer extends React.Component<
  RouteComponentProps<RouteParams> & IWithApi & IWithSnackbar
> {
  readonly state: IState = {
    voyage: undefined,
    voyageAllotments: [],
    voyageId: this.GetVoyageIdFromURL(),
    cabinCategoryId: this.GetCabinCategoryIdFromURL(),
    isLoaded: false,
    enhancedSharingGroups: [],
  };

  private _isMounted = false;
  private apiWrapper = this.props.api.wrapper;

  componentWillReceiveProps(nextProps: RouteComponentProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({
        sharingGroups: [],
        voyageAllotments: [],
        voyageId: undefined,
        cabinCategoryId: undefined,
      });
    }
  }

  async componentDidMount() {
    this._isMounted = true;

    await this.loadData();
  }

  async loadData() {
    const { voyageId } = this.state;
    let voyage: IVoyage | undefined;
    let enhancedSharingGroups: ISharingGroupCombined[] = [];
    let voyageAllotments: IAllotment[] = [];

    try {
      if (voyageId) {
        voyage = await this.apiWrapper(VoyagesService.getVoyageById(voyageId));

        const sharingGroups = await this.apiWrapper(
          VoyagesService.getSharingGroups(voyageId)
        );

        voyageAllotments = await this.apiWrapper(
          VoyagesService.getAllotmentsByVoyage(voyageId)
        );

        enhancedSharingGroups = await Promise.all(
          sharingGroups.map(async (sg) => ({
            operationalInfo: await PaxService.getOperationalInfo(sg.groupId),
            sharingGroup: sg,
          }))
        );
      }
    } catch (ex) {
      if (!(ex instanceof NotFoundError)) throw ex;
    }

    this.updateState({
      voyage,
      voyageAllotments,
      isLoaded: true,
      enhancedSharingGroups,
    });
  }

  private GetVoyageIdFromURL() {
    const { voyageId } = this.props.match.params;
    return voyageId && !isNaN(+voyageId) ? +voyageId : undefined;
  }

  private GetCabinCategoryIdFromURL() {
    const values = new URLSearchParams(this.props.location.search);
    const cabinCategoryId = values.get('cabinCategoryId');
    return cabinCategoryId && !isNaN(+cabinCategoryId)
      ? +cabinCategoryId
      : undefined;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onSelectCabinCategory = async (cabinCategoryId: number) => {
    this.updateState({
      cabinCategoryId: cabinCategoryId,
    });
  };

  onCabinConfigurationSave = async (
    cabinConfigurations: ICabinAssignment[]
  ) => {
    try {
      const voyageId = this.state.voyageId;
      // Save
      if (voyageId) {
        await this.apiWrapper(
          VoyagesService.assignCabins(voyageId, cabinConfigurations)
        );
        this.props.snackbar.showMessage(
          'Cabin Assignments submitted successfully',
          { autoHideDuration: 8000, disableClickaway: true }
        );

        await this.loadData();
      }
    } catch (ex) {
      this.props.snackbar.showMessage(
        ex.errors ? ex.errors.join('\n') : ex.message,
        { severity: 'error' }
      );
    }
  };

  updateState(updatedState: Partial<IState>) {
    if (this._isMounted) {
      this.setState(updatedState);
    }
  }

  render() {
    const { error } = this.props.api;
    if (error instanceof NotFoundError) return <NotFound404 />;
    if (error instanceof ForbiddenError) return <AccessDenied />;

    const {
      voyage,
      enhancedSharingGroups,
      voyageAllotments,
      isLoaded,
    } = this.state;

    if (!isLoaded) return null;
    else if (voyage)
      return (
        <CabinAssignment
          voyage={voyage}
          onSelectCabinCategory={this.onSelectCabinCategory}
          voyageAllotments={voyageAllotments}
          onCabinConfigurationSave={this.onCabinConfigurationSave}
          selectedCabinCategoryId={this.state.cabinCategoryId}
          enhancedSharingGroups={enhancedSharingGroups}
        />
      );
    else return <NotFound404 />;
  }
}

export default withApi(withSnackbar(withRouter(CabinAssignmentContainer)));
