import React, { useMemo } from 'react';
import { useFormState } from 'react-final-form';
import { Button, Grid, Container, makeStyles } from '@material-ui/core';
import styles from './PaxListForm.styles';
import IVoyage from '../../../common/types/IVoyage';
import FormSelectField from '../../../common/forms/FormSelectField';
import WhenFieldChanges from '../../../common/forms/WhenFieldChanges';
import FormCheckboxField from '../../../common/forms/FormCheckboxField';
import PaxStatus, { isTakingCapacity } from '../../enums/paxStatus';
import getAvailabilityText from '../../../common/helpers/getAvailabilityText';
import IAllotment from '../../../common/types/IAllotment';
import ISharingGroup from '../../../common/types/ISharingGroup';
import { getBrandOptions } from '../../enums/brand';
import { useUser } from '../../../common/contexts/UserContext';
import Role from '../../../common/enums/Role';
import { cabinCat } from '../../../common/helpers/cabinCat';
import { useFieldArray } from 'react-final-form-arrays';

const useStyles = makeStyles(styles);

const PaxListFormHeader: React.FC<{
  disabled: boolean;
  allotment: IAllotment;
  voyage: IVoyage;
}> = ({ disabled, allotment, voyage }) => {
  const user = useUser();
  const classes = useStyles();

  const brandOpts = useMemo(
    () =>
      getBrandOptions(
        user.brands.filter(
          (b) => allotment.defaultAllotment || b === allotment.brand
        )
      ),
    [allotment.brand, allotment.defaultAllotment, user.brands]
  );

  const { initialValues, values } = useFormState<ISharingGroup>();

  const paxFieldArray = useFieldArray('pax');
  const isSingleSuppDisabled = !cabinCat(voyage)(values.bookedCabinCategoryId)
    ?.availableForSingleSupp;

  const paxCount =
    values?.pax?.filter((p) => p && isTakingCapacity(p.status)).length ?? 0;

  const bookedCabinCapacity =
    cabinCat(voyage)(values.bookedCabinCategoryId)?.cabinCapacity ?? 0;

  const singleSuppNA = useMemo(() => {
    const singleSupp = values.bookedSingleSupplement;
    const cabinCategory = cabinCat(voyage)(values.bookedCabinCategoryId);

    const singleSuppNA =
      singleSupp &&
      (cabinCategory?.availableForSingleSupp ?? false) &&
      (cabinCategory?.cabinCapacity ?? 0) <= paxCount;

    if (singleSuppNA) return 'Not applicable when cabin is full';
  }, [
    paxCount,
    values.bookedCabinCategoryId,
    values.bookedSingleSupplement,
    voyage,
  ]);

  const isHeaderDisabledByRole =
    !user.hasRole(Role.OperationsManager) &&
    initialValues?.pax?.some((p) =>
      [
        PaxStatus.Confirmed,
        PaxStatus.ExpiredOption,
        PaxStatus.Cancelled,
      ].includes(p.status)
    );

  return (
    <Container maxWidth="md">
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="center"
      >
        <Grid
          container
          item
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          md={12}
        >
          <Grid item md={4}>
            <FormSelectField
              name="brand"
              label="Brand"
              options={brandOpts}
              className={classes.inputField}
              required={true}
              disabled={
                brandOpts.length <= 1 || disabled || isHeaderDisabledByRole
              }
            />
          </Grid>
          <Grid item md={8}>
            <FormSelectField
              name="bookedCabinCategoryId"
              label="Cabin Category"
              options={allotment.allocations
                .map((a) => ({
                  value: a.cabinCategoryId,
                  label: `${
                    cabinCat(voyage)(a.cabinCategoryId)?.name
                  } (${getAvailabilityText(a.availability)})`,
                  disabled:
                    a.availability.unallocatedBerths <= 0 &&
                    a.availability.femaleOnlyBerths <= 0 &&
                    a.availability.maleOnlyBerths <= 0 &&
                    a.cabinCategoryId !== initialValues?.bookedCabinCategoryId,
                }))
                .sort((a, b) =>
                  a.label.localeCompare(b.label, 'en', {
                    numeric: true,
                  })
                )}
              className={classes.inputFieldCabinCat}
              disabled={disabled || isHeaderDisabledByRole}
              required={true}
              toolTip={
                <span>
                  Cabin Categories are displayed in the following format:
                  <br />
                  <br />
                  Category - Description (Available Berths)
                </span>
              }
            />
          </Grid>
        </Grid>
        <Grid
          container
          item
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          md={12}
        >
          <Grid item md={4}>
            <FormCheckboxField
              name={`bookedSingleSupplement`}
              label={'Single Supplement'}
              disabled={
                isSingleSuppDisabled || disabled || isHeaderDisabledByRole
              }
              toolTip={
                'Single Supplement is only available for some cabin categories'
              }
              warning={singleSuppNA}
            />
            <WhenFieldChanges
              changedField="bookedCabinCategoryId"
              shouldUpdate={true}
              updateField="bookedSingleSupplement"
              updateValue={false}
            />
          </Grid>
          <Grid item md={4}>
            <Button
              variant="contained"
              color="primary"
              aria-label="add"
              onClick={() => paxFieldArray.fields.push(undefined)}
              disabled={
                !values.bookedAllotmentId ||
                !values.bookedCabinCategoryId ||
                paxCount >= bookedCabinCapacity ||
                disabled
              }
              className={classes.addPaxButton}
            >
              Add Passenger
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PaxListFormHeader;
