import { createStyles } from '@material-ui/core/styles';

export default createStyles({
  buttons: {
    '& > *': {
      margin: '8px',
    },
  },
  inputField: {
    width: '200px',
  },
  inputFieldCabinCat: {
    width: '360px',
  },
  inputFieldNotes: {
    width: '200px',
  },
  tooltipContainer: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  fieldContainer: {
    textAlign: 'left',
    flexBasis: '33%',
    flex: 1,
    alignSelf: 'flex-start',
  },
  buttonContainer: {
    textAlign: 'right',
    flexBasis: '100%',
    flex: 1,
    alignSelf: 'flex-start',
  },

  fieldsContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: '1200px',
    margin: 'auto',
    flexWrap: 'wrap',
  },
  itemContainer: {
    display: 'flex',
  },
  paxDetailsContainer: {
    padding: '15px 0px 15px 25px',
  },
  paxDetailsContainerCancelled: {
    borderColor: 'red',
  },
  paxExtraServiceHidden: {
    visibility: 'hidden',
  },

  alignTop: {
    verticalAlign: 'top',
  },
  addPaxButton: {
    marginTop: '25px',
    marginBottom: 'auto',
  },
  tabs: {
    width: '1080px',
    margin: 'auto',
  },
  operationalInfoGridItem: {
    width: '809px',
  },
});
