enum Brand {
  Chimu = 'chimu',
  Intrepid = 'intrepid',
  OpCo = 'opCo',
}

export const brandLabels: Readonly<Record<Brand, string>> = {
  [Brand.Chimu]: 'Chimu',
  [Brand.Intrepid]: 'Intrepid',
  [Brand.OpCo]: 'OpCo',
};

export const brandOpts = Object.entries(brandLabels).map(([value, label]) => ({
  value,
  label,
})) as { value: Brand; label: string }[];

export const getBrandOptions = (
  brandOptions: Brand[]
): Array<{ value: string; label: string }> => {
  return brandOpts.filter((b) => brandOptions.includes(b.value));
};

export const isValidBrand = (brand: string): boolean => {
  const brandKeys = Object.keys(Brand).filter((x) => !(parseInt(x) >= 0));
  return brandKeys.includes(brand);
};

export default Brand;
