import IAvailability from '../types/IAvailability';

export default function getAvailabilityText(availability: IAvailability) {
  // No available berths
  if (
    availability.maleOnlyBerths === 0 &&
    availability.femaleOnlyBerths === 0 &&
    availability.unallocatedBerths === 0
  ) {
    return `0`;
  }

  // Gender and unallocated
  let cabinAvailabilityText: string[] = [];

  if (availability.unallocatedBerths !== 0) {
    cabinAvailabilityText.push(`${availability.unallocatedBerths}`);
  }

  if (availability.maleOnlyBerths !== 0) {
    cabinAvailabilityText.push(`${availability.maleOnlyBerths}M`);
  }

  if (availability.femaleOnlyBerths !== 0) {
    cabinAvailabilityText.push(`${availability.femaleOnlyBerths}F`);
  }

  return cabinAvailabilityText.join(', ').trim();
}
