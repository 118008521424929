import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Link,
  Typography,
} from '@material-ui/core';
import IExtraService from '../../common/types/IExtraService';
import PaxExtraServiceStatus from '../../pax/enums/paxExtraServiceStatus';
import { HashLink } from 'react-router-hash-link';
import Routes from '../../common/constants/routes';

interface IProps {
  extraServices: IExtraService[];
  renderHashLinks?: boolean;
}

const VoyageExtraServiceSummary: React.FC<IProps> = ({
  extraServices,
  renderHashLinks = true,
}) => {
  if (extraServices.length === 0) {
    return (
      <Typography align="left">
        No extra services available for selected voyage
      </Typography>
    );
  }

  return (
    <Table size="small" aria-label="Extra Services" stickyHeader>
      <TableHead>
        <TableRow>
          <TableCell>Activity</TableCell>
          <TableCell align="right">Available</TableCell>
          <TableCell align="right">Option</TableCell>
          <TableCell align="right">Confirmed</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {extraServices
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((es) => {
            const optionCount = es.paxExtraServices.filter(
              (pes) => pes.status === PaxExtraServiceStatus.Option
            ).length;

            const confirmedCount = es.paxExtraServices.filter(
              (pes) => pes.status === PaxExtraServiceStatus.Confirmed
            ).length;

            return (
              <React.Fragment key={'extraService_' + es.extraServiceId}>
                <TableRow key={'extraService_' + es.name}>
                  <TableCell
                    data-attr={`extraService_${es.extraServiceId}.name`}
                  >
                    {renderHashLinks ? (
                      <Link
                        component={HashLink}
                        to={Routes.VoyageManagement.getLocation(
                          es.voyageId,
                          undefined,
                          es.extraServiceId
                        )}
                        smooth
                      >
                        {es.name}
                      </Link>
                    ) : (
                      es.name
                    )}
                  </TableCell>
                  <TableCell
                    align="right"
                    data-attr={`extraService_${es.extraServiceId}.available`}
                  >
                    {es.available}
                  </TableCell>
                  <TableCell
                    align="right"
                    data-attr={`extraService_${es.extraServiceId}.option`}
                  >
                    {optionCount}
                  </TableCell>
                  <TableCell
                    align="right"
                    data-attr={`extraService_${es.extraServiceId}.confirmed`}
                  >
                    {confirmedCount}
                  </TableCell>
                </TableRow>
              </React.Fragment>
            );
          })}
      </TableBody>
    </Table>
  );
};

export default VoyageExtraServiceSummary;
