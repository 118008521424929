import React, { useState } from 'react';
import styles from './PaxExtraServicesDetails.styles';
import {
  WithStyles,
  withStyles,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import IExtraService from '../types/IExtraService';
import PaxExtraServiceStatus, {
  paxExtraServiceStatusLabels,
  paxExtraServiceStatusOpts,
} from '../../pax/enums/paxExtraServiceStatus';
import formatDate from '../helpers/formatDate';
import { HashLink } from 'react-router-hash-link';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { brandLabels } from '../../pax/enums/brand';
import ISharingGroup from '../types/ISharingGroup';
import Filters, { IFilters } from './Filters';
import createFullName from '../helpers/createFullName';

interface IProps extends WithStyles<typeof styles> {
  extraServices: IExtraService[];
  sharingGroups: ISharingGroup[];
  classes: any;
}

type Props = IProps & RouteComponentProps;

const PaxExtraServicesDetails: React.FC<Props> = ({
  sharingGroups,
  extraServices,
  classes,
  history,
}) => {
  const currentLocation = history.location;

  const [filters, setFilters] = useState<IFilters>({
    statuses: [PaxExtraServiceStatus.Option, PaxExtraServiceStatus.Confirmed],
  });

  const extraServicesFiltered = extraServices
    .map((es) => ({
      ...es,
      paxExtraServices: es.paxExtraServices.filter((pes) =>
        filters.statuses.includes(pes.status!)
      ),
    }))
    .filter((es) => es.paxExtraServices.length > 0);

  const handleStatusChange = (e: React.ChangeEvent<any> | any) => {
    setFilters({ ...filters, statuses: e.target.value });
  };

  if (extraServices.length === 0) return null;

  return (
    <div className={classes.paxExtraServicesContainer}>
      <Grid container direction="column">
        <Grid item xs className={classes.paxExtraServicesTitle}>
          Extra Services Passengers
        </Grid>
        <Grid>
          <Filters
            filters={filters}
            handleStatusChange={handleStatusChange}
            statusesOptions={paxExtraServiceStatusOpts}
          ></Filters>
        </Grid>
        <Grid item>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Booking Ref</TableCell>
                <TableCell>Brand</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Status Date</TableCell>
                <TableCell align="center">
                  <Tooltip title="Scroll to top">
                    <IconButton className={classes.scrollToTopButton}>
                      <HashLink
                        to={{
                          hash: '#root',
                          pathname: currentLocation.pathname,
                          search: currentLocation.search,
                        }}
                        smooth
                      >
                        <KeyboardArrowUpIcon />
                      </HashLink>
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {extraServicesFiltered
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((extraService) => {
                  return (
                    <PaxExtraServiceDetailsItem
                      sharingGroups={sharingGroups}
                      extraService={extraService}
                      classes={classes}
                      key={extraService.extraServiceId}
                    />
                  );
                })}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </div>
  );
};

const PaxExtraServiceDetailsItem: React.FC<{
  sharingGroups: ISharingGroup[];
  extraService: IExtraService;
  classes?: any;
}> = ({ sharingGroups, extraService, classes }) => {
  return (
    <React.Fragment>
      <TableRow className={classes.paxExtraServicesItemTitle}>
        <TableCell
          component="th"
          scope="row"
          colSpan={8}
          id={`extraService_${extraService.extraServiceId}`}
        >
          {extraService.name}
        </TableCell>
      </TableRow>

      {extraService.paxExtraServices.map((pes) => {
        const sharingGroup = sharingGroups.find((sg) =>
          sg.pax.find((p) => p.id === pes.paxId)
        )!;
        const passenger = sharingGroup.pax.find((p) => p.id === pes.paxId);

        let statusDate = '';
        switch (pes.status) {
          case PaxExtraServiceStatus.Option: {
            statusDate = formatDate(pes.optionExpiryDate!);
            break;
          }
          case PaxExtraServiceStatus.Confirmed: {
            statusDate = formatDate(pes.confirmedDate!);
            break;
          }
        }
        return (
          passenger && (
            <TableRow key={`${extraService.extraServiceId}${pes.paxId}`}>
              <TableCell>{passenger.bookingReference}</TableCell>
              <TableCell>{brandLabels[sharingGroup.brand]}</TableCell>
              <TableCell>
                {createFullName(
                  passenger.firstName,
                  passenger.middleName,
                  passenger.surname
                )}
              </TableCell>
              <TableCell>
                {pes.status && paxExtraServiceStatusLabels[pes.status]}
              </TableCell>
              <TableCell>{statusDate}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          )
        );
      })}
    </React.Fragment>
  );
};

export default withRouter(withStyles(styles)(PaxExtraServicesDetails));
