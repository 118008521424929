import React, { ReactNode } from 'react';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core';

export interface IProps {
  title: ReactNode;
  onOK: () => void;
  onCancel: () => void;
  okButtonText?: ReactNode;
  cancelButtonText?: ReactNode;
}

const ConfirmationDialog: React.FC<IProps> = ({
  title,
  onOK,
  onCancel,
  okButtonText = 'OK',
  cancelButtonText = 'Cancel',
  children,
}) => {
  return (
    <React.Fragment>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
      <DialogActions>
        <Button onClick={onOK} color="primary">
          {okButtonText}
        </Button>
        <Button autoFocus onClick={onCancel} color="primary">
          {cancelButtonText}
        </Button>
      </DialogActions>
    </React.Fragment>
  );
};

export default ConfirmationDialog;
