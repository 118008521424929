import { createStyles } from '@material-ui/core/styles';

export default createStyles({
  inputField: {
    width: '200px',
  },
  inputFieldNotes: {
    width: '200px',
  },
  paxDetailsContainer: {
    padding: '15px 0px 15px 25px',
  },
  paxDetailsContainerCancelled: {
    borderColor: 'red',
  },
  paxOpsCostFieldsGroupText: {
    marginTop: '15px',
    marginBottom: '15px',
  },
  netPriceOverrideButton: {
    margin: '20px 15px 5px 5px',
  },
  netPriceOverrideButtonGrid: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});
