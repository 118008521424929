import { createStyles } from '@material-ui/core/styles';

export default createStyles({
  extraServiceSelect: {
    minWidth: 150,
  },
  extraServiceDate: {
    width: '200px',
    marginTop: '0px',
  },
  extraServicesDateCell: {
    width: '248px',
  },
  table: {
    paddingBlock: '8px',
    width: '738px',
  },
  tableRow: {
    height: '95px',
  },
});
