export default interface ApiResponse {
  body: any;
  errors: string[];
  hasErrors: boolean;
}

export interface PaginatedApiResponse extends ApiResponse {
  pageNumber: number;
  pageSize: number;
  totalRecords: number;
}

const isPaginatedApiResponse = (
  apiResponse?: Partial<PaginatedApiResponse>
): apiResponse is PaginatedApiResponse =>
  apiResponse?.pageSize !== undefined &&
  apiResponse?.totalRecords !== undefined;

export { isPaginatedApiResponse };
