import React from 'react';
import { Typography } from '@material-ui/core';

export default function AccessDenied() {
  return (
    <React.Fragment>
      <Typography component="h2" variant="h5" gutterBottom>
        Access Denied!
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        You don't have access to the requested page.
      </Typography>
      <img
        src="https://contestchest.com/public/images/Locked_Treasure_Chest.png"
        alt="Treasure"
      />
    </React.Fragment>
  );
}
