import React, { ReactNode } from 'react';
import { Typography } from '@material-ui/core';

interface IProps {
  text: ReactNode | ReactNode[];
}

const PageDescription: React.FC<IProps> = ({ text }) => {
  const content = Array.isArray(text) ? text : [text];

  return (
    <React.Fragment>
      {content.map(
        (line, index) =>
          line && (
            <Typography variant="subtitle2" gutterBottom key={index}>
              {line}
            </Typography>
          )
      )}
    </React.Fragment>
  );
};

export default PageDescription;
