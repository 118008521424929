import React from 'react';
import { Box } from '@material-ui/core';

interface IProps {
  value: number;
  index: number;
  justHide?: boolean;
}

const TabPanel: React.FC<IProps> = ({
  children,
  value,
  index,
  justHide,
  ...other
}) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {(justHide || value === index) && <Box p={3}>{children}</Box>}
    </div>
  );
};

export default TabPanel;
