import React, { ReactNode } from 'react';
import { Field } from 'react-final-form';
import { TextField, makeStyles } from '@material-ui/core';
import styles from './FormSelectAutoCompleteField.styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TooltipIcon from '../components/TooltipIcon';
import { FieldValidator } from 'final-form';
import { isPopulated } from './validators';

type SelectAutoCompleteOption = {
  value: string | number | null;
  label: string;
  view?: ReactNode;
};

export interface IProps {
  name: string;
  label: string;
  options: Array<SelectAutoCompleteOption>;
  className?: string;
  required?: boolean;
  disabled?: boolean;
  defaultValue?: string | number | null;
  toolTip?: ReactNode;
  initialValue?: string | number | null;
}

const nullOption: SelectAutoCompleteOption = {
  value: null,
  label: '',
};

const useStyles = makeStyles(styles);

const FormSelectAutoCompleteField: React.FC<IProps> = ({
  name,
  label,
  options,
  required = false,
  disabled = false,
  defaultValue,
  className,
  toolTip,
  initialValue,
}) => {
  const classes = useStyles();

  const validate: FieldValidator<string | number | null> = (value) =>
    required ? isPopulated(value) : undefined;

  return (
    <TooltipIcon title={toolTip}>
      <Field
        name={name}
        initialValue={initialValue}
        defaultValue={defaultValue}
        validate={validate}
      >
        {({ input, meta }) => {
          const hasError = meta.touched && meta.error !== undefined;
          return (
            <Autocomplete<SelectAutoCompleteOption>
              id={name}
              options={options}
              value={options.find((o) => o.value === input.value) ?? nullOption}
              autoHighlight
              autoComplete
              onChange={(
                _event: React.ChangeEvent<{}>,
                value: SelectAutoCompleteOption | null
              ) => input.onChange(value?.value ?? null)}
              getOptionLabel={(option) => option.label}
              disabled={disabled}
              renderOption={(option) => option.view ?? option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={label}
                  required={required}
                  disabled={disabled}
                  className={`${classes.select} ${className}`}
                  error={hasError}
                  margin="normal"
                  helperText={
                    hasError && <React.Fragment>{meta.error}</React.Fragment>
                  }
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password', // disable autocomplete and autofill
                  }}
                />
              )}
            />
          );
        }}
      </Field>
    </TooltipIcon>
  );
};

export default FormSelectAutoCompleteField;
