import React from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Link,
  Button,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import { HashLink } from 'react-router-hash-link';
import getAvailabilityText from '../../common/helpers/getAvailabilityText';
import ICabinCategory from '../../common/types/ICabinCategory';
import ISharingGroup from '../../common/types/ISharingGroup';
import IAllotment from '../../common/types/IAllotment';
import IPax from '../../common/types/IPax';
import PaxStatus from '../../pax/enums/paxStatus';
import { Link as RouterLink } from 'react-router-dom';
import styles from './AllotmentAvailabilitySummary.styles';
import Routes from '../../common/constants/routes';

function getPaxCountByStatus(paxList: IPax[], paxStatus: PaxStatus) {
  const paxListByStatus = paxList.filter((x) => x.status === paxStatus);
  return paxListByStatus.length;
}

interface IProps extends WithStyles<typeof styles> {
  allotment: IAllotment;
  cabinCategories: ICabinCategory[];
  sharingGroups: ISharingGroup[];
  renderHashLinks?: boolean;
}

const AllotmentAvailabilitySummary: React.FC<IProps> = ({
  allotment,
  cabinCategories,
  sharingGroups,
  renderHashLinks = true,
  classes,
}) => {
  return (
    <Table
      size="small"
      aria-label="Allotment availability summary"
      stickyHeader
    >
      <TableHead>
        <TableRow>
          <TableCell>Cabin Category</TableCell>
          <TableCell align="right">Available Berths</TableCell>
          <TableCell align="center"></TableCell>
          <TableCell align="right">On Option Passengers</TableCell>
          <TableCell align="right">Confirmed Passengers</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {cabinCategories.map((cc) => {
          const paxList = sharingGroups
            .filter((sg) => sg.cabinCategoryId === cc.id)
            .flatMap((sg) => sg.pax);

          const allocation = allotment.allocations.find(
            (a) => a.cabinCategoryId === cc.id
          );

          return (
            allocation && (
              <React.Fragment key={'allocation_' + cc.id}>
                <TableRow key={'cabinCategoryId_' + cc.id}>
                  <TableCell>
                    <div data-attr={`cabinCategory_${cc.id}.name`}>
                      {renderHashLinks ? (
                        <Link
                          component={HashLink}
                          to={`#cabinCategoryId_${cc.id}`}
                          smooth
                        >
                          {cc.name}
                        </Link>
                      ) : (
                        cc.name
                      )}
                    </div>
                  </TableCell>
                  <TableCell align="right">
                    <div data-attr={`cabinCategory_${cc.id}.available`}>
                      {getAvailabilityText(allocation.availability)}
                    </div>
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      variant="contained"
                      color="primary"
                      component={RouterLink}
                      to={Routes.PaxInsert.getLocation(
                        allotment.voyageId,
                        allotment.id,
                        cc.id
                      )}
                      className={classes.tableButton}
                      disabled={
                        allocation.availability.femaleOnlyBerths +
                          allocation.availability.maleOnlyBerths +
                          allocation.availability.unallocatedBerths ===
                        0
                      }
                    >
                      Book
                    </Button>
                  </TableCell>
                  <TableCell align="right">
                    <div data-attr={`cabinCategory_${cc.id}.onOption`}>
                      {getPaxCountByStatus(paxList, PaxStatus.Option)}
                    </div>
                  </TableCell>
                  <TableCell align="right">
                    <div data-attr={`cabinCategory_${cc.id}.confirmed`}>
                      {getPaxCountByStatus(paxList, PaxStatus.Confirmed)}
                    </div>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            )
          );
        })}
      </TableBody>
    </Table>
  );
};
export default withStyles(styles)(AllotmentAvailabilitySummary);
