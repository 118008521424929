import React, { createContext, useState, useContext } from 'react';
import {
  Snackbar,
  Slide,
  makeStyles,
  SnackbarCloseReason,
} from '@material-ui/core';
import Alert, { Color } from '@material-ui/lab/Alert';
import styles from './Snackbar.styles';

export const SnackbarContext = createContext<ISnackbarContext>({
  showMessage: (_message: string) => {},
});

export interface ISnackbarContext {
  showMessage: (message: string, opts?: ISnackbarOpts) => void;
}

export interface ISnackbarOpts {
  autoHideDuration?: number;
  severity?: Color;
  elevation?: number;
  variant?: 'standard' | 'filled' | 'outlined';
  disableTimeout?: boolean;
  disableClickaway?: boolean;
}

const defaultSnackbarOpts: ISnackbarOpts = {
  elevation: 6,
  variant: 'filled',
  severity: 'success',
  autoHideDuration: 2000,
};
export interface IWithSnackbar {
  snackbar: ISnackbarContext;
}
const useStyles = makeStyles(styles);

export function withSnackbar<P extends IWithSnackbar>(
  Component: React.ComponentType<P>
) {
  return function WrappedComponent(
    props: Pick<P, Exclude<keyof P, keyof IWithSnackbar>>
  ) {
    return (
      <SnackbarContext.Consumer>
        {(snackbarContext) => (
          <Component {...(props as P)} snackbar={snackbarContext} />
        )}
      </SnackbarContext.Consumer>
    );
  };
}

export const useSnackbar = () => useContext(SnackbarContext);

export const SnackbarProvider: React.FC = ({ children }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string | undefined>();
  const [snackbarOpts, setSnackbarOpts] = useState<ISnackbarOpts>(
    defaultSnackbarOpts
  );

  const classes = useStyles();

  const onClose = (
    _event: React.SyntheticEvent<any, Event>,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === 'clickaway' && snackbarOpts.disableClickaway) return;
    if (reason === 'timeout' && snackbarOpts.disableTimeout) return;
    setOpen(false);
  };

  const onExited = () => {
    setMessage(undefined);
    setSnackbarOpts(defaultSnackbarOpts);
    setOpen(false);
  };

  const showMessage = (message: string, opts?: ISnackbarOpts) => {
    setOpen(true);
    setMessage(message);
    setSnackbarOpts({
      ...defaultSnackbarOpts,
      ...opts,
    });
  };

  return (
    <SnackbarContext.Provider value={{ showMessage }}>
      {children}
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={snackbarOpts.autoHideDuration}
        onClose={onClose}
        TransitionProps={{ onExited }}
        TransitionComponent={Slide}
        className={classes.snackbar}
        key={message ? 'snackbarOpen' : 'snackbarClosed'}
      >
        <Alert
          elevation={snackbarOpts.elevation}
          variant={snackbarOpts.variant}
          severity={snackbarOpts.severity}
          onClose={onClose}
        >
          {message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};
