import React from 'react';
import { Router } from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import RouteSwitch from './RouteSwitch';
import history from './History';
import './App.css';
import NavigationBar from '../common/components/NavigationBar';
import UserContextProvider from '../common/contexts/UserContext';
import { SnackbarProvider } from '../common/contexts/SnackbarContext';
import { create } from 'jss';
import { StylesProvider, jssPreset } from '@material-ui/core/styles';
import extend from 'jss-plugin-extend';
import { ApiProvider } from '../common/contexts/ApiContext';
import { ModalProvider } from '../common/contexts/ModalContext';

const jss = create({
  plugins: [extend(), ...jssPreset().plugins],
});

const App: React.FC = () => {
  return (
    <StylesProvider jss={jss}>
      <UserContextProvider>
        <Router history={history}>
          <ModalProvider>
            <SnackbarProvider>
              <ApiProvider>
                <header>
                  <NavigationBar />
                </header>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <div className="App">
                    <RouteSwitch />
                  </div>
                </MuiPickersUtilsProvider>
              </ApiProvider>
            </SnackbarProvider>
          </ModalProvider>
        </Router>
      </UserContextProvider>
    </StylesProvider>
  );
};

export default App;
