import * as React from 'react';
import Select from '@material-ui/core/Select';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import styles from './CustomSelectField.styles';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Pluralize from 'pluralize';

interface IProps extends WithStyles<typeof styles> {
  value: any[];
  label: string;
  options: Array<{ value: any; label: string }>;
  multiSelect?: boolean;
  aggregateMultiSelectionsOnDisplay?: boolean;
  onChange?: (event: React.ChangeEvent<any> | any) => void;
}

class CustomSelectField extends React.Component<IProps> {
  public render() {
    const {
      value,
      classes,
      label,
      options,
      onChange,
      multiSelect = true,
      aggregateMultiSelectionsOnDisplay = true,
    } = this.props;

    const renderValue = (selected: any) => {
      const selectedItems = options.filter(
        (o) => selected.indexOf(o.value) > -1
      );

      if (selectedItems.length === 0) {
        return '';
      }

      if (aggregateMultiSelectionsOnDisplay) {
        return selectedItems.length > 1
          ? `${selectedItems.length} ${Pluralize(
              this.props.label,
              selectedItems.length
            )} selected`
          : selectedItems[0].label;
      }

      return selectedItems.map((s) => s.label).join(', ');
    };

    return (
      <FormControl>
        <InputLabel>{label}</InputLabel>
        <br />
        <Select
          multiple={multiSelect}
          value={value}
          renderValue={renderValue}
          className={classes.select}
          MenuProps={{
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
          }}
          onChange={onChange}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {multiSelect && (
                <Checkbox
                  checked={value.indexOf(option.value) > -1}
                  color="primary"
                />
              )}
              <ListItemText primary={option.label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
}

export default withStyles(styles)(CustomSelectField);
