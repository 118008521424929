import React from 'react';
import { Form, FormSpy, Field, FormRenderProps } from 'react-final-form';
import { withStyles, WithStyles, Grid } from '@material-ui/core';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';
import styles from './PaxExtraServicesForm.styles';
import IPaxExtraServicesForm from '../types/IPaxExtraServicesForm';
import IPax from '../../common/types/IPax';
import { withRouter, RouteComponentProps } from 'react-router';
import PaxExtraServices from './PaxExtraServices';
import IExtraService from '../../common/types/IExtraService';
import ISharingGroup from '../../common/types/ISharingGroup';
import Routes from '../../common/constants/routes';
import FormAlerts from '../../common/forms/FormAlerts';
import { isTakingCapacity } from '../enums/paxStatus';
import { AnyObject, FormState } from 'final-form';
import FormButtons from '../../common/forms/FormButtons';
import { IWithModal, withModal } from '../../common/contexts/ModalContext';
import PaxExtraServiceStatus from '../enums/paxExtraServiceStatus';
import { flatten, values } from 'lodash';
import PaxExtraServiceConfirmationDialogContent from './PaxExtraServiceConfirmationDialogContent';

interface IProps {
  extraServices?: IExtraService[];
  sharingGroup?: ISharingGroup;
  onSubmit: (paxExtraServices: IPaxExtraServicesForm) => void;
  initialValues?: IPaxExtraServicesForm;
  onChange: (state: FormState<AnyObject>) => void;
}

interface IState {
  dirtyForm: boolean;
}

type Props = IProps &
  RouteComponentProps &
  WithStyles<typeof styles> &
  IWithModal;

class PaxExtraServicesForm extends React.Component<Props, IState> {
  readonly state: IState = {
    dirtyForm: false,
  };
  private showSubmitError(
    submitFailed: boolean,
    submitError: boolean,
    dirtySinceLastSubmit: boolean
  ) {
    // show error when a submit failed, there is an error to show
    // and the form is unchanged since the failed submit
    return submitFailed && submitError && !dirtySinceLastSubmit;
  }

  private cancelForm = () => {
    if (window.confirm('Are you sure you want to cancel?')) {
      this.props.history.push(Routes.CheckAvailability.route);
    }
  };

  private handleFormChange = (state: FormState<IPaxExtraServicesForm>) => {
    this.setState({ dirtyForm: state.dirty && !state.submitting });
    return this.props.onChange(state);
  };

  private getPaxExtraServicesThatChangedToStatus = (
    formValues: IPaxExtraServicesForm,
    status: PaxExtraServiceStatus
  ) => {
    const { initialValues } = this.props;

    // List of extra services that needs to be displayed on Confirmation
    const getInitialValuesExtraService = (
      paxId: number,
      extraServiceId: number
    ) => {
      return flatten(values(initialValues?.data)).find(
        (paxExtraServiceInitialValue) =>
          paxExtraServiceInitialValue.paxId === paxId &&
          paxExtraServiceInitialValue.extraServiceId === extraServiceId
      );
    };

    const flatPaxExtraServices = flatten(values(formValues.data)).filter(
      (pes) => pes.status === status
    );

    const newPaxExtraServices = flatPaxExtraServices.filter(
      (pes) =>
        getInitialValuesExtraService(pes.paxId, pes.extraServiceId) ===
        undefined
    );

    const changedPaxExtraServices = flatPaxExtraServices.filter(
      (pes) =>
        getInitialValuesExtraService(pes.paxId, pes.extraServiceId)?.status !==
        status
    );

    return [...newPaxExtraServices, ...changedPaxExtraServices];
  };

  private handleOnSubmit = ({
    form,
    values,
  }: FormRenderProps<IPaxExtraServicesForm>) => {
    const { extraServices, sharingGroup } = this.props;

    const paxWithPaxExtraServicesConfirmedOrCancelled = this.getPaxExtraServicesThatChangedToStatus(
      values,
      PaxExtraServiceStatus.Confirmed
    ).concat(
      this.getPaxExtraServicesThatChangedToStatus(
        values,
        PaxExtraServiceStatus.Cancelled
      )
    );

    if (
      paxWithPaxExtraServicesConfirmedOrCancelled.length === 0 ||
      !form.getState().valid
    ) {
      form.submit();
      return;
    }

    this.props.modal.show({
      title: 'Submit',
      onOK: form.submit,
      content: (
        <PaxExtraServiceConfirmationDialogContent
          pax={sharingGroup?.pax ?? []}
          extraServices={extraServices ?? []}
          updatedExtraServices={paxWithPaxExtraServicesConfirmedOrCancelled}
        />
      ),
    });
  };

  render() {
    const {
      classes,
      extraServices,
      sharingGroup,
      onSubmit,
      initialValues,
    } = this.props;

    return (
      <Form
        onSubmit={onSubmit}
        mutators={{
          ...arrayMutators,
        }}
        initialValues={initialValues}
        render={(renderProps) => {
          const {
            submitting,
            pristine,
            form,
            submitFailed,
            submitError,
            dirtySinceLastSubmit,
          } = renderProps;

          return (
            <form>
              <FormSpy
                subscription={{ dirty: true, submitting: true, values: true }}
                onChange={this.handleFormChange}
              />

              {sharingGroup?.pax &&
                sharingGroup?.pax.map((pax: IPax) => {
                  const paxFormClasses = classNames({
                    [classes.paxDetailsContainer]: true,
                    [classes.paxDetailsContainerNotTakingCapacity]: !isTakingCapacity(
                      pax.status
                    ),
                  });
                  const paxExtraServicesClasses = classNames({
                    [classes.paxExtraServiceHidden]: pax.id === undefined,
                  });

                  return (
                    <Grid container direction="column" spacing={1} key={pax.id}>
                      {extraServices && (
                        <Grid
                          item
                          className={classes.paxExtraServicesContainer}
                        >
                          <div className={paxExtraServicesClasses}>
                            <PaxExtraServices
                              extraServices={extraServices}
                              paxFormClasses={paxFormClasses}
                              pax={pax}
                            />
                          </div>
                        </Grid>
                      )}
                    </Grid>
                  );
                })}

              <div>
                {this.showSubmitError(
                  submitFailed,
                  submitError,
                  dirtySinceLastSubmit
                ) && <FormAlerts alerts={submitError} alertType="error" />}
              </div>

              <Field name={`meta`}>
                {({ meta }) => {
                  return (
                    <FormButtons
                      submitButtonType="button"
                      submit={{
                        disabled: pristine || submitting,
                        onClick: () => {
                          this.handleOnSubmit(renderProps);
                        },
                      }}
                      reset={{
                        disabled: submitting,
                        onClick: () => {
                          if (
                            !this.state.dirtyForm ||
                            window.confirm(
                              'You have unsaved changes, are you sure you want to reset the form?'
                            )
                          )
                            form.reset();
                        },
                      }}
                      cancel={{
                        disabled: submitting,
                        onClick: this.cancelForm,
                      }}
                    />
                  );
                }}
              </Field>
            </form>
          );
        }}
      />
    );
  }
}

export default withRouter(withModal(withStyles(styles)(PaxExtraServicesForm)));
