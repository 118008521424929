import React, { createContext, ReactNode, useState, useContext } from 'react';
import ConfirmationDialog, {
  IProps as ConfirmationDialogProps,
} from '../components/ConfirmationDialog';
import { Dialog } from '@material-ui/core';

// Modal Context
export interface IModalContext {
  show: (
    props: {
      title: ReactNode;
      content: ReactNode;
    } & Partial<ConfirmationDialogProps>
  ) => void;
  hide: () => void;
  open: boolean;
}

export const ModalContext = createContext<IModalContext>({
  show: () => {},
  hide: () => {},
  open: false,
});

export const ModalProvider: React.FC = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [confirmationDialogProps, setConfirmationDialogProps] = useState<
    {
      title: ReactNode;
      content: ReactNode;
    } & Partial<ConfirmationDialogProps>
  >();

  const hide = () => {
    setOpen(false);
  };

  const show = (
    props: {
      title: ReactNode;
      content: ReactNode;
    } & Partial<ConfirmationDialogProps>
  ) => {
    setConfirmationDialogProps(props);
    setOpen(true);
  };

  const onOK = () => {
    confirmationDialogProps?.onOK && confirmationDialogProps.onOK();
    hide();
  };
  const onCancel = () => {
    confirmationDialogProps?.onCancel && confirmationDialogProps.onCancel();
    hide();
  };

  return (
    <ModalContext.Provider value={{ hide, show, open }}>
      {children}
      <Dialog disableBackdropClick disableEscapeKeyDown open={open}>
        {confirmationDialogProps && (
          <ConfirmationDialog
            {...confirmationDialogProps}
            onOK={onOK}
            onCancel={onCancel}
          >
            {confirmationDialogProps?.content}
          </ConfirmationDialog>
        )}
      </Dialog>
    </ModalContext.Provider>
  );
};

// Hook and Component Wrapped
export const useModal = () => useContext(ModalContext);

export interface IWithModal {
  modal: IModalContext;
}

export function withModal<P extends IWithModal>(
  Component: React.ComponentType<P>
) {
  return function WrappedComponent(
    props: Pick<P, Exclude<keyof P, keyof IWithModal>>
  ) {
    return (
      <ModalContext.Consumer>
        {(modalContext) => <Component {...(props as P)} modal={modalContext} />}
      </ModalContext.Consumer>
    );
  };
}
