import React from 'react';
import { WithStyles, withStyles } from '@material-ui/core';
import styles from './Warning.styles';

interface IProps extends WithStyles<typeof styles> {
  children: React.ReactNode;
}

const Warning: React.FC<IProps> = (props: IProps) => {
  const { classes, children } = props;

  return <span className={classes.warning}>{children}</span>;
};

export default withStyles(styles)(Warning);
