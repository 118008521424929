import * as React from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from '@material-ui/core';
import IAllotment from '../../common/types/IAllotment';
import styles from './AllotmentDetails.styles';
import ICabinCategory from '../../common/types/ICabinCategory';
import { makeStyles } from '@material-ui/styles';

interface IProps {
  allotment: IAllotment;
  cabinCategories?: ICabinCategory[];
}

const useStyles = makeStyles(styles);

const AllotmentDetails: React.FC<IProps> = ({ allotment, cabinCategories }) => {
  const classes = useStyles();

  return (
    <div className={classes.allotmentContent}>
      <div className={classes.allotmentNote}>
        <pre>
          <Typography variant="caption">{allotment.notes}</Typography>
        </pre>
      </div>
      <Table size="small" aria-label="Allotment Details Table">
        <TableHead>
          <TableRow className={classes.allotmentAllocationsTableHeader}>
            <TableCell>Cabin Category</TableCell>
            <TableCell>Allotted Cabins</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {allotment.allocations.map((allocation) => {
            const key = allotment.id + '_' + allocation.cabinCategoryId;
            return (
              <TableRow key={key}>
                <TableCell>
                  <div data-attr={`${key}.cabinCategory`}>
                    {
                      cabinCategories?.find(
                        (x) => x.id === allocation.cabinCategoryId
                      )?.name
                    }
                  </div>
                </TableCell>
                <TableCell>
                  <div data-attr={`${key}.cabinCount`}>
                    {allocation.cabinCount}
                  </div>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};

export default AllotmentDetails;
