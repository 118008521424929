import env from '../../core/env';

export interface IAuthConfig {
  audience: string;
  domain: string;
  client_id: string;
  redirect_uri: string;
}

const authConfig: IAuthConfig = {
  audience: `${env.REACT_APP_AUTH0_AUDIENCE}`,
  domain: `${env.REACT_APP_AUTH0_DOMAIN}`,
  client_id: `${env.REACT_APP_AUTH0_CLIENT_ID}`,
  redirect_uri: `${env.REACT_APP_WEB_BASE_URL}`,
};

export default authConfig;
