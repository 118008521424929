import env from '../../../core/env';

/**
 * Detects if value isn't a date, OR has NaN as the time aka 'Invalid Date'
 * Null and undefined values are allowed
 * @param value
 */
export default function isValidDatePickerFormat(value: Date | null) {
  if (value && (!(value instanceof Date) || isNaN(value.getTime()))) {
    return `Valid format is ${env.REACT_APP_DATE_FORMAT}`;
  }

  return undefined;
}
