import React from 'react';
import {
  Typography,
  makeStyles,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';

import IPax from '../../common/types/IPax';
import IPaxExtraService from '../../common/types/IPaxExtraService';
import IExtraService from '../../common/types/IExtraService';
import { groupBy } from 'lodash';
import createFullName from '../../common/helpers/createFullName';
import PaxExtraServiceStatus, {
  paxExtraServiceStatusLabels,
} from '../enums/paxExtraServiceStatus';
import styles from './PaxExtraServiceConfirmationDialogContent.styles';
import { Alert } from '@material-ui/lab';
import pluralize from 'pluralize';

const useStyles = makeStyles(styles);

interface IProps {
  updatedExtraServices: IPaxExtraService[];
  extraServices: IExtraService[];
  pax: IPax[];
}

const UpdatedPax: React.FC<{
  pax: IPax;
  extraServices: IExtraService[];
  updatedExtraServices: IPaxExtraService[];
}> = ({ pax, extraServices, updatedExtraServices }) => {
  const classes = useStyles();

  const extraServiceName = ({ extraServiceId }: { extraServiceId: number }) =>
    extraServices.find((es) => es.extraServiceId === extraServiceId)?.name ??
    '';

  return (
    <ListItem>
      <ListItemText>
        {createFullName(pax.firstName, pax.middleName, pax.surname)}
        <ul className={classes.confirmationDialogPesList}>
          {updatedExtraServices
            .sort((a, b) =>
              extraServiceName(a).localeCompare(extraServiceName(b), 'en')
            )
            .map((pes, index) => (
              <li key={index}>
                {extraServiceName(pes)} -{' '}
                {paxExtraServiceStatusLabels[pes.status!]}
              </li>
            ))}
        </ul>
      </ListItemText>
    </ListItem>
  );
};

const PaxExtraServiceConfirmationDialogContent: React.FC<IProps> = ({
  updatedExtraServices,
  extraServices,
  pax,
}) => {
  const groupedByPax = groupBy(updatedExtraServices, (pes) => pes.paxId);

  const confirmedPes = updatedExtraServices.filter(
    (pes) => pes.status === PaxExtraServiceStatus.Confirmed
  );
  return (
    <React.Fragment>
      {confirmedPes.length > 0 && (
        <React.Fragment>
          <Alert severity="warning" variant="outlined">
            {`By confirming the below extra ${pluralize(
              'service',
              confirmedPes.length
            )} you are locking in the details provided and will need to contact the OE Team to make changes after confirmation. Please ensure all information is correct.`}
          </Alert>
          <br />
        </React.Fragment>
      )}
      <Typography variant="body2">
        Are you sure you want to make the following changes?
      </Typography>
      <List dense component="div">
        {Object.keys(groupedByPax).map((paxId) => (
          <UpdatedPax
            key={paxId}
            extraServices={extraServices}
            pax={pax.find((p) => p.id === +paxId)!}
            updatedExtraServices={groupedByPax[paxId]}
          />
        ))}
      </List>
    </React.Fragment>
  );
};

export default PaxExtraServiceConfirmationDialogContent;
