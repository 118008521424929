import React from 'react';

const createFullName = (
  first: string | null | undefined,
  middle: string | null | undefined,
  last: string | null | undefined
) => {
  if (!first && !last) {
    return <em>Name to be confirmed</em>;
  }

  return [first, middle, last].filter((n) => !!n).join(' ');
};
export default createFullName;
