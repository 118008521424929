import React, { ReactNode, ReactText, useMemo } from 'react';
import { Field, AnyObject, useField } from 'react-final-form';
import { TextField } from '@material-ui/core';
import { get } from 'lodash';
import {
  isPopulated,
  isBelowMaxLength,
  isAboveMin,
  isBelowMax,
} from './validators';
import NumberFormat from 'react-number-format';
import TooltipIcon from '../components/TooltipIcon';

interface IRequiredWhen {
  FieldName: string;
  FieldValue: unknown;
}
interface IProps {
  name: string;
  label: string;
  required?: boolean;
  requiredWhen?: IRequiredWhen;
  maxLength?: number;
  multiline?: boolean;
  rowsMax?: number;
  disabled?: boolean;
  min?: number;
  max?: number;
  currencySymbol?: string;
  initialValue?: ReactText | null;
  className?: string;
  type?: 'text' | 'currency';
  toolTip?: ReactNode;
}

const FormTextField: React.FC<IProps> = ({
  name,
  label,
  required = false,
  maxLength,
  multiline = false,
  rowsMax = 1,
  disabled = false,
  className,
  type = 'text',
  min,
  max,
  currencySymbol = '$',
  initialValue,
  toolTip,
  requiredWhen,
}) => {
  const {
    input: { value: requiredWhenVal },
  } = useField(requiredWhen?.FieldName ?? name);

  const calculatedRequired = useMemo(
    () =>
      required ||
      (requiredWhen?.FieldName !== undefined
        ? requiredWhenVal === requiredWhen.FieldValue
        : false),
    [required, requiredWhen, requiredWhenVal]
  );

  return (
    <TooltipIcon title={toolTip}>
      <Field<ReactText | null>
        name={name}
        validate={(value, allValues) =>
          validate(value, allValues, {
            required,
            requiredWhen,
            maxLength,
            min,
            max,
          })
        }
        initialValue={initialValue}
        defaultValue={null}
        format={(value) => {
          if (value && type === 'currency') return +value;
          else if (typeof value === 'string') return value.trim();
          else return value ?? null;
        }}
        formatOnBlur
      >
        {({ input, meta }) => {
          return (
            <TextField
              {...input}
              value={input.value ?? null} // fixes uncontrolled input warning
              id={name}
              label={label}
              className={className}
              margin="normal"
              required={calculatedRequired}
              rowsMax={rowsMax}
              multiline={multiline}
              disabled={disabled}
              inputProps={
                type === 'currency'
                  ? { min, max, currencySymbol }
                  : { maxLength }
              }
              InputProps={
                type === 'currency'
                  ? { inputComponent: CurrencyFormatter as any }
                  : undefined
              }
              error={meta.touched && meta.error !== undefined}
              helperText={
                meta.touched &&
                meta.error && <React.Fragment>{meta.error}</React.Fragment>
              }
            />
          );
        }}
      </Field>
    </TooltipIcon>
  );
};

const validate = (
  value: ReactText | null,
  allValues: AnyObject,
  {
    required,
    requiredWhen,
    maxLength,
    min,
    max,
  }: Pick<IProps, 'required' | 'requiredWhen' | 'maxLength' | 'min' | 'max'>
) => {
  const isRequired = requiredWhen
    ? isRequiredWhen(allValues, requiredWhen)
    : required;

  let error;
  if (!error && isRequired) {
    error = isPopulated(value);
  }

  if (typeof value === 'string') {
    if (!error && maxLength) error = isBelowMaxLength(value, maxLength);
  } else if (typeof value === 'number') {
    if (!error && min !== undefined) error = isAboveMin(value, min);

    if (!error && max !== undefined) error = isBelowMax(value, max);
  }

  return error;
};

const isRequiredWhen = (allValues: AnyObject, requiredWhen: IRequiredWhen) =>
  get(allValues, requiredWhen.FieldName) === requiredWhen.FieldValue;

interface CurrencyFormatterProps {
  currencySymbol: string;
  min?: number;
  max?: number;
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { value: number | null } }) => void;
}

const CurrencyFormatter = ({
  inputRef,
  onChange,
  currencySymbol,
  min,
  max,
  ...other
}: CurrencyFormatterProps) => {
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={({ value }) =>
        onChange({ target: { value: value ? +value : null } })
      }
      thousandSeparator
      isNumericString
      decimalScale={2}
      allowNegative={false}
      prefix={currencySymbol}
      isAllowed={({ formattedValue, floatValue }) =>
        formattedValue === '' ||
        ((min === undefined || floatValue >= min) &&
          (max === undefined || floatValue <= max))
      }
    />
  );
};

export default FormTextField;
