import React from 'react';
import {
  Card,
  CardContent,
  Divider,
  List,
  ListItemText,
  Typography,
} from '@material-ui/core';
import styles from './CabinAssignmentPaxDetails.styles';
import IAllotment from '../../common/types/IAllotment';
import ICabin from '../../common/types/ICabin';
import { isTakingCapacity, paxStatusLabels } from '../../pax/enums/paxStatus';
import createFullName from '../../common/helpers/createFullName';
import { makeStyles } from '@material-ui/styles';
import ISharingGroupCombined from '../../common/types/ISharingGroupCombined';

const useStyles = makeStyles(styles);

interface IProps {
  allotment: IAllotment;
  cabins: ICabin[];
  className: string;
  sharingGroup: ISharingGroupCombined;
}

const CabinAssignmentPaxDetails: React.FC<IProps> = ({
  sharingGroup,
  allotment,
  cabins,
  className,
}) => {
  const classes = useStyles();

  const pax = sharingGroup.sharingGroup.pax.filter((p) =>
    isTakingCapacity(p.status)
  );

  const requestedCabinNumber = cabins.find(
    (c) => c.id === sharingGroup.operationalInfo.requestedCabinId
  )?.cabinNumber;

  const bull = <span className={classes.bullet}>•</span>;

  return (
    <Card className={className}>
      <CardContent>
        <Typography
          align="left"
          variant="h6"
          classes={{ h6: classes.paxCount }}
        >
          {pax.length}
        </Typography>
        {pax.map((pax) => {
          return (
            <List key={pax.id} dense disablePadding>
              <ListItemText
                primary={createFullName(
                  pax.firstName,
                  pax.middleName,
                  pax.surname
                )}
                secondary={
                  <React.Fragment>
                    {pax.gender}
                    {pax.gender && pax.status && bull}
                    {paxStatusLabels[pax.status]}
                  </React.Fragment>
                }
                classes={{ secondary: classes.paxListItem }}
              />
            </List>
          );
        })}
      </CardContent>
      {(requestedCabinNumber ||
        sharingGroup.sharingGroup.singleSupplement ||
        !allotment.defaultAllotment) && (
        <React.Fragment>
          <Divider />
          <CardContent>
            {requestedCabinNumber && (
              <Typography variant="subtitle2" align="left">
                Cabin Request: {requestedCabinNumber}
              </Typography>
            )}
            {sharingGroup.sharingGroup.singleSupplement && (
              <Typography variant="subtitle2" align="left">
                Single Supplement
              </Typography>
            )}
            {!allotment.defaultAllotment && (
              <Typography variant="subtitle2" align="left">
                {allotment.name}
              </Typography>
            )}
          </CardContent>
        </React.Fragment>
      )}
    </Card>
  );
};

export default CabinAssignmentPaxDetails;
