import React from 'react';
import {
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  withStyles,
  WithStyles,
  Grid,
  Paper,
  Tabs,
  Tab,
  Container,
  Typography,
} from '@material-ui/core';
import styles from './CheckAvailability.styles';
import ICabinCategory from '../../common/types/ICabinCategory';
import IAllotment from '../../common/types/IAllotment';
import AllotmentStatus from '../enums/allotmentStatus';
import IVoyage from '../../common/types/IVoyage';
import ISharingGroup from '../../common/types/ISharingGroup';
import VoyageSelect from '../../common/components/VoyageSelect';
import IExtraService from '../../common/types/IExtraService';
import VoyageExtraServiceSummary from '../../voyage/components/VoyageExtraServiceSummary';
import AllotmentAvailabilitySummary from './AllotmentAvailabilitySummary';
import TabPanel from '../../common/components/TabPanel';
import PageHeader from '../../common/components/PageHeader';

interface IState {
  allotmentId?: number;
  tabSelected: AllotmentPageTab;
}

enum AllotmentPageTab {
  Availability,
  ExtraServices,
}

interface IProps extends WithStyles<typeof styles> {
  voyages: IVoyage[];
  sharingGroups: ISharingGroup[];
  selectedVoyageAllotments: IAllotment[];
  voyageId: number | undefined;
  selectedVoyageExtraServices: IExtraService[];
  getSharingGroupsForVoyage: (voyageId: number) => Promise<void>;
  onSelectVoyage: (voyageId: number) => Promise<void>;
}

class CheckAvailability extends React.Component<IProps, IState> {
  readonly state: IState = {
    allotmentId: this.setInitialAllotmentId(),
    tabSelected: AllotmentPageTab.Availability,
  };

  private setInitialAllotmentId() {
    const { selectedVoyageAllotments } = this.props;

    const allotments = selectedVoyageAllotments.filter(
      (a) => a.status === AllotmentStatus.Active && a.defaultAllotment === false
    );

    return allotments.length === 1 ? allotments[0].id : undefined;
  }

  private handleChangeVoyage = async (e: any) => {
    const voyageId = e.target.value;

    this.setState({ allotmentId: undefined });

    // update pax list
    await Promise.all([
      this.props.getSharingGroupsForVoyage(voyageId),
      this.props.onSelectVoyage(voyageId),
    ]);

    this.setState({ allotmentId: this.setInitialAllotmentId() });
  };

  private handleChangeAllotment = async (e: any) => {
    const allotmentId = e.target.value;
    this.setState({ allotmentId });
  };

  private getAllotmentSelectOptions(allotments: IAllotment[]) {
    return allotments.map((a) => ({
      value: a.id,
      label: a.name,
    }));
  }

  private handleTabChange = async (
    e: React.ChangeEvent<any>,
    selectedTab: AllotmentPageTab
  ) => {
    this.setState({ tabSelected: selectedTab });
  };

  private renderAvailability(
    selectedVoyageAllotments: IAllotment[],
    cabinCategories: ICabinCategory[],
    sharingGroups: ISharingGroup[],
    classes: any
  ) {
    const { allotmentId } = this.state;

    const generalAllotment = selectedVoyageAllotments.find(
      (x) => x.defaultAllotment
    );

    const selectedAllotment = selectedVoyageAllotments.find(
      (x) => x.id === allotmentId
    );

    const allotmentOptions = this.getAllotmentSelectOptions(
      selectedVoyageAllotments.filter(
        (a) =>
          a.status === AllotmentStatus.Active && a.defaultAllotment === false
      )
    );

    const voyageDefaultAllotment = selectedVoyageAllotments.find(
      (a) => a.defaultAllotment === true
    );

    const defaultAllotmentSharingGroups =
      voyageDefaultAllotment &&
      sharingGroups.filter(
        (sg) => sg.allotmentId === voyageDefaultAllotment.id
      );

    const selectedAllotmentSharingGroups = sharingGroups.filter(
      (sg) => sg.allotmentId === allotmentId
    );

    return (
      this.props.voyageId && (
        <Container maxWidth="md">
          <Paper square className={classes.tabPaper}>
            <Paper square>
              <Tabs
                value={this.state.tabSelected}
                onChange={this.handleTabChange}
                aria-label="Allotment Details"
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab label="General Availability" className={classes.tab} />
                <Tab label="Allotment Availability" className={classes.tab} />
                <Tab label="Extra Services" className={classes.tab} />
              </Tabs>
            </Paper>

            <TabPanel value={this.state.tabSelected} index={0}>
              {generalAllotment &&
                cabinCategories &&
                defaultAllotmentSharingGroups && (
                  <AllotmentAvailabilitySummary
                    allotment={generalAllotment}
                    cabinCategories={cabinCategories}
                    sharingGroups={defaultAllotmentSharingGroups}
                    renderHashLinks={false}
                  />
                )}
            </TabPanel>
            <TabPanel value={this.state.tabSelected} index={1}>
              <div className={classes.allotmentSelectContainer}>
                {allotmentOptions && allotmentOptions?.length !== 0 ? (
                  <FormControl>
                    <InputLabel>Allotment</InputLabel>
                    <Select
                      labelId="allotment-label"
                      data-attr="allotmentId"
                      value={allotmentId || ''}
                      className={classes.inputField}
                      onChange={this.handleChangeAllotment}
                      disabled={allotmentOptions.length <= 1}
                    >
                      {allotmentOptions
                        .sort((a, b) =>
                          a.label.localeCompare(b.label, 'en', {
                            numeric: true,
                          })
                        )
                        .map((option, index) => (
                          <MenuItem
                            key={option.label + index}
                            value={option.value}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                ) : (
                  <Typography>
                    No allotments available for this Voyage.
                  </Typography>
                )}
              </div>
              {allotmentId && selectedAllotment && (
                <div className={classes.allotmentAvailabilityContainer}>
                  <AllotmentAvailabilitySummary
                    allotment={selectedAllotment}
                    cabinCategories={cabinCategories}
                    sharingGroups={selectedAllotmentSharingGroups}
                    renderHashLinks={false}
                  />
                </div>
              )}
            </TabPanel>
            <TabPanel value={this.state.tabSelected} index={2}>
              <VoyageExtraServiceSummary
                extraServices={this.props.selectedVoyageExtraServices}
                renderHashLinks={false}
              />
            </TabPanel>
          </Paper>
        </Container>
      )
    );
  }

  render() {
    const {
      voyages,
      sharingGroups,
      selectedVoyageAllotments,
      voyageId,
      classes,
    } = this.props;

    const selectedVoyage = voyages.find((v) => v.id === voyageId);

    const cabinCategoriesSorted = selectedVoyage?.cabinCategories.sort((a, b) =>
      a.name.localeCompare(b.name, 'en', {
        numeric: true,
      })
    );

    return (
      <React.Fragment>
        <PageHeader
          title="Check Availability"
          description={[
            'Select Voyage to check the current availability and book a cabin category.',
            'F – female share available berths.',
            'M – male share available berths.',
          ]}
        />
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <VoyageSelect
              selectedVoyageId={voyageId}
              voyages={voyages}
              onChange={this.handleChangeVoyage}
            />
          </Grid>
          {selectedVoyage &&
            cabinCategoriesSorted &&
            sharingGroups &&
            selectedVoyageAllotments && (
              <Grid item>
                {this.renderAvailability(
                  selectedVoyageAllotments,
                  cabinCategoriesSorted,
                  sharingGroups,
                  classes
                )}
              </Grid>
            )}
        </Grid>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(CheckAvailability);
