import React, { useState, useEffect, useMemo } from 'react';
import { useApi } from '../../common/contexts/ApiContext';
import VoyagesService from '../../services/VoyagesService';
import PaxService from '../../services/PaxService';
import IVoyage from '../../common/types/IVoyage';
import { Filters, SortingRule } from 'react-table';
import PaxExtraServiceSearchResult from '../../common/types/PaxExtraServiceSearchResult';
import PaxExtraServiceStatus from '../../pax/enums/paxExtraServiceStatus';
import ExtraServiceOverview from './ExtraServiceOverview';
import Pagination from '../../common/types/Pagination';
import { convertForApi } from '../../common/types/SortOrder';
import Brand from '../../pax/enums/brand';
import { CircularProgress } from '@material-ui/core';

const ExtraServiceOverviewContainer: React.FC = () => {
  const [pes, setPes] = useState<PaxExtraServiceSearchResult[]>([]);
  const [pagination, setPagination] = useState<Pagination>({
    pageNumber: 0,
    pageSize: 25,
  });
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [voyages, setVoyages] = useState<IVoyage[]>([]);
  const [referenceDataLoaded, setReferenceDataLoaded] = useState(false);

  const { wrapper } = useApi();

  useEffect(() => {
    const load = async () => {
      const voyages = await wrapper(VoyagesService.getVoyages());
      setVoyages(voyages);
      setReferenceDataLoaded(true);
    };
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFetchData = async ({
    filters,
    pageIndex,
    pageSize,
    sortBy,
  }: {
    filters?: Filters<PaxExtraServiceSearchResult>;
    pageIndex?: number;
    pageSize?: number;
    sortBy?: SortingRule<PaxExtraServiceSearchResult>[];
  }) => {
    const newStatuses = (filters?.find(({ id }) => id === 'status')?.value ??
      []) as PaxExtraServiceStatus[];
    const brands = (filters?.find(({ id }) => id === 'brand')?.value ??
      []) as Brand[];

    const results = await wrapper(
      PaxService.searchPaxExtraServices({
        statuses: newStatuses,
        brands,
        pageNumber: pageIndex ?? pagination.pageNumber,
        pageSize: pageSize ?? pagination.pageSize,
        statusDateSortOrder: convertForApi(
          sortBy?.find((rule) => rule.id === 'statusDate')
        ),
      })
    );
    setTotalRecords(results.totalRecords);

    setPagination({
      pageNumber: results.pageNumber,
      pageSize: results.pageSize,
    });
    setPes(results.results);
  };

  const pageCount = useMemo(
    () => Math.ceil(totalRecords / pagination.pageSize),
    [pagination.pageSize, totalRecords]
  );

  if (!referenceDataLoaded) return <CircularProgress />;

  return (
    <ExtraServiceOverview
      pes={pes}
      voyages={voyages}
      onFetchData={onFetchData}
      totalRecords={totalRecords}
      pageCount={pageCount}
    />
  );
};

export default ExtraServiceOverviewContainer;
