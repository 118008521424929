import { createStyles } from '@material-ui/core/styles';

export default createStyles({
  allotmentContent: {
    width: '100%',
  },
  allotmentNote: {
    textAlign: 'left',
    marginBottom: '20px',
  },
  allotmentAllocationsTableHeader: {
    backgroundColor: '#EFF6FF',
  },
});
