import React from 'react';
import { HashLink } from 'react-router-hash-link';
import {
  Link,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import IAllotment from '../../common/types/IAllotment';
import IVoyage from '../../common/types/IVoyage';
import ISharingGroup from '../../common/types/ISharingGroup';
import PaxStatus, { isTakingCapacity } from '../../pax/enums/paxStatus';
import Routes from '../../common/constants/routes';
import styles from './VoyagePaxDetailsSummary.styles';

interface IProps extends WithStyles<typeof styles> {
  allotments: IAllotment[];
  voyage: IVoyage;
  sharingGroups: ISharingGroup[];
}

const VoyagePaxDetailsSummary: React.FC<IProps> = ({
  allotments,
  voyage,
  sharingGroups,
  classes,
}) => {
  const cabinCategoriesSorted = voyage.cabinCategories.sort((a, b) =>
    a.name.localeCompare(b.name, 'en', {
      numeric: true,
    })
  );

  const defaultAllotment = allotments.find((a) => a.defaultAllotment);

  return (
    <Table
      className={classes.summaryTable}
      size="small"
      aria-label="Voyage availability summary"
      stickyHeader
    >
      <TableHead>
        <TableRow>
          <TableCell>Cabin Category</TableCell>
          <TableCell>Total Cabins</TableCell>
          <TableCell>Total Berths</TableCell>
          <TableCell>Available Berths</TableCell>
          <TableCell>Held Berths</TableCell>
          <TableCell>Confirmed Berths</TableCell>
          <TableCell>Single Supplement</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {defaultAllotment &&
          cabinCategoriesSorted?.map((cc) => {
            const allocation = defaultAllotment.allocations.find(
              (a) => a.cabinCategoryId === cc.id
            );

            const availableBerths =
              allocation &&
              allocation.availability.femaleOnlyBerths +
                allocation.availability.maleOnlyBerths +
                allocation.availability.unallocatedBerths;

            const categorySharingGroups = sharingGroups.filter(
              (sg) => sg.cabinCategoryId === cc.id
            );

            const pax = categorySharingGroups.flatMap((sg) => sg.pax);
            const totalConfirmed = pax.filter(
              (p) => p.status === PaxStatus.Confirmed
            ).length;

            const groupsWithSingleSupplement = categorySharingGroups.filter(
              (sg) =>
                sg.singleSupplement &&
                sg.pax.some((p) => p.status === PaxStatus.Confirmed)
            );
            const singleSupplementCount = groupsWithSingleSupplement.reduce(
              (prev, sg) =>
                prev +
                cc.cabinCapacity -
                sg.pax.filter((p) => isTakingCapacity(p.status)).length,
              0
            );

            const heldBerths =
              availableBerths !== undefined
                ? cc.totalBerths -
                  availableBerths -
                  totalConfirmed -
                  singleSupplementCount
                : 0;

            return (
              allocation && (
                <React.Fragment key={cc.id}>
                  <TableRow key={'cabinCategoryId_' + cc.id}>
                    <TableCell>
                      <div data-attr={`cabinCategory_${cc.id}.name`}>
                        <Link
                          component={HashLink}
                          smooth
                          to={Routes.VoyageManagement.getLocation(
                            voyage.id,
                            cc.id
                          )}
                        >
                          {cc.name}
                        </Link>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div data-attr={`cabinCategory_${cc.id}.totalCabins`}>
                        {cc.totalCabins}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div data-attr={`cabinCategory_${cc.id}.totalBerths`}>
                        {cc.totalBerths}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div data-attr={`cabinCategory_${cc.id}.available`}>
                        {availableBerths}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div data-attr={`cabinCategory_${cc.id}.Held`}>
                        {heldBerths}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div data-attr={`cabinCategory_${cc.id}.confirmed`}>
                        {totalConfirmed}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div
                        data-attr={`cabinCategory_${cc.id}.SingleSupplement`}
                      >
                        {singleSupplementCount}
                      </div>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              )
            );
          })}
      </TableBody>
    </Table>
  );
};

export default withStyles(styles)(VoyagePaxDetailsSummary);
