import React, { useState, useEffect } from 'react';
import IAuditRecord from '../../common/types/IAuditRecord';
import { useApi } from '../../common/contexts/ApiContext';
import PaxService from '../../services/PaxService';
import PaxAuditHistory from './PaxAuditHistory';

interface IProps {
  sharingGroupGuid: string;
}

const PaxAuditHistoryContainer: React.FC<IProps> = ({ sharingGroupGuid }) => {
  const [auditHistory, setAuditHistory] = useState<IAuditRecord[]>([]);

  const { wrapper } = useApi();

  useEffect(() => {
    const loadAuditHistory = async () => {
      const loadedAuditHistory = await wrapper(PaxService.getSharingGroupAuditHistory(sharingGroupGuid));
      setAuditHistory(loadedAuditHistory);
    };
    loadAuditHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sharingGroupGuid]);

  return (
    <PaxAuditHistory
      auditHistory={auditHistory}
    />
  );
};

export default PaxAuditHistoryContainer;
