import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import IVoyage from '../../common/types/IVoyage';
import VoyagesService from '../../services/VoyagesService';
import PaxService from '../../services/PaxService';
import ISharingGroup from '../../common/types/ISharingGroup';
import PaxCabinCategoryUpgrade from './PaxCabinCategoryUpgrade';
import IAllotment from '../../common/types/IAllotment';
import Routes from '../../common/constants/routes';
import { withApi, IWithApi } from '../../common/contexts/ApiContext';
import NotFoundError from '../../services/errors/NotFoundError';
import NotFound404 from '../../common/components/NotFound404';
import ForbiddenError from '../../services/errors/ForbiddenError';
import AccessDenied from '../../common/components/AccessDenied';
import {
  IWithSnackbar,
  withSnackbar,
} from '../../common/contexts/SnackbarContext';
import pluralize from 'pluralize';
import ISharingGroupOperationalInfo from '../../common/types/ISharingGroupOperationalInfo';

interface IState {
  voyage?: IVoyage;
  voyageAllotments?: IAllotment[];
  sharingGroup?: ISharingGroup;
  selectedCabinCategoryId?: number;
  selectedSingleSupplement: boolean;
  operationalInfo?: ISharingGroupOperationalInfo;
}

class PaxCabinCategoryUpgradeContainer extends React.Component<
  RouteComponentProps & IWithApi & IWithSnackbar
> {
  readonly state: IState = {
    voyage: undefined,
    sharingGroup: undefined,
    selectedCabinCategoryId: undefined,
    selectedSingleSupplement: false,
  };
  private _isMounted = false;
  private apiWrapper = this.props.api.wrapper;

  async componentDidMount() {
    this._isMounted = true;

    await this.loadData();
  }

  async loadData() {
    const values = new URLSearchParams(this.props.location.search);
    const sharingGroupId = values.get('sharingGroupId');
    const voyageId = values.get('voyageId');

    if (sharingGroupId && voyageId && !isNaN(+voyageId)) {
      await Promise.all([
        this.loadVoyage(+voyageId),
        this.loadVoyageAllotments(+voyageId),
        this.loadSharingGroup(sharingGroupId),
        this.loadOperationalInfo(sharingGroupId),
      ]);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async loadVoyage(voyageId: number) {
    const voyage = await this.apiWrapper(
      VoyagesService.getVoyageById(voyageId)
    );
    this.updateState({ voyage });
  }

  async loadSharingGroup(sharingGroupId: string) {
    const sharingGroup = await this.apiWrapper(
      PaxService.getSharingGroup(sharingGroupId)
    );

    this.updateState({ sharingGroup });
  }

  async loadVoyageAllotments(voyageId: number) {
    const voyageAllotments = await this.apiWrapper(
      VoyagesService.getAllotmentsByVoyage(voyageId)
    );
    this.updateState({ voyageAllotments });
  }

  async loadOperationalInfo(sharingGroupId: string) {
    const operationalInfo = await this.apiWrapper(
      PaxService.getOperationalInfo(sharingGroupId)
    );
    this.updateState({ operationalInfo });
  }

  updateState(updatedState: Partial<IState>) {
    if (this._isMounted) {
      this.setState(updatedState);
    }
  }

  onSelectCabinCategory = async (cabinCategoryId?: number) => {
    this.updateState({
      selectedCabinCategoryId: cabinCategoryId,
      selectedSingleSupplement: false,
    });
  };

  onSingleSupplementChange = async (selectedSingleSupplement: boolean) => {
    this.updateState({
      selectedSingleSupplement,
    });
  };

  onSubmit = async () => {
    const {
      voyage,
      sharingGroup,
      selectedCabinCategoryId,
      selectedSingleSupplement,
    } = this.state;

    if (
      window.confirm('Are you sure you want to move to a different category?')
    ) {
      if (sharingGroup === undefined || selectedCabinCategoryId === undefined)
        return;
      try {
        await this.apiWrapper(
          PaxService.upgradeSharingGroupCabinCategory(sharingGroup.groupId, {
            cabinCategoryId: selectedCabinCategoryId,
            singleSupplement: selectedSingleSupplement,
          })
        );

        this.props.snackbar.showMessage(
          `${pluralize(
            'Passenger',
            sharingGroup.pax.length
          )} upgraded successfully!`,
          { autoHideDuration: 8000, disableClickaway: true }
        );

        this.props.history.push(
          Routes.VoyageManagement.getLocation(
            voyage?.id,
            sharingGroup.cabinCategoryId
          )
        );
      } catch (ex) {
        window.alert(ex.errors ? ex.errors.join('\n') : ex.message);
      }
    }
  };

  onRemoveUpgrade = async () => {
    const { voyage, sharingGroup } = this.state;

    if (
      window.confirm(
        'Are you sure you want to remove the cabin category upgrade?'
      )
    ) {
      if (sharingGroup === undefined) return;
      try {
        await this.apiWrapper(
          PaxService.removeSharingGroupCabinCategoryUpgrade(
            sharingGroup.groupId
          )
        );

        this.props.snackbar.showMessage(
          `Passenger ${pluralize('upgrade', sharingGroup.pax.length)} removed`
        );

        this.props.history.push(
          Routes.VoyageManagement.getLocation(
            voyage?.id,
            sharingGroup.cabinCategoryId
          )
        );
      } catch (ex) {
        window.alert(ex.errors ? ex.errors.join('\n') : ex.message);
      }
    }
  };

  render() {
    const { error } = this.props.api;
    if (error instanceof NotFoundError) return <NotFound404 />;
    if (error instanceof ForbiddenError) return <AccessDenied />;

    const {
      voyage,
      voyageAllotments,
      sharingGroup,
      selectedCabinCategoryId,
      selectedSingleSupplement,
      operationalInfo,
    } = this.state;

    return (
      <div>
        {voyage && sharingGroup && voyageAllotments && operationalInfo && (
          <PaxCabinCategoryUpgrade
            voyage={voyage}
            voyageAllotments={voyageAllotments}
            sharingGroup={sharingGroup}
            selectedCabinCategoryId={selectedCabinCategoryId}
            selectedSingleSupplement={selectedSingleSupplement}
            onSubmit={this.onSubmit}
            onSelectCabinCategory={this.onSelectCabinCategory}
            onSingleSupplementChange={this.onSingleSupplementChange}
            onRemoveUpgrade={this.onRemoveUpgrade}
            operationalInfo={operationalInfo}
          />
        )}
      </div>
    );
  }
}

export default withApi(
  withSnackbar(withRouter(PaxCabinCategoryUpgradeContainer))
);
