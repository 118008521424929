import { Typography } from '@material-ui/core';
import React from 'react';

export default function NotFound404() {
  return (
    <React.Fragment>
      <Typography component="h2" variant="h5" gutterBottom>
        Navigation failed!
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        It appears you're lost at sea..
      </Typography>
      <img
        src="https://live.staticflickr.com/3147/3000884990_e1a2a15c0c.jpg"
        alt="Lost at Sea"
      />
    </React.Fragment>
  );
}
