import React from 'react';
import { makeStyles } from '@material-ui/core';
import styles from './FormAlerts.styles';
import { Alert, AlertTitle } from '@material-ui/lab';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import classNames from 'classnames';

interface IProps {
  title?: string;
  alertType?: 'error' | 'warning' | 'success' | 'info';
  alerts: string[] | string;
}

const useStyles = makeStyles(styles);

const AlertItem: React.FC<{
  alert: string;
  alertType?: 'error' | 'warning' | 'success' | 'info';
}> = ({ alert, alertType }) => {
  const classes = useStyles();

  return (
    <span className={classes.alertBody}>
      {alertType === 'error' ? (
        <ErrorOutlineIcon
          className={classNames(classes.alertIcon, classes.alertErrorIcon)}
          color="action"
          fontSize="small"
        />
      ) : (
        <ReportProblemOutlinedIcon
          className={classNames(classes.alertIcon, classes.alertWarningIcon)}
          fontSize="small"
        />
      )}
      <span className={classes.alertText}>{alert}</span>
    </span>
  );
};

const FormAlerts: React.FC<IProps> = ({
  alertType,
  alerts,
  title = alertType === 'error'
    ? 'Please update the form or refresh the page before submitting again.'
    : '',
}) => {
  const classes = useStyles();
  const alertItems = alerts instanceof Array ? alerts : [alerts];

  return (
    <Alert icon={false} severity={alertType} className={classes.alertContainer}>
      {alertItems.map((alert, index) => (
        <AlertItem key={index} alert={alert} alertType={alertType} />
      ))}
      {title && <AlertTitle className={classes.alertText}>{title}</AlertTitle>}
    </Alert>
  );
};

export default FormAlerts;
