import BaseService from './BaseService';
import formatDate from '../common/helpers/formatDate';

class FinanceService extends BaseService {
  resourceBase = '/finance';

  async getSalesExtract(): Promise<void> {
    const today = formatDate(new Date());
    return await this.download(
      `${this.resourceBase}/report`,
      `OE_Sales_Extract_${today}.csv`
    );
  }
}

const service = new FinanceService();

export default service;
