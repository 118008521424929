import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { FORM_ERROR } from 'final-form';
import PaxService from '../../services/PaxService';
import AllotmentService from '../../services/AllotmentService';
import VoyagesService from '../../services/VoyagesService';
import PaxListForm from './PaxListForm';
import IVoyage from '../../common/types/IVoyage';
import ISharingGroup from '../../common/types/ISharingGroup';
import UnprocessableEntity from '../../services/errors/UnprocessableEntity';
import IAllotment from '../../common/types/IAllotment';
import BadRequest from '../../services/errors/BadRequest';
import { useSnackbar } from '../../common/contexts/SnackbarContext';
import pluralize from 'pluralize';
import ForbiddenError from '../../services/errors/ForbiddenError';
import AccessDenied from '../../common/components/AccessDenied';
import NotFound404 from '../../common/components/NotFound404';
import NotFoundError from '../../services/errors/NotFoundError';
import { useApi } from '../../common/contexts/ApiContext';
import { useUser } from '../../common/contexts/UserContext';
import IPax from '../../common/types/IPax';

const deleteStatusDate = (pax: IPax) => {
  if (pax.confirmedDate === null) delete pax.confirmedDate;
  if (pax.optionExpiryDate === null) delete pax.optionExpiryDate;
  if (pax.expiredOptionDate === null) delete pax.expiredOptionDate;
  if (pax.cancelledDate === null) delete pax.cancelledDate;
};

const finalFormWorkaround = (sharingGroup: ISharingGroup) => {
  // https://github.com/final-form/final-form/pull/424
  sharingGroup.pax.forEach(deleteStatusDate);
};

const PaxUpdateFormContainer: React.FC = () => {
  const [sharingGroup, setSharingGroup] = useState<ISharingGroup>();
  const [allotment, setAllotment] = useState<IAllotment>();
  const [voyages, setVoyages] = useState<IVoyage[]>([]);
  const snackbar = useSnackbar();
  const { wrapper, error } = useApi();
  const user = useUser();
  const { guid } = useParams<{ guid: string }>();

  const submit = async (sharingGroup: ISharingGroup) => {
    try {
      if (sharingGroup) {
        // Pass through the current allotment rather than the currently selected
        const updatedSharingGroup = await wrapper(
          AllotmentService.updateSharingGroup(
            sharingGroup.bookedAllotmentId,
            guid,
            sharingGroup
          )
        );
        finalFormWorkaround(updatedSharingGroup);
        setSharingGroup(updatedSharingGroup);

        snackbar.showMessage(
          `${pluralize(
            'Passenger',
            updatedSharingGroup.pax.length
          )} updated successfully!`,
          { autoHideDuration: 8000, disableClickaway: true }
        );
      }
    } catch (ex) {
      if (ex instanceof UnprocessableEntity || ex instanceof BadRequest) {
        return {
          [FORM_ERROR]: ex.errors,
        };
      }
    }
  };

  useEffect(() => {
    AllotmentService.setUser(user);
    const load = async () => {
      const sharingGroup = await wrapper(PaxService.getSharingGroup(guid));
      finalFormWorkaround(sharingGroup);
      setSharingGroup(sharingGroup);

      const [allotment, voyages] = await wrapper(
        Promise.all([
          AllotmentService.getAllotment(sharingGroup.bookedAllotmentId),
          VoyagesService.getVoyages(),
        ])
      );
      setVoyages(voyages);
      setAllotment(allotment);
    };
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const voyage = useMemo(
    () =>
      voyages.find((v) =>
        v.cabinCategories.find(
          (cc) => cc.id === sharingGroup?.bookedCabinCategoryId
        )
      ),
    [sharingGroup, voyages]
  );

  if (!sharingGroup || !allotment || !voyage) return null;
  if (error instanceof NotFoundError) return <NotFound404 />;
  if (error instanceof ForbiddenError) return <AccessDenied />;

  return (
    <PaxListForm
      initialValues={sharingGroup}
      updateMode
      onSubmit={submit}
      allotment={allotment}
      voyage={voyage}
      sharingGroup={sharingGroup}
    />
  );
};

export default PaxUpdateFormContainer;
