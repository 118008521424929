import env from '../core/env';
import BadRequest from './errors/BadRequest';
import Unauthorized from './errors/Unauthorized';
import NetworkError from './errors/NetworkError';
import NotFoundError from './errors/NotFoundError';
import ForbiddenError from './errors/ForbiddenError';
import InternalServerError from './errors/InternalServerError';
import UnprocessableEntity from './errors/UnprocessableEntity';
import ApiResponse, {
  PaginatedApiResponse,
  isPaginatedApiResponse,
} from './types/ApiResponse';
import auth0Client from '../common/authentication/auth0Client';
import isoStringToDateReviver from '../common/helpers/isoStringToDateReviver';
import IUser from '../common/types/IUser';
import PaginatedResult from '../common/types/PaginatedResult';

enum HttpMethod {
  Get = 'GET',
  Post = 'POST',
  Put = 'PUT',
}

export default abstract class BaseService {
  private readonly baseUrl: string = env.REACT_APP_API_BASE_URL;
  protected abstract resourceBase: string;

  protected user: IUser | undefined = undefined;

  public setUser(user: IUser) {
    this.user = user;
  }

  protected async getWithPagination<T>(
    resourcePath: string,
    headers?: Headers
  ) {
    const request = await this.createRequest(
      resourcePath,
      HttpMethod.Get,
      headers
    );

    return this.fetch(request).then((value) =>
      extractPaginatedResult<T>(value)
    );
  }

  protected get<T>(resourcePath: string, headers?: Headers) {
    return this.performRequest<T>(resourcePath, HttpMethod.Get, headers);
  }

  protected post<T>(resourcePath: string, data: any, headers?: Headers) {
    return this.performRequest<T>(resourcePath, HttpMethod.Post, headers, data);
  }

  protected put<T>(resourcePath: string, data: any, headers?: Headers) {
    return this.performRequest<T>(resourcePath, HttpMethod.Put, headers, data);
  }

  protected async download(
    resourcePath: string,
    filename: string,
    headers?: Headers
  ) {
    var request = await this.createRequest(
      resourcePath,
      HttpMethod.Get,
      headers
    );

    return this.fetch(request)
      .then((response) => response.blob())
      .then((blob) => {
        var url = window.URL.createObjectURL(blob);
        // create file link for blob
        var a = document.createElement('a');
        a.href = url;
        a.download = filename;
        // append element to the dom
        document.body.appendChild(a);
        a.click();
        // remove element after click
        a.remove();
        // make sure to remove object url, to release memory
        window.URL.revokeObjectURL(url);
      });
  }

  private async performRequest<T>(
    resourcePath: string,
    method: HttpMethod,
    headers?: Headers,
    data?: any
  ) {
    const request = await this.createRequest(
      resourcePath,
      method,
      headers,
      data
    );

    return this.fetch(request).then((value) => extractBody<T>(value));
  }

  private async createRequest(
    resourcePath: string,
    method: HttpMethod,
    headers?: Headers,
    data?: any
  ) {
    headers = new Headers(headers);
    const token = await auth0Client.getTokenSilently!();
    headers.append('Authorization', 'Bearer ' + token);
    headers.append('Content-Type', 'application/json;charset=utf-8');

    const url = `${this.baseUrl}${resourcePath}`;

    return new Request(url, {
      headers,
      method,
      body: JSON.stringify(data),
      credentials: 'same-origin',
    });
  }

  private async fetch(request: Request) {
    return fetch(request)
      .catch(() => Promise.reject(new NetworkError()))
      .then(validate);
  }
}

async function validate(response: Response) {
  if (response.ok) {
    return response;
  }

  if (response.status === 400) {
    const apiResponse = await extract(response);
    return Promise.reject(new BadRequest(apiResponse.errors));
  }

  if (response.status === 401) {
    return Promise.reject(new Unauthorized());
  }

  if (response.status === 403) {
    return Promise.reject(new ForbiddenError());
  }

  if (response.status === 404) {
    return Promise.reject(new NotFoundError());
  }

  if (response.status === 422) {
    const apiResponse = await extract(response);
    return Promise.reject(new UnprocessableEntity(apiResponse.errors));
  }

  // Handle client errors
  if (response.status > 400 && response.status < 499) {
    return Promise.reject(new BadRequest([]));
  }

  if (response.status === 302) {
    return Promise.reject(new Unauthorized());
  }

  if (response.status === 500) {
    return Promise.reject(new InternalServerError());
  }

  // Handle server errors
  if (response.status > 500 && response.status < 600) {
    return Promise.reject(new NetworkError());
  }

  return response;
}

async function extract(
  response: Response
): Promise<ApiResponse | PaginatedApiResponse> {
  return response.text().then(
    (text) =>
      text &&
      JSON.parse(text, (key, value) => {
        return isoStringToDateReviver(value);
      })
  );
}

const extractPaginatedResult = async <T>(
  response: Response
): Promise<PaginatedResult<T>> => {
  const apiResponse = await extract(response);
  if (isPaginatedApiResponse(apiResponse))
    return {
      results: apiResponse.body,
      pageNumber: apiResponse.pageNumber,
      pageSize: apiResponse.pageSize,
      totalRecords: apiResponse.totalRecords,
    };
  else throw new NetworkError();
};

async function extractBody<T>(response: Response): Promise<T> {
  const apiResponse = await extract(response);
  return apiResponse.body;
}
