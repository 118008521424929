import React from 'react';
import {
  withStyles,
  WithStyles,
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  Card,
  CardContent,
  Button,
  Tooltip,
  IconButton,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import styles from './PaxCabinCategoryUpgrade.styles';
import IVoyage from '../../common/types/IVoyage';
import ISharingGroup from '../../common/types/ISharingGroup';
import IPax from '../../common/types/IPax';
import formatDate from '../../common/helpers/formatDate';
import getAvailabilityText from '../../common/helpers/getAvailabilityText';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import CabinCategorySelect from '../../common/components/CabinCategorySelect';
import ICabinCategory from '../../common/types/ICabinCategory';
import IAllotment from '../../common/types/IAllotment';
import { HelpOutline } from '@material-ui/icons';
import { withRouter, RouteComponentProps } from 'react-router';
import Routes from '../../common/constants/routes';
import { paxStatusLabels } from '../enums/paxStatus';
import createFullName from '../../common/helpers/createFullName';
import ISharingGroupOperationalInfo from '../../common/types/ISharingGroupOperationalInfo';

interface IProps extends WithStyles<typeof styles>, RouteComponentProps {
  voyage: IVoyage;
  voyageAllotments: IAllotment[];
  sharingGroup: ISharingGroup;
  operationalInfo: ISharingGroupOperationalInfo;
  selectedCabinCategoryId?: number;
  selectedSingleSupplement: boolean;
  onSubmit: () => void;
  onSelectCabinCategory: (cabinCategoryId: number) => void;
  onSingleSupplementChange: (selectedSingleSupplement: boolean) => void;
  onRemoveUpgrade: () => void;
}

enum CabinCategorySection {
  BookedCabinCategory,
  UpgradeCabinCategory,
}
class PaxCabinCategoryUpgrade extends React.Component<IProps> {
  private renderVoyage = () => {
    const { voyage } = this.props;
    return (
      <div>
        <div>Voyage Code: {voyage.tripCode} </div>
        <div>Departure Date: {formatDate(voyage.departureDate)} </div>
      </div>
    );
  };

  private onClickCancel = (voyageId: number) => (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (!this.props.selectedCabinCategoryId) {
      this.redirectToVoyage(voyageId);
      return;
    }

    if (
      window.confirm(
        'Are you sure you want to leave the Cabin Category Upgrade page?, changes not saved will be lost'
      )
    ) {
      this.redirectToVoyage(voyageId);
    }
  };

  private redirectToVoyage(voyageId: number) {
    this.props.history.push(
      Routes.VoyageManagement.getLocation(
        voyageId,
        this.props.sharingGroup.cabinCategoryId
      )
    );
  }

  private renderCabinCategory = (
    cabinCategory: ICabinCategory,
    voyageAllotment: IAllotment
  ) => {
    const voyageAllotmentAllocation = voyageAllotment.allocations.find(
      (va) => va.cabinCategoryId === cabinCategory.id
    )!;

    return (
      <div>
        <div>Name: {cabinCategory.name}</div>
        <div>Capacity: {cabinCategory.cabinCapacity}</div>
        {cabinCategory.availableForSingleSupp && (
          <div>Available for Single Sup: Yes</div>
        )}
        {cabinCategory.compulsorySingleSupp && (
          <div>Compulsory for Single Sup: Yes</div>
        )}
        <div>Total Cabins: {cabinCategory.totalCabins}</div>
        <div>Total Berths: {cabinCategory.totalBerths}</div>

        <div>
          Availability:{' '}
          {getAvailabilityText(voyageAllotmentAllocation.availability)}
        </div>
      </div>
    );
  };

  private handleChangeCabinCategory = async (e: any) => {
    const cabinCategoryId = e.target.value;
    this.props.onSelectCabinCategory(cabinCategoryId);
  };

  private handleSingleSupplementChange = async (e: any) => {
    const selectedSingleSupplement = e.target.checked;
    this.props.onSingleSupplementChange(selectedSingleSupplement);
  };

  private renderSharingGroup = (
    pageSection: CabinCategorySection,
    isUpgraded: boolean
  ) => {
    const { voyage, sharingGroup, classes, operationalInfo } = this.props;

    const cabins = voyage.cabinCategories.flatMap((c) => c.cabins);

    return (
      <div>
        {pageSection === CabinCategorySection.BookedCabinCategory &&
          sharingGroup.bookedSingleSupplement && <div>Single Sup: Yes</div>}

        {pageSection === CabinCategorySection.UpgradeCabinCategory &&
          sharingGroup.singleSupplement && <div>Single Sup: Yes</div>}

        {((pageSection === CabinCategorySection.UpgradeCabinCategory &&
          isUpgraded) ||
          (pageSection === CabinCategorySection.BookedCabinCategory &&
            !isUpgraded)) && (
          <div>
            Assigned Cabin:{' '}
            {sharingGroup.assignedCabinId &&
              cabins.find((c) => c.id === sharingGroup.assignedCabinId)!
                .cabinNumber}
            <Tooltip
              className={classes.tooltip}
              title={
                <span>
                  Upgrading passengers will remove their assigned cabin
                </span>
              }
            >
              <IconButton aria-label="help">
                <HelpOutline />
              </IconButton>
            </Tooltip>
          </div>
        )}

        {pageSection === CabinCategorySection.BookedCabinCategory && (
          <div>
            Requested Cabin:{' '}
            {operationalInfo.requestedCabinId &&
              cabins.find((c) => c.id === operationalInfo.requestedCabinId)
                ?.cabinNumber}
          </div>
        )}
      </div>
    );
  };

  private renderPax = () => {
    const { sharingGroup, operationalInfo, classes } = this.props;
    return (
      <div>
        <Table size="small" aria-label="Passenger Details Table">
          <TableHead>
            <TableRow className={classes.paxTableHeader}>
              <TableCell>Name</TableCell>
              <TableCell>Gender</TableCell>
              <TableCell>DOB</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Notes</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sharingGroup.pax.map((pax: IPax) => {
              const poi = operationalInfo.pax.find((p) => p.paxId === pax.id);
              return (
                <TableRow key={`pax_${pax.id}`}>
                  <TableCell>
                    <div>
                      {createFullName(
                        pax.firstName,
                        pax.middleName,
                        pax.surname
                      )}
                    </div>
                  </TableCell>
                  <TableCell className={classes.paxCellCapitalize}>
                    <div>{pax.gender}</div>
                  </TableCell>
                  <TableCell>
                    <div>{pax.dateOfBirth && formatDate(pax.dateOfBirth)}</div>
                  </TableCell>
                  <TableCell>
                    <div>{paxStatusLabels[pax.status]}</div>
                  </TableCell>
                  <TableCell>
                    <div>{poi?.notes}</div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    );
  };

  render() {
    const {
      voyage,
      voyageAllotments,
      classes,
      sharingGroup,
      selectedCabinCategoryId,
      selectedSingleSupplement,
    } = this.props;

    // Upgraded Cabin Category - Sharing Group Cabin Catetory
    const sharingGroupCabinCategory = voyage.cabinCategories.find(
      (cc) => cc.id === sharingGroup.cabinCategoryId
    )!;

    const sharingGroupCabinCategoryBooked = voyage.cabinCategories.find(
      (cc) => cc.id === sharingGroup.bookedCabinCategoryId
    )!;

    // New Cabin Category - Selected Cabin Category
    const newCabinCategory =
      selectedCabinCategoryId &&
      voyage.cabinCategories.find((cc) => cc.id === selectedCabinCategoryId)!;

    // Allotment of sharing group
    const voyageAllotment = voyageAllotments.find(
      (va) => va.id === sharingGroup.allotmentId
    )!;

    const voyageAllotmentBooked = voyageAllotments.find(
      (va) => va.id === sharingGroup.bookedAllotmentId
    )!;

    // Allotment Cabin Categories
    const allotmentCabinCategoryIds = voyageAllotment.allocations.flatMap(
      (m) => m.cabinCategoryId
    );

    const allotmentCabinCategories = voyage.cabinCategories.filter((cc) =>
      allotmentCabinCategoryIds.includes(cc.id)
    );

    // Disabled cabin categories
    const cabinCategoryIdsWinNoBerthsAvailable = voyageAllotment.allocations
      .filter(
        (a) =>
          a.availability.maleOnlyBerths === 0 &&
          a.availability.femaleOnlyBerths === 0 &&
          a.availability.unallocatedBerths === 0
      )
      .map((a) => a.cabinCategoryId);

    const cabinCategoriesWithNoBerthsAvailable = allotmentCabinCategories.filter(
      (cc) => cabinCategoryIdsWinNoBerthsAvailable.includes(cc.id)
    );

    const disabledCabinCategories = [
      sharingGroupCabinCategory,
      sharingGroupCabinCategoryBooked,
      ...cabinCategoriesWithNoBerthsAvailable,
    ];

    const isUpgraded =
      sharingGroupCabinCategory.id !== sharingGroupCabinCategoryBooked.id;

    return (
      <div>
        <h3>Upgrade Passengers Cabin Category</h3>

        <div className={classes.voyageContainer}>
          <Box fontWeight="fontWeightBold">Voyage and Allotment Details</Box>
          {this.renderVoyage()}
          <div>Allotment: {voyageAllotment.name}</div>
          <div>Booked Allotment: {voyageAllotmentBooked.name}</div>
        </div>

        <Grid container spacing={0} alignItems="flex-start">
          <Grid item xs={5} sm={5}>
            <Card variant="outlined">
              <CardContent>
                <div className={classes.sectionContainer}>
                  <Box fontWeight="fontWeightBold">Booked Cabin Category</Box>
                  {this.renderCabinCategory(
                    sharingGroupCabinCategoryBooked,
                    voyageAllotmentBooked
                  )}
                  <hr></hr>
                  {this.renderSharingGroup(
                    CabinCategorySection.BookedCabinCategory,
                    isUpgraded
                  )}
                  <hr></hr>
                  <Box fontWeight="fontWeightBold">Passengers</Box>
                  {this.renderPax()}
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={2} sm={2}>
            <KeyboardArrowRightIcon fontSize="large"></KeyboardArrowRightIcon>
          </Grid>
          <Grid item xs={5} sm={5}>
            <Card variant="outlined">
              <CardContent>
                <div className={classes.sectionContainer}>
                  <Box fontWeight="fontWeightBold">New Cabin Category</Box>

                  <Grid container spacing={0} alignItems="flex-start">
                    <Grid item xs={6} sm={6}>
                      <CabinCategorySelect
                        selectedCabinCategoryId={selectedCabinCategoryId}
                        cabinCategories={allotmentCabinCategories}
                        disabledCabinCategories={disabledCabinCategories}
                        displayAvailability={true}
                        allotmentAllocations={voyageAllotment.allocations}
                        onChange={this.handleChangeCabinCategory}
                        displayLabel={false}
                      />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <div className={classes.singleSupplementContainer}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={selectedSingleSupplement}
                              color="primary"
                              name="selectedSingleSupplement"
                              onChange={this.handleSingleSupplementChange}
                              disabled={
                                !newCabinCategory ||
                                !newCabinCategory.availableForSingleSupp
                              }
                            />
                          }
                          label="Single Supplement"
                        />
                      </div>
                    </Grid>
                  </Grid>

                  {newCabinCategory &&
                    this.renderCabinCategory(newCabinCategory, voyageAllotment)}
                </div>
                {isUpgraded && (
                  <div className={classes.sectionUpgradedContainer}>
                    <hr></hr>
                    <div className={classes.removeUpgradeButton}>
                      <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={this.props.onRemoveUpgrade}
                        className={classes.saveButton}
                      >
                        Remove Upgrade
                      </Button>
                    </div>
                    <Box fontWeight="fontWeightBold">
                      Upgraded Cabin Category
                    </Box>
                    {this.renderCabinCategory(
                      sharingGroupCabinCategory,
                      voyageAllotment
                    )}
                    <hr></hr>
                    {this.renderSharingGroup(
                      CabinCategorySection.UpgradeCabinCategory,
                      isUpgraded
                    )}
                  </div>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <div>
          <Button
            size="small"
            variant="contained"
            color="primary"
            disabled={!selectedCabinCategoryId}
            onClick={this.props.onSubmit}
            className={classes.saveButton}
          >
            Submit
          </Button>

          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={this.onClickCancel(voyage.id)}
            className={classes.saveButton}
          >
            Cancel
          </Button>
        </div>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(PaxCabinCategoryUpgrade));
