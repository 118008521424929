import { createStyles } from '@material-ui/core/styles';

export default createStyles({
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  paxListItem: {
    'text-transform': 'capitalize',
  },
  paxListInfo: {
    align: 'left',
  },
  paxCount: {
    'line-height': '0.5em',
  },
});
