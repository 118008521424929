import React from 'react';
import { Field } from 'react-final-form';
// @ts-ignore
import { OnChange } from 'react-final-form-listeners';

interface IProps {
  changedField: string;
  shouldUpdate: boolean;
  updateField: string;
  updateValue: any;
}

const WhenFieldChanges: React.FC<IProps> = ({
  changedField,
  shouldUpdate,
  updateField,
  updateValue,
}) => {
  return (
    <Field name={updateField} subscription={{}}>
      {(
        // No subscription. We only use Field to get to the change function
        { input: { onChange } }
      ) => (
        <OnChange name={changedField}>
          {() => {
            if (shouldUpdate) {
              onChange(updateValue);
            }
          }}
        </OnChange>
      )}
    </Field>
  );
};

export default WhenFieldChanges;
