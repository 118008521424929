import React from 'react';
import IPax from '../../common/types/IPax';
import { Typography, makeStyles } from '@material-ui/core';
import pluralize from 'pluralize';
import createFullName from '../../common/helpers/createFullName';
import PaxStatus from '../enums/paxStatus';
import { Alert } from '@material-ui/lab';
import styles from './PaxConfirmationDialogContent.styles';

const useStyles = makeStyles(styles);

const PaxList: React.FC<{
  pax: IPax[];
  status: PaxStatus.Confirmed | PaxStatus.Cancelled;
}> = ({ pax, status }) => {
  const classes = useStyles();
  if (!pax.length) return null;
  return (
    <React.Fragment>
      {status === PaxStatus.Confirmed && (
        <React.Fragment>
          <Alert severity="warning" variant="outlined">
            {`By confirming the below ${pluralize(
              'passenger',
              pax.length
            )} you are locking in the details provided and will need to contact the OE Team to make changes after confirmation. Please ensure all information is correct and deposit has been collected.`}
          </Alert>
          <br />
        </React.Fragment>
      )}

      <Typography variant="body2">
        Are you sure you want to{' '}
        {
          <em>
            <strong>
              {status === PaxStatus.Confirmed ? 'confirm' : 'cancel'}
            </strong>
          </em>
        }{' '}
        the following {pluralize('passenger', pax.length)}?
      </Typography>

      <ul
        aria-label={`${status}-pax`}
        className={classes.confirmationDialogPaxList}
      >
        {pax.map(({ firstName, middleName, surname }, index) => (
          <li key={index}>{createFullName(firstName, middleName, surname)}</li>
        ))}
      </ul>
    </React.Fragment>
  );
};

const PaxConfirmationDialogContent: React.FC<{
  paxConfirmed: IPax[];
  paxCancelled: IPax[];
}> = ({ paxConfirmed, paxCancelled }) => {
  return (
    <React.Fragment>
      <PaxList status={PaxStatus.Confirmed} pax={paxConfirmed} />
      <PaxList status={PaxStatus.Cancelled} pax={paxCancelled} />
    </React.Fragment>
  );
};

export default PaxConfirmationDialogContent;
