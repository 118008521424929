import BaseService from './BaseService';
import ISharingGroup from '../common/types/ISharingGroup';
import convertToAPIDate from './helpers/convertToAPIDate';
import convertToUTCDate from './helpers/convertToUTCDate';
import PaxStatus from '../pax/enums/paxStatus';
import IAllotment from '../common/types/IAllotment';
import Role from '../common/enums/Role';
import { cloneDeep } from 'lodash';

class AllotmentService extends BaseService {
  resourceBase = '/allotment';

  async getAllotment(allotmentId: number): Promise<IAllotment> {
    return this.get(`${this.resourceBase}/${allotmentId}`);
  }

  async releaseAllotment(allotmentId: number) {
    return this.put(`${this.resourceBase}/${allotmentId}/release`, {});
  }

  async insertSharingGroup(
    allotmentId: number,
    sharingGroup: ISharingGroup
  ): Promise<ISharingGroup> {
    var sharingGroupModified = this.removeUnnecessaryPaxFields(sharingGroup);
    return await this.post(
      `${this.resourceBase}/${allotmentId}/pax/`,
      this.convertForApi(sharingGroupModified)
    );
  }

  async updateSharingGroup(
    allotmentId: number,
    sharingGroupGuid: string,
    sharingGroup: ISharingGroup
  ): Promise<ISharingGroup> {
    var sharingGroupModified = this.removeUnnecessaryPaxFields(sharingGroup);

    return await this.put(
      `${this.resourceBase}/${allotmentId}/pax/${sharingGroupGuid}`,
      this.convertForApi(sharingGroupModified)
    );
  }

  private convertForApi(sharingGroup: ISharingGroup) {
    const paxListModified = sharingGroup.pax.map((p) => ({
      ...p,
      dateOfBirth: convertToAPIDate(p.dateOfBirth),
      optionExpiryDate: convertToUTCDate(p.optionExpiryDate),
      confirmedDate: convertToUTCDate(p.confirmedDate),
      cancelledDate: convertToUTCDate(p.cancelledDate),
    }));

    const sharingGroupModified = { ...sharingGroup, pax: paxListModified };

    return sharingGroupModified;
  }

  private removeUnnecessaryPaxFields(sharingGroup: ISharingGroup) {
    let sharingGroupModified = cloneDeep(sharingGroup);

    sharingGroupModified.pax.forEach((pax) => {
      if (
        pax.status !== PaxStatus.Option ||
        !this.user?.hasRole(Role.OperationsManager)
      ) {
        delete pax.optionExpiryDate;
      }
      if (
        pax.status !== PaxStatus.Confirmed ||
        !this.user?.hasRole(Role.OperationsManager)
      ) {
        delete pax.confirmedDate;
      }
      if (
        pax.status !== PaxStatus.ExpiredOption ||
        !this.user?.hasRole(Role.OperationsManager)
      ) {
        delete pax.expiredOptionDate;
      }
      if (
        pax.status !== PaxStatus.Cancelled ||
        !this.user?.hasRole(Role.OperationsManager)
      ) {
        delete pax.cancelledDate;
      }

      delete pax.createdDate;
      delete pax.updatedDate;
    });

    return sharingGroupModified;
  }
}

const service = new AllotmentService();

export default service;
