import React from 'react';
import IVoyage from '../../common/types/IVoyage';
import ISharingGroup from '../../common/types/ISharingGroup';
import VoyagesService from '../../services/VoyagesService';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ManageBookings from './ManageBookings';
import { withApi, IWithApi } from '../../common/contexts/ApiContext';
import NotFound404 from '../../common/components/NotFound404';
import NotFoundError from '../../services/errors/NotFoundError';
import ForbiddenError from '../../services/errors/ForbiddenError';
import AccessDenied from '../../common/components/AccessDenied';

interface IState {
  voyages: IVoyage[];
  sharingGroups?: ISharingGroup[];
  selectedVoyageId: number | undefined;
}

class ManageBookingsContainer extends React.Component<
  RouteComponentProps & IWithApi
> {
  readonly state: IState = {
    voyages: [],
    sharingGroups: undefined,
    selectedVoyageId: undefined,
  };
  private _isMounted = false;
  private apiWrapper = this.props.api.wrapper;

  async componentDidMount() {
    this._isMounted = true;

    await this.loadVoyages();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async loadVoyages() {
    const voyages = await this.apiWrapper(VoyagesService.getVoyages());

    this.updateState({ voyages: voyages });
  }

  onSelectVoyage = async (voyageId: number) => {
    this.updateState({
      selectedVoyageId: voyageId,
      sharingGroups: undefined,
    });
  };

  getSharingGroupsForVoyage = async (voyageId: number) => {
    const sharingGroups = await this.apiWrapper(
      VoyagesService.getSharingGroups(voyageId)
    );
    this.updateState({ sharingGroups });
  };

  updateState(updatedState: Partial<IState>) {
    if (this._isMounted) {
      this.setState(updatedState);
    }
  }

  render() {
    const { error } = this.props.api;
    if (error instanceof NotFoundError) return <NotFound404 />;
    if (error instanceof ForbiddenError) return <AccessDenied />;

    const { voyages, sharingGroups, selectedVoyageId } = this.state;

    return (
      <ManageBookings
        voyages={voyages}
        sharingGroups={sharingGroups}
        voyageId={selectedVoyageId}
        getSharingGroupsForVoyage={this.getSharingGroupsForVoyage}
        onSelectVoyage={this.onSelectVoyage}
      />
    );
  }
}

export default withApi(withRouter(ManageBookingsContainer));
