import React, { useMemo } from 'react';
import { Form, Field } from 'react-final-form';
import { Grid, Container, makeStyles, Typography } from '@material-ui/core';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import { FORM_ERROR, ValidationErrors } from 'final-form';
import {
  FormTextField,
  FormSelectField,
  FormNumberField,
} from '../../common/forms';
import IVoyage from '../../common/types/IVoyage';
import IAllotmentForm from '../types/IAllotmentForm';
import styles from './AllotmentForm.styles';
import IAllotment from '../../common/types/IAllotment';
import FormDateField from '../../common/forms/FormDateField';
import IAllotmentAllocation from '../../common/types/IAllotmentAllocation';
import { getBrandOptions } from '../../pax/enums/brand';
import { useUser } from '../../common/contexts/UserContext';
import PageHeader from '../../common/components/PageHeader';
import pluralize from 'pluralize';
import FormButtons from '../../common/forms/FormButtons';
import { useHistory } from 'react-router';
import Routes from '../../common/constants/routes';
import FormAlerts from '../../common/forms/FormAlerts';
import { cabinCat, cabinCatSort } from '../../common/helpers/cabinCat';
import IAvailability from '../../common/types/IAvailability';

interface IProps {
  voyage: IVoyage;
  onSubmit: (allotmentForm: IAllotmentForm) => void;
  initialValues?: IAllotmentForm;
  defaultAllotment?: IAllotment;
  updateMode?: boolean;
}

const useStyles = makeStyles(styles);

const AllotmentAllocation: React.FC<{
  allocation: IAllotmentAllocation;
  voyage: IVoyage;
  defaultAllocations: IAllotmentAllocation[];
  isEditable: boolean;
  initialAllocation?: Omit<IAllotmentAllocation, 'availability'> & {
    availability?: IAvailability;
  };
  allocationKey: string;
}> = ({
  allocation,
  voyage,
  defaultAllocations,
  isEditable,
  initialAllocation,
  allocationKey,
}) => {
  const classes = useStyles();

  const cabinCategory = cabinCat(voyage)(allocation.cabinCategoryId);

  const unallocatedBerths =
    defaultAllocations?.find((a) => a.cabinCategoryId === cabinCategory?.id)
      ?.availability.unallocatedBerths ?? 0;

  let unallocatedCabins = cabinCategory
    ? unallocatedBerths / cabinCategory.cabinCapacity
    : 0;

  let allocatedCabins = 0;

  // adjust the available cabins when editing an allotment
  if (isEditable && initialAllocation) {
    unallocatedCabins += initialAllocation.cabinCount;

    const availableCabinsInAllocation =
      cabinCategory && initialAllocation.availability?.unallocatedBerths
        ? initialAllocation.availability.unallocatedBerths /
          cabinCategory.cabinCapacity
        : 0;

    allocatedCabins =
      initialAllocation.cabinCount - availableCabinsInAllocation;
  }

  return (
    <div className={classes.cabinCategory}>
      <div className={classes.cabinCategoryLabel}>
        {cabinCategory && (
          <React.Fragment>
            <Typography>{cabinCategory.name}</Typography>
            <Typography variant="subtitle2">{`${unallocatedCabins} ${pluralize(
              'cabin',
              unallocatedCabins
            )} available`}</Typography>
          </React.Fragment>
        )}
      </div>
      <FormNumberField
        name={`${allocationKey}.cabinCount`}
        label={'Number of Cabins'}
        required={true}
        initialValue={allocation.cabinCount}
        min={allocatedCabins}
        max={unallocatedCabins}
        className={classes.inputField}
        disabled={!isEditable || unallocatedCabins === 0}
      />
    </div>
  );
};

const AllotmentForm: React.FC<IProps> = ({
  voyage,
  onSubmit,
  initialValues,
  defaultAllotment,
  updateMode = false,
}) => {
  const classes = useStyles();
  const user = useUser();
  const history = useHistory();

  const validateForm = (
    formValues: IAllotmentForm
  ): ValidationErrors | undefined => {
    if (!formValues.data?.allocations?.some((x) => x.cabinCount > 0)) {
      return {
        [FORM_ERROR]: 'Allocate at least one cabin category for the allotment',
      };
    }
  };

  const defaultAllocations = defaultAllotment?.allocations ?? [];

  const newAllocations = useMemo<Partial<IAllotmentAllocation>[]>(
    () =>
      defaultAllocations
        .map((a) => ({
          cabinCategoryId: a.cabinCategoryId,
          cabinCount: 0,
        }))
        .sort((a, b) =>
          cabinCatSort(voyage)(a.cabinCategoryId, b.cabinCategoryId)
        ),
    [defaultAllocations, voyage]
  );

  const isEditable =
    !updateMode || (updateMode && !initialValues?.data?.defaultAllotment);

  const brandOpts = getBrandOptions(user.brands);

  const initial = useMemo(
    () =>
      ({
        data: {
          ...initialValues?.data,
          allocations: initialValues?.data.allocations ?? newAllocations,
        },
      } as IAllotmentForm),
    [initialValues, newAllocations]
  );

  return (
    <Form
      onSubmit={onSubmit}
      validate={validateForm}
      mutators={{
        ...arrayMutators,
      }}
      initialValues={initial}
      render={({
        handleSubmit,
        submitting,
        pristine,
        form,
        submitFailed,
        submitError,
        errors,
      }) => {
        return (
          <React.Fragment>
            <PageHeader
              title={updateMode ? 'Update Allotment' : 'Create Allotment'}
              description={
                updateMode ? 'Update an Allotment' : 'Create a new Allotment'
              }
              voyage={voyage}
            />
            <Container maxWidth="lg">
              <form onSubmit={handleSubmit}>
                <Grid
                  container
                  spacing={1}
                  alignItems="stretch"
                  direction="column"
                >
                  <Grid
                    item
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                    <Grid item xs="auto">
                      <FormTextField
                        name="data.name"
                        label="Name"
                        className={classes.inputField}
                        required={true}
                        maxLength={100}
                        disabled={!isEditable}
                      />
                    </Grid>
                    <Grid item xs="auto">
                      <FormSelectField
                        name="data.brand"
                        label="Brand"
                        options={brandOpts}
                        className={classes.inputField}
                        required={true}
                        disabled={brandOpts.length <= 1 || updateMode}
                        defaultValue={
                          brandOpts.length === 1 ? brandOpts[0].value : null
                        }
                      />
                    </Grid>
                    <Grid item xs="auto">
                      <FormDateField
                        name={`data.expiryDate`}
                        label="Expiry Date"
                        required={true}
                        disabled={!isEditable}
                        className={classes.inputField}
                      />
                    </Grid>
                    <Grid item xs="auto">
                      <FormTextField
                        name={`data.notes`}
                        label="Notes"
                        maxLength={500}
                        multiline={true}
                        rowsMax={4}
                        disabled={!isEditable}
                        className={classes.inputField}
                      />
                    </Grid>
                  </Grid>
                  <FieldArray
                    name={'data.allocations'}
                    defaultValue={newAllocations}
                  >
                    {({ fields }) => {
                      return fields.map((allocationKey) => (
                        <Grid item sm="auto" key={allocationKey}>
                          <Field<IAllotmentAllocation>
                            key={allocationKey}
                            name={`${allocationKey}`}
                          >
                            {({ input }) => (
                              <AllotmentAllocation
                                allocation={input.value}
                                voyage={voyage}
                                defaultAllocations={defaultAllocations}
                                isEditable={isEditable}
                                initialAllocation={initial.data.allocations?.find(
                                  (a) =>
                                    a.cabinCategoryId ===
                                    input.value.cabinCategoryId
                                )}
                                allocationKey={allocationKey}
                              />
                            )}
                          </Field>
                        </Grid>
                      ));
                    }}
                  </FieldArray>
                  <Grid item xs="auto">
                    {errors?.[FORM_ERROR] && !pristine && (
                      <FormAlerts
                        title="Please update the allotment."
                        alertType="warning"
                        alerts={errors[FORM_ERROR]}
                      />
                    )}
                    {submitFailed && submitError && (
                      <FormAlerts
                        title="Please update the allotment or refresh the page."
                        alertType="error"
                        alerts={submitError}
                      />
                    )}
                  </Grid>
                  <Grid item xs="auto">
                    <FormButtons
                      submit={{ disabled: submitting || pristine }}
                      reset={{
                        disabled: submitting || pristine,
                        onClick: () => form.reset(),
                      }}
                      cancel={{
                        onClick: () =>
                          history.push(
                            Routes.AllotmentManagement.getLocation(voyage.id)
                          ),
                      }}
                    />
                  </Grid>
                </Grid>
              </form>
            </Container>
          </React.Fragment>
        );
      }}
    />
  );
};

export default AllotmentForm;
