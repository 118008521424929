export enum PaxStatus {
  Option = 'option',
  Confirmed = 'confirmed',
  Cancelled = 'cancelled',
  ExpiredOption = 'expiredOption',
}
export const paxStatusLabels: Readonly<Record<PaxStatus, string>> = {
  [PaxStatus.Option]: 'Option',
  [PaxStatus.Confirmed]: 'Confirmed',
  [PaxStatus.Cancelled]: 'Cancelled',
  [PaxStatus.ExpiredOption]: 'Expired Option',
};
export const paxStatusOpts = Object.entries(paxStatusLabels).map(
  ([value, label]) => {
    return { value, label };
  }
);
export const getPaxStatusOptions = (
  statusOptions: Set<PaxStatus>
): Array<{ value: string; label: string }> => {
  return paxStatusOpts.filter((b) => statusOptions.has(b.value as PaxStatus));
};
export type TakingCapacity = PaxStatus.Confirmed | PaxStatus.Option;
export type NotTakingCapacity = Exclude<PaxStatus, TakingCapacity>;

export const isTakingCapacity = (
  status?: PaxStatus
): status is TakingCapacity => {
  return status === PaxStatus.Confirmed || status === PaxStatus.Option;
};
export default PaxStatus;
