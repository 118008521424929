import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { FormState, FORM_ERROR } from 'final-form';
import PaxService from '../../services/PaxService';
import IPaxExtraServicesForm, {
  PopulatePaxExtraServicesForm,
  ConvertPaxExtraServicesFormToApi,
} from '../types/IPaxExtraServicesForm';
import ISharingGroup from '../../common/types/ISharingGroup';
import UnprocessableEntity from '../../services/errors/UnprocessableEntity';
import IExtraService from '../../common/types/IExtraService';
import IPaxExtraService from '../../common/types/IPaxExtraService';
import PaxExtraServicesForm from './PaxExtraServicesForm';
import BadRequest from '../../services/errors/BadRequest';
import {
  IWithSnackbar,
  withSnackbar,
} from '../../common/contexts/SnackbarContext';
import VoyagesService from '../../services/VoyagesService';
import pluralize from 'pluralize';
import { withApi, IWithApi } from '../../common/contexts/ApiContext';
import { IWithUser, withUser } from '../../common/contexts/UserContext';

interface IState {
  initialValues?: IPaxExtraServicesForm;
  extraServices?: IExtraService[];
  paxExtraServices?: IPaxExtraService[];
}

interface IProps {
  sharingGroup: ISharingGroup;
  onChange: (state: FormState<IPaxExtraServicesForm>) => void;
  onSubmit: (
    paxExtraServices: IPaxExtraService[],
    extraServices: IExtraService[]
  ) => void;
  voyageId: number;
}

interface RouteParams {
  guid: string;
}

type Props = IProps &
  RouteComponentProps<RouteParams> &
  IWithSnackbar &
  IWithApi &
  IWithUser;

class PaxExtraServicesInsertUpdateFormContainer extends React.Component<
  Props,
  IState
> {
  readonly state: IState = {
    initialValues: undefined,
    extraServices: undefined,
    paxExtraServices: undefined,
  };

  private _isMounted = false;
  private apiWrapper = this.props.api.wrapper;

  async loadFormData(pes?: IPaxExtraService[]) {
    const { sharingGroup, voyageId } = this.props;

    const paxExtraServices =
      pes ??
      (await this.apiWrapper(
        PaxService.getPaxExtraServices(sharingGroup.groupId)
      ));

    const extraServices = await this.apiWrapper(
      VoyagesService.getExtraServices(voyageId)
    );
    this.updateState({ extraServices });

    const paxExtraServicesformValues = PopulatePaxExtraServicesForm(
      sharingGroup,
      paxExtraServices,
      extraServices
    );

    this.updateState({
      initialValues: paxExtraServicesformValues,
      paxExtraServices,
    });

    this.props.onSubmit(paxExtraServices, extraServices);
  }

  async componentDidMount() {
    this._isMounted = true;
    PaxService.setUser(this.props.user);
    this.loadFormData();
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    const { sharingGroup } = this.props;
    const { extraServices, paxExtraServices } = this.state;

    // Should find a better solution ("I'M A GENIUS!" - James Taranto)
    if (prevProps.sharingGroup.pax.length !== sharingGroup.pax.length) {
      const paxExtraServicesformValues = PopulatePaxExtraServicesForm(
        sharingGroup,
        paxExtraServices ?? [],
        extraServices ?? []
      );

      this.updateState({
        initialValues: paxExtraServicesformValues,
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  updateState(updatedState: Partial<IState>) {
    if (this._isMounted) {
      this.setState(updatedState);
    }
  }

  onSubmit = async (paxExtraServices: IPaxExtraServicesForm) => {
    const {
      match: { params },
    } = this.props;

    try {
      if (params.guid && paxExtraServices?.data) {
        const upsertedPes = await this.apiWrapper(
          PaxService.insertUpdatePaxExtraServices(
            params.guid,
            ConvertPaxExtraServicesFormToApi(paxExtraServices)
          )
        );

        this.props.snackbar.showMessage(
          `Extra ${pluralize(
            'Service',
            upsertedPes.length
          )} submitted successfully!`,
          { autoHideDuration: 8000, disableClickaway: true }
        );

        this.loadFormData(upsertedPes);
      }
    } catch (ex) {
      if (ex instanceof UnprocessableEntity || ex instanceof BadRequest) {
        return {
          [FORM_ERROR]: ex.errors,
        };
      }
    }
  };

  render() {
    const { sharingGroup, onChange } = this.props;
    const { extraServices, initialValues } = this.state;

    if (!sharingGroup) return null;

    return (
      <PaxExtraServicesForm
        extraServices={extraServices}
        sharingGroup={sharingGroup}
        onSubmit={this.onSubmit}
        initialValues={initialValues}
        onChange={onChange}
      />
    );
  }
}

export default withUser(
  withApi(withSnackbar(withRouter(PaxExtraServicesInsertUpdateFormContainer)))
);
