enum AllotmentStatus {
  Active = 'active',
  Released = 'released',
}

export const AllotmentStatusOptions: Array<{ value: string; label: string }> = [
  { value: AllotmentStatus.Active, label: 'Active' },
  { value: AllotmentStatus.Released, label: 'Released' },
];

export default AllotmentStatus;
