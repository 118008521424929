import React, { useState, useEffect } from 'react';
import { useApi } from '../../common/contexts/ApiContext';
import VoyagesService from '../../services/VoyagesService';
import PaxFormService from '../../services/PaxFormService';
import IVoyage from '../../common/types/IVoyage';
import PassengerDetails from './PassengerDetails';

import copy from 'copy-to-clipboard';
import { useSnackbar } from '../../common/contexts/SnackbarContext';
import ExternalUserType from '../../common/enums/ExternalUserType';

const PassengerDetailsContainer: React.FC = () => {
  const [voyages, setVoyages] = useState<IVoyage[]>([]);
  const [selectedVoyage, setSelectedVoyage] = useState<IVoyage | undefined>(
    undefined
  );

  const { wrapper } = useApi();
  const snackbar = useSnackbar();

  useEffect(() => {
    const loadVoyages = async () => {
      const loadedVoyages = await wrapper(VoyagesService.getVoyages());
      setVoyages(loadedVoyages);
    };
    loadVoyages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedVoyage(voyages.find((v) => v.id === +event.target.value));
  };

  const onDownloadCSV = async (event: React.MouseEvent<HTMLElement>) => {
    await wrapper(PaxFormService.downloadPassengerDetailsCSV(selectedVoyage!));
  };

  const onExternalUserGenerateDownloadLink = (
    externalUserType: ExternalUserType
  ) => async (_event: React.MouseEvent<HTMLElement>) => {
    selectedVoyage &&
      copy(
        await wrapper(
          PaxFormService.generateExternalUserDownloadLink(
            selectedVoyage.id,
            externalUserType
          )
        )
      );
    snackbar.showMessage('URL Generated and copied to the clipboard');
  };

  return (
    <PassengerDetails
      voyages={voyages}
      onChange={onChange}
      onDownloadCSV={onDownloadCSV}
      selectedVoyage={selectedVoyage}
      onExternalUserGenerateDownloadLink={onExternalUserGenerateDownloadLink}
    />
  );
};

export default PassengerDetailsContainer;
