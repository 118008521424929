import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Routes from '../../common/constants/routes';
import { useLocation } from 'react-router';
import { Container, Link, Typography } from '@material-ui/core';
import AllotmentHeader from './AllotmentHeader';
import AllotmentDetails from './AllotmentDetails';
import IAllotment from '../../common/types/IAllotment';
import ICabinCategory from '../../common/types/ICabinCategory';

const AllotmentSubmitted: React.FC = () => {
  const { state } = useLocation<{
    allotment: IAllotment;
    cabinCategories: ICabinCategory[];
    voyageId: number;
  }>();

  const { allotment, cabinCategories, voyageId } = state;

  return (
    <Container maxWidth="lg">
      <Typography>Allotment submitted successfully!</Typography>
      <Typography variant="h6" align="left">
        Summary:
      </Typography>

      <AllotmentHeader
        allotment={allotment}
        showStatus={false}
        showActionButtons={false}
        voyageId={voyageId}
      />
      <AllotmentDetails
        allotment={allotment}
        cabinCategories={cabinCategories}
      />

      <p>
        <Link
          component={RouterLink}
          to={Routes.AllotmentInsert.getLocation(voyageId)}
        >
          Create another allotment
        </Link>
      </p>
      <p>
        <Link
          component={RouterLink}
          to={Routes.AllotmentManagement.getLocation(voyageId)}
        >
          View voyage allotments
        </Link>
      </p>
    </Container>
  );
};

export default AllotmentSubmitted;
