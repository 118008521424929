import React, { useState, useMemo, useEffect } from 'react';
import { Form, FormSpy, FormRenderProps } from 'react-final-form';
import { Tabs, Tab, Container, makeStyles, Badge } from '@material-ui/core';
import arrayMutators from 'final-form-arrays';
import styles from './PaxListForm.styles';
import IVoyage from '../../../common/types/IVoyage';
import ICabinCategory from '../../../common/types/ICabinCategory';
import IPax from '../../../common/types/IPax';
import PaxStatus, { isTakingCapacity } from '../../enums/paxStatus';
import { FORM_ERROR } from 'final-form';
import IAllotment from '../../../common/types/IAllotment';
import Routes from '../../../common/constants/routes';
import { useHistory, useLocation } from 'react-router';
import calculatePaxAgeOnTravelDate from '../../helpers/calculatePaxAgeOnTravelDate';
import { ADULT_AGE } from '../../../common/constants/constants';
import ISharingGroup from '../../../common/types/ISharingGroup';
import PaxExtraServicesInsertUpdateFormContainer from '../PaxExtraServicesInsertUpdateFormContainer';
import TabPanel from '../../../common/components/TabPanel';
import FormAlerts from '../../../common/forms/FormAlerts';
import { useUser } from '../../../common/contexts/UserContext';
import PageHeader from '../../../common/components/PageHeader';
import UnsavedChangesPrompt from '../../../common/components/UnsavedChangesPrompt';
import OperationsPaxInsertUpdateFormContainer from '../SharingGroupOperationalInfoUpdateContainer';
import PaxAuditHistoryContainer from '../PaxAuditHistoryContainer';
import Role from '../../../common/enums/Role';
import FormButtons from '../../../common/forms/FormButtons';
import { useModal } from '../../../common/contexts/ModalContext';
import PaxConfirmationDialogContent from '../PaxConfirmationDialogContent';
import { cabinCat } from '../../../common/helpers/cabinCat';
import PaxListFormHeader from './PaxListFormHeader';
import PaxArray from './PaxArray';
import enumValueFromHash from '../../../common/helpers/enumValueFromHash';
import IPaxExtraServicesForm, {
  ConvertPaxExtraServicesFormToApi,
} from '../../types/IPaxExtraServicesForm';
import { isTakingCapacity as isExtraServiceTakingCapacity } from '../../enums/paxExtraServiceStatus';
import IPaxExtraService from '../../../common/types/IPaxExtraService';
import IExtraService from '../../../common/types/IExtraService';
import PaxService from '../../../services/PaxService';
import VoyagesService from '../../../services/VoyagesService';
import { useApi } from '../../../common/contexts/ApiContext';

export enum FormPageTab {
  Pax,
  ExtraServices,
  Operations,
  AuditHistory,
}

interface IProps {
  allotment: IAllotment;
  voyage: IVoyage;
  updateMode?: boolean;
  initialValues?: ISharingGroup;
  onSubmit: (paxDetails: ISharingGroup) => void;
  sharingGroup?: ISharingGroup;
}

const useStyles = makeStyles(styles);

const paxCount = (pax?: Array<Partial<Pick<IPax, 'status'>> | undefined>) =>
  pax?.filter((p) => p && isTakingCapacity(p.status)).length ?? 0;

const underagePaxFillCabin = ({
  departureDate,
}: Pick<IVoyage, 'departureDate'>) => (pax?: Partial<IPax>[]) => {
  if (pax) {
    const hasPax = pax.filter((p) => p).length > 0;

    const allPaxUnderage = pax?.every((pax) => {
      if (!pax) return;
      const age = calculatePaxAgeOnTravelDate(pax, departureDate);

      return age !== undefined && age < ADULT_AGE;
    });
    return hasPax && allPaxUnderage;
  }

  return false;
};

const getPaxThatChangedToStatus = (
  status: PaxStatus,
  pax: IPax[],
  initialValues?: Partial<ISharingGroup>
) => {
  const initialPax = ({ id }: Pick<Partial<IPax>, 'id'>) =>
    initialValues?.pax?.find((p) => p.id === id);

  return pax.filter(
    (p) => p.status === status && initialPax(p)?.status !== status
  );
};

const validate = (cabinCategories: ICabinCategory[]) => ({
  bookedCabinCategoryId,
  pax,
}: Pick<ISharingGroup, 'pax' | 'bookedCabinCategoryId'>) => {
  const cabinCategory = cabinCategories.find(
    (cc) => cc.id === bookedCabinCategoryId
  );
  return cabinCategory && paxCount(pax) > cabinCategory.cabinCapacity
    ? {
        [FORM_ERROR]: 'Number of passengers exceeds cabin capacity',
      }
    : undefined;
};

const PaxListForm: React.FC<IProps> = ({
  allotment,
  voyage,
  updateMode = false,
  initialValues,
  onSubmit,
  sharingGroup,
}) => {
  const { hash } = useLocation();
  const { wrapper } = useApi();
  const selectedTab = useMemo(
    () => enumValueFromHash(FormPageTab, FormPageTab.Pax)(hash),
    [hash]
  );

  const [paxExtraServices, setPaxExtraServices] = useState<
    IPaxExtraService[]
  >();
  const [extraServices, setExtraServices] = useState<IExtraService[]>();

  const setSelectedTab = (
    _event: React.ChangeEvent<{}>,
    newValue: FormPageTab
  ) => {
    history.replace({ ...history.location, hash: FormPageTab[newValue] });
  };

  const [dirtyForm, setDirtyForm] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [
    extraServicesTakingCapacityCount,
    setExtraServicesTakingCapacityCount,
  ] = useState(0);
  const user = useUser();
  const classes = useStyles();
  const cabinCategory = cabinCat(voyage)(initialValues?.cabinCategoryId);
  const history = useHistory();
  const modal = useModal();

  const outsideInitialValues = initialValues; // final form reset workaround

  const handleExtraServiceOnChange = ({
    dirty,
    values,
  }: Pick<FormRenderProps<IPaxExtraServicesForm>, 'dirty' | 'values'>) => {
    setDirtyForm(dirty);
    const extraServicesTakingCapacityCount = ConvertPaxExtraServicesFormToApi(
      values
    ).filter((pes) => pes.status && isExtraServiceTakingCapacity(pes.status))
      .length;
    setExtraServicesTakingCapacityCount(extraServicesTakingCapacityCount);
  };

  const loadPaxExtraServices = async () => {
    if (sharingGroup && sharingGroup.groupId) {
      setPaxExtraServices(
        await wrapper(PaxService.getPaxExtraServices(sharingGroup.groupId))
      );

      setExtraServices(
        await wrapper(VoyagesService.getExtraServices(voyage.id))
      );
    }
  };
  useEffect(() => {
    loadPaxExtraServices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sharingGroup?.groupId, voyage.id]);

  const handleExtraServiceSubmission = (
    paxExtraServices: IPaxExtraService[],
    extraServices: IExtraService[]
  ) => {
    setExtraServices(extraServices);
    setPaxExtraServices(paxExtraServices);
  };

  const handleOnSubmit = ({
    form,
    values,
    initialValues,
    invalid,
  }: Pick<
    FormRenderProps<ISharingGroup>,
    'form' | 'values' | 'initialValues' | 'invalid'
  >) => {
    if (!values?.pax || !initialValues) return;
    const confirmedPax = getPaxThatChangedToStatus(
      PaxStatus.Confirmed,
      values.pax,
      initialValues
    );
    const cancelledPax = getPaxThatChangedToStatus(
      PaxStatus.Cancelled,
      values.pax,
      initialValues
    );

    const onFormSubmit = async () => {
      await form.submit();
      await loadPaxExtraServices();
    };

    if ((confirmedPax.length === 0 && cancelledPax.length === 0) || invalid)
      return form.submit();

    modal.show({
      title: 'Submit',
      onOK: onFormSubmit,
      content: (
        <PaxConfirmationDialogContent
          paxConfirmed={confirmedPax}
          paxCancelled={cancelledPax}
        />
      ),
    });
  };

  return (
    <React.Fragment>
      <PageHeader
        title={updateMode ? 'Update Passengers' : 'Add Passengers'}
        description={[
          'Enter the details of the passengers you are adding to the selected cabin category.',
          'Adding passengers together indicates their desire to share a cabin',
          'Please confirm the sharing preferences prior to submitting.',
        ]}
        voyage={voyage}
        allotment={allotment}
      />
      <Container maxWidth="lg">
        <Form<ISharingGroup>
          onSubmit={onSubmit}
          validate={validate(voyage.cabinCategories)}
          mutators={{
            ...arrayMutators,
          }}
          initialValues={initialValues}
          render={({
            values,
            errors,
            form,
            submitting,
            submitFailed,
            submitError,
            dirtySinceLastSubmit,
            pristine,
            invalid,
            initialValues,
          }) => {
            return (
              <form>
                <FormSpy<ISharingGroup>
                  subscription={{
                    dirty: true,
                    submitting: true,
                  }}
                  onChange={({ dirty, submitting }) => {
                    setDirtyForm(dirty);
                    setSubmitting(submitting);
                  }}
                />
                <PaxListFormHeader
                  voyage={voyage}
                  allotment={allotment}
                  disabled={selectedTab !== FormPageTab.Pax}
                />
                <Tabs
                  value={selectedTab}
                  onChange={setSelectedTab}
                  aria-label="Allotment Details"
                  indicatorColor="primary"
                  textColor="primary"
                  className={classes.tabs}
                >
                  <Tab
                    label="Pax"
                    disabled={dirtyForm && selectedTab !== FormPageTab.Pax}
                  />
                  <Tab
                    label={
                      extraServicesTakingCapacityCount > 0 ? (
                        <Badge
                          badgeContent={extraServicesTakingCapacityCount}
                          color="primary"
                          className={classes.extraServiceTabBadge}
                        >
                          Extra Services
                        </Badge>
                      ) : (
                        <React.Fragment>Extra Services</React.Fragment>
                      )
                    }
                    disabled={
                      (dirtyForm || !updateMode) &&
                      selectedTab !== FormPageTab.ExtraServices
                    }
                  />
                  {user.hasRole(Role.OperationsManager) && (
                    <Tab
                      label="Ops"
                      disabled={
                        (dirtyForm || !updateMode) &&
                        selectedTab !== FormPageTab.Operations
                      }
                    />
                  )}
                  {user.hasRole(Role.OperationsManager) && (
                    <Tab
                      label="Audit"
                      disabled={
                        (dirtyForm || !updateMode) &&
                        selectedTab !== FormPageTab.AuditHistory
                      }
                    />
                  )}
                </Tabs>

                <TabPanel value={selectedTab} index={FormPageTab.Pax} justHide>
                  <PaxArray voyage={voyage} />

                  {underagePaxFillCabin(voyage)(values?.pax) && (
                    <FormAlerts
                      alerts="Passengers in this cabin do not meet the cabin configuration requirement due to their ages and must be approved to travel."
                      alertType="warning"
                    />
                  )}

                  {errors?.[FORM_ERROR] && (
                    <FormAlerts
                      title="Please update the cabin details."
                      alerts={errors[FORM_ERROR]}
                      alertType="error"
                    />
                  )}
                  {submitFailed && submitError && !dirtySinceLastSubmit && (
                    <FormAlerts alerts={submitError} alertType="error" />
                  )}

                  <FormButtons
                    submitButtonType="button"
                    submit={{
                      disabled:
                        pristine ||
                        submitting ||
                        (values?.pax?.length ?? 0) === 0,
                      onClick: () =>
                        handleOnSubmit({
                          form,
                          values,
                          initialValues,
                          invalid,
                        }),
                    }}
                    reset={{
                      disabled: submitting,
                      onClick: () => {
                        if (
                          !dirtyForm ||
                          window.confirm(
                            'You have unsaved changes, are you sure you want to reset the form?'
                          )
                        )
                          form.reset(outsideInitialValues);
                      },
                    }}
                    cancel={{
                      disabled: submitting,
                      onClick: () =>
                        history.push(Routes.CheckAvailability.route),
                    }}
                  />
                </TabPanel>
              </form>
            );
          }}
        />
        <TabPanel
          value={selectedTab}
          index={FormPageTab.ExtraServices}
          justHide
        >
          {updateMode && sharingGroup && (
            <PaxExtraServicesInsertUpdateFormContainer
              sharingGroup={sharingGroup}
              onChange={handleExtraServiceOnChange}
              onSubmit={handleExtraServiceSubmission}
              voyageId={voyage.id}
            />
          )}
        </TabPanel>
        {user.hasRole(Role.OperationsManager) && (
          <TabPanel value={selectedTab} index={FormPageTab.Operations} justHide>
            {sharingGroup && cabinCategory && (
              <OperationsPaxInsertUpdateFormContainer
                paxExtraServices={paxExtraServices ?? []}
                extraServices={extraServices ?? []}
                voyageId={voyage.id}
                sharingGroup={sharingGroup}
                onChange={({ dirty }) => setDirtyForm(dirty)}
                cabinCategory={cabinCategory}
              />
            )}
          </TabPanel>
        )}
        {user.hasRole(Role.OperationsManager) && (
          <TabPanel value={selectedTab} index={FormPageTab.AuditHistory}>
            {sharingGroup && updateMode && (
              <PaxAuditHistoryContainer
                sharingGroupGuid={sharingGroup.groupId}
              />
            )}
          </TabPanel>
        )}
      </Container>
      <UnsavedChangesPrompt
        when={dirtyForm && !submitting}
        message="You have unsaved changes, are you sure you want to leave the page?"
      />
    </React.Fragment>
  );
};

export default PaxListForm;
