import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useLocation, useHistory } from 'react-router';
import { FORM_ERROR } from 'final-form';
import AllotmentService from '../../services/AllotmentService';
import VoyagesService from '../../services/VoyagesService';
import PaxListForm from './PaxListForm';
import IVoyage from '../../common/types/IVoyage';
import Routes from '../../common/constants/routes';
import UnprocessableEntity from '../../services/errors/UnprocessableEntity';
import IAllotment from '../../common/types/IAllotment';
import BadRequest from '../../services/errors/BadRequest';
import { useSnackbar } from '../../common/contexts/SnackbarContext';
import { parseNumberQuery } from '../../common/helpers/parseQueryString';
import ISharingGroup from '../../common/types/ISharingGroup';
import pluralize from 'pluralize';
import NotFound404 from '../../common/components/NotFound404';
import ForbiddenError from '../../services/errors/ForbiddenError';
import NotFoundError from '../../services/errors/NotFoundError';
import AccessDenied from '../../common/components/AccessDenied';
import { useApi } from '../../common/contexts/ApiContext';
import { useUser } from '../../common/contexts/UserContext';

const PaxInsertFormContainer: React.FC = () => {
  const { voyageId, allotmentId } = useParams<{
    voyageId: string;
    allotmentId: string;
  }>();
  const { search } = useLocation();
  const [cabinCategoryId] = parseNumberQuery(search, ['cabinCategoryId']);

  const [voyage, setVoyage] = useState<IVoyage>();
  const [allotment, setAllotment] = useState<IAllotment>();

  const snackbar = useSnackbar();
  const { wrapper, error } = useApi();
  const user = useUser();
  const history = useHistory();

  const brands = useMemo(
    () =>
      user.brands.filter(
        (b) => allotment?.defaultAllotment || b === allotment?.brand
      ),
    [allotment, user.brands]
  );

  useEffect(() => {
    const load = async () => {
      VoyagesService.setUser(user);
      const [voyage, allotment] = await wrapper(
        Promise.all([
          VoyagesService.getVoyageById(+voyageId),
          AllotmentService.getAllotment(+allotmentId),
        ])
      );
      setVoyage(voyage);
      setAllotment(allotment);
    };
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialValues = useMemo(
    () =>
      ({
        bookedCabinCategoryId: voyage?.cabinCategories.find(
          (cc) => cc.id === cabinCategoryId
        )?.id,
        bookedAllotmentId: allotment?.id,
        brand: brands.length === 1 ? brands[0] : undefined,
      } as ISharingGroup),
    [allotment, cabinCategoryId, brands, voyage]
  );

  const submitPax = async (paxForm: ISharingGroup) => {
    try {
      const sharingGroup = paxForm;

      if (sharingGroup) {
        const insertedSharingGroup = await wrapper(
          AllotmentService.insertSharingGroup(
            sharingGroup.bookedAllotmentId,
            sharingGroup
          )
        );

        snackbar.showMessage(
          `${pluralize(
            'Passenger',
            insertedSharingGroup.pax.length
          )} submitted successfully!`,
          { autoHideDuration: 8000, disableClickaway: true }
        );

        history.push(
          Routes.PaxUpdate.getLocation(insertedSharingGroup.groupId)
        );
      }
    } catch (ex) {
      if (ex instanceof UnprocessableEntity || ex instanceof BadRequest) {
        return {
          [FORM_ERROR]: ex.errors,
        };
      }
    }
  };

  if (error instanceof NotFoundError) return <NotFound404 />;
  if (error instanceof ForbiddenError) return <AccessDenied />;

  if (!voyage || !allotment) return null;

  return (
    <PaxListForm
      initialValues={initialValues}
      onSubmit={submitPax}
      allotment={allotment}
      voyage={voyage}
    />
  );
};

export default PaxInsertFormContainer;
