import { createStyles } from '@material-ui/core/styles';

export default createStyles({
  voyageSelect: {
    width: '200px',
  },
  allotment: {
    marginBottom: '5px',
  },
  allotmentCreateButtonContainer: {
    overflow: 'auto',
  },
  allotmentCreateButton: {
    float: 'right',
    marginRight: '20px',
  },
  allotmentContainer: {
    margin: '20px 0px 20px',
  },
  subHeaderPaper: {
    width: '100%',
    textAlign: 'left',
    paddingLeft: '15px',
    paddingRight: '15px',
  },
});
