import BaseService from './BaseService';
import IVoyage from '../common/types/IVoyage';
import ISharingGroup from '../common/types/ISharingGroup';
import IAllotment from '../common/types/IAllotment';
import convertToUTCDate from './helpers/convertToUTCDate';
import ICabinAssignment from '../common/types/ICabinAssignment';
import IExtraService from '../common/types/IExtraService';
import ISharingGroupOperationalInfo from '../common/types/ISharingGroupOperationalInfo';

class VoyagesService extends BaseService {
  resourceBase = '/voyages';

  async getVoyageById(voyageId: number): Promise<IVoyage> {
    return await this.get(`${this.resourceBase}/${voyageId}`);
  }

  async getVoyages(): Promise<IVoyage[]> {
    return await this.get(this.resourceBase);
  }

  async getSharingGroups(voyageId: number): Promise<ISharingGroup[]> {
    return await this.get(`${this.resourceBase}/${voyageId}/pax`);
  }

  async getSharingGroupOperationalInfo(
    voyageId: number
  ): Promise<ISharingGroupOperationalInfo[]> {
    return await this.get(
      `${this.resourceBase}/${voyageId}/paxOperationalInfo`
    );
  }

  async getAllotmentsByVoyage(voyageId: number): Promise<IAllotment[]> {
    return await this.get(`${this.resourceBase}/${voyageId}/allotments`);
  }

  async insertAllotment(
    voyageId: number,
    allotment: IAllotment
  ): Promise<IAllotment> {
    return await this.post(
      `${this.resourceBase}/${voyageId}/allotments`,
      this.convertAllotmentForApi(allotment)
    );
  }

  async updateAllotment(
    voyageId: number,
    allotmentId: number,
    allotment: IAllotment
  ): Promise<IAllotment> {
    return await this.put(
      `${this.resourceBase}/${voyageId}/allotments/${allotmentId}`,
      this.convertAllotmentForApi(allotment)
    );
  }

  async assignCabins(
    voyageId: number,
    cabinAssignments: ICabinAssignment[]
  ): Promise<IAllotment> {
    return await this.post(
      `${this.resourceBase}/${voyageId}/cabinAssignment`,
      cabinAssignments
    );
  }

  async getExtraService(
    voyageId: number,
    extraServiceId: number
  ): Promise<IExtraService> {
    return await this.get(
      `${this.resourceBase}/${voyageId}/extraService/${extraServiceId}`
    );
  }

  async getExtraServices(voyageId: number): Promise<IExtraService[]> {
    return await this.get(`${this.resourceBase}/${voyageId}/extraService`);
  }

  private convertAllotmentForApi(allotment: IAllotment) {
    return {
      ...allotment,
      expiryDate: convertToUTCDate(allotment.expiryDate),
    };
  }
}

const service = new VoyagesService();

export default service;
