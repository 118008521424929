import React, { useMemo } from 'react';
import { Paper, Tabs, Tab, Container } from '@material-ui/core';
import TabPanel from '../../common/components/TabPanel';
import PaxOverviewContainer from './PaxOverviewContainer';
import ExtraServiceOverviewContainer from './ExtraServiceOverviewContainer';
import { useLocation, useHistory } from 'react-router';
import enumValueFromHash from '../../common/helpers/enumValueFromHash';

enum OverviewPageTab {
  Pax,
  ExtraServices,
}

const OverviewPage: React.FC = () => {
  const { hash } = useLocation();
  const history = useHistory();

  const tabFromHash = useMemo(
    () => enumValueFromHash(OverviewPageTab, OverviewPageTab.Pax)(hash),
    [hash]
  );

  const handleChange = (
    _event: React.ChangeEvent<{}>,
    newValue: OverviewPageTab
  ) => {
    history.replace({ ...history.location, hash: OverviewPageTab[newValue] });
  };

  return (
    <React.Fragment>
      <Container maxWidth="xl">
        <Paper>
          <Tabs value={tabFromHash} onChange={handleChange} centered>
            <Tab label="Pax" />
            <Tab label="Extra Services" />
          </Tabs>
        </Paper>
      </Container>
      <TabPanel value={tabFromHash} index={OverviewPageTab.Pax}>
        <PaxOverviewContainer />
      </TabPanel>
      <TabPanel value={tabFromHash} index={OverviewPageTab.ExtraServices}>
        <ExtraServiceOverviewContainer />
      </TabPanel>
    </React.Fragment>
  );
};

export default OverviewPage;
