import React, { ReactNode } from 'react';
import { Field } from 'react-final-form';
import { KeyboardDatePicker } from '@material-ui/pickers';
import {
  isValidDatePickerFormat,
  isBeforeDate,
  isPopulated,
} from '../../common/forms/validators';
import styles from './FormDateField.styles';
import env from '../../core/env';
import setCurrentTimeToDate from '../../services/helpers/setCurrentTimeToDate';
import Warning from '../../common/forms/Warning';
import TooltipIcon from '../components/TooltipIcon';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { makeStyles } from '@material-ui/core';

interface IProps {
  name: string;
  label?: string;
  initialValue?: Date | null;
  required?: boolean;
  disabled?: boolean;
  disableFuture?: boolean;
  className?: string;
  hasWarning?: boolean;
  warningText?: string;
  toolTip?: ReactNode;
  disablePast?: boolean;
  initialFocusedDate?: ParsableDate;
  defaultValue?: Date;
}

const useStyles = makeStyles(styles);

const FormDateField: React.FC<IProps> = ({
  name,
  label,
  initialValue = null,
  required = false,
  disabled = false,
  disableFuture = false,
  className,
  hasWarning = false,
  warningText = '',
  toolTip,
  disablePast = false,
  initialFocusedDate,
  defaultValue, // https://github.com/mui-org/material-ui-pickers/issues/1544
}) => {
  const classes = useStyles();

  return (
    <TooltipIcon title={toolTip}>
      <Field<Date | null>
        name={name}
        allowNull
        parse={setCurrentTimeToDate}
        initialValue={initialValue}
        defaultValue={defaultValue}
        validate={(value) => validate(value, { required, disableFuture })}
      >
        {({ input, meta }) => (
          <KeyboardDatePicker
            {...input}
            initialFocusedDate={initialFocusedDate}
            id={name}
            variant="inline"
            label={label}
            format={env.REACT_APP_DATE_FORMAT}
            className={`${classes.datePicker} ${className}`}
            margin="normal"
            required={required}
            disabled={disabled}
            disableFuture={disableFuture}
            disablePast={disablePast}
            placeholder={env.REACT_APP_DATE_FORMAT}
            refuse={new RegExp(/\W/, 'gim')}
            error={meta.touched && meta.error !== undefined}
            helperText={
              (meta.touched && meta.error && (
                <React.Fragment>{meta.error}</React.Fragment>
              )) ||
              (hasWarning && <Warning>{warningText}</Warning>)
            }
          />
        )}
      </Field>
    </TooltipIcon>
  );
};

function validate(
  value: Date | null,
  props: Pick<IProps, 'required' | 'disableFuture'>
) {
  let error = undefined;

  if (!error) {
    error = isValidDatePickerFormat(value);
  }

  if (!error && props.required) {
    error = isPopulated(value);
  }

  if (!error && props.disableFuture) {
    error = isBeforeDate(value, new Date());
  }

  return error;
}

export default FormDateField;
