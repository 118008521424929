import { createStyles } from '@material-ui/core/styles';

export default createStyles({
  tableTitle: {
    flexGrow: 1,
    display: 'block',
    textAlign: 'left',
  },
  tableHeaderCell: {
    verticalAlign: 'bottom',
  },
});
