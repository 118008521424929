import React from 'react';
import IVoyage from '../../common/types/IVoyage';
import { uniq, remove } from 'lodash';
import {
  withStyles,
  WithStyles,
  Typography,
  Button,
  Paper,
  Grid,
  Tooltip,
} from '@material-ui/core';
import styles, { transitionDuration } from './CabinAssignment.styles';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  DraggableLocation,
  DroppableId,
} from 'react-beautiful-dnd';
import ISharingGroup from '../../common/types/ISharingGroup';
import IPax from '../../common/types/IPax';
import ICabin from '../../common/types/ICabin';
import ICabinAssignment from '../../common/types/ICabinAssignment';
import CabinCategorySelect from '../../common/components/CabinCategorySelect';
import classNames from 'classnames';
import IAllotment from '../../common/types/IAllotment';
import { withRouter, RouteComponentProps } from 'react-router';
import ICabinCategory from '../../common/types/ICabinCategory';
import { lightBlue, lightGreen, grey } from '@material-ui/core/colors';
import CabinAssignmentPaxDetails from './CabinAssignmentPaxDetails';
import Transition, {
  UNMOUNTED,
  EXITED,
  ENTERING,
  ENTERED,
  EXITING,
} from 'react-transition-group/Transition';
import PageHeader from '../../common/components/PageHeader';
import { isTakingCapacity } from '../../pax/enums/paxStatus';
import getCabinDetailsText from '../../common/helpers/getCabinDetailsText';
import ISharingGroupCombined from '../../common/types/ISharingGroupCombined';
import AnnouncementIcon from '@material-ui/icons/Announcement';

interface IDragAndDropItem extends ICabinAssignment {
  content: ISharingGroupCombined;
}

interface IState {
  dragAndDropSourceItems: IDragAndDropItem[];
  dragAndDropDestinationItems: IDragAndDropItem[];
  changeMade: boolean;
}

interface IProps {
  voyage: IVoyage;
  voyageAllotments: IAllotment[];
  onSelectCabinCategory: (cabinCategoryId: number) => Promise<void>;
  onCabinConfigurationSave: (cabinConfigurations: ICabinAssignment[]) => void;
  selectedCabinCategoryId?: number;
  enhancedSharingGroups: ISharingGroupCombined[];
}

type Props = IProps & WithStyles<typeof styles> & RouteComponentProps;

const isCabinDroppableSection = (sectionName: string): boolean => {
  return sectionName.includes('cabin');
};

const getCabinIdFromDroppableSectionName = (sectionName: string): number => {
  return +sectionName.split('-')[1];
};

const reorder = (
  droppableSection: DraggableLocation,
  list: IDragAndDropItem[],
  startIndex: number,
  endIndex: number
) => {
  let result: IDragAndDropItem[] = Object.assign([], list);

  // Cabin reorder
  if (isCabinDroppableSection(droppableSection.droppableId)) {
    const cabinId = getCabinIdFromDroppableSectionName(
      droppableSection.droppableId
    );

    const cabinItems = result.filter((i) => i.cabinId! === cabinId);

    const [removed] = cabinItems.splice(startIndex, 1);
    cabinItems.splice(endIndex, 0, removed);

    // Delete all items and insert at the end
    result = remove(result, (item) => {
      return item.cabinId !== cabinId;
    });

    result.splice(result.length, cabinItems.length, ...cabinItems);
  } else {
    // sharing groups reorder
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  }

  return result;
};

const move = (
  source: IDragAndDropItem[],
  destination: IDragAndDropItem[],
  droppableSource: DraggableLocation,
  droppableDestination: DraggableLocation
) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);

  // Calculate index of the item removed (cabin if not is from the pax)
  let removeIndex: number = 0;
  if (isCabinDroppableSection(droppableSource.droppableId)) {
    const cabinId = getCabinIdFromDroppableSectionName(
      droppableSource.droppableId
    );
    removeIndex =
      sourceClone.findIndex((x) => x.cabinId === cabinId) +
      droppableSource.index;
  } else {
    removeIndex = droppableSource.index;
  }

  // Remove item from the source
  const [removed] = sourceClone.splice(removeIndex, 1);

  // Remove the cabin ID if sharing group moved to the pax of assigned if moved to the cabins section
  if (droppableDestination.droppableId === 'sharingGroupSection') {
    removed.cabinId = undefined;
  } else {
    const cabinId = getCabinIdFromDroppableSectionName(
      droppableDestination.droppableId
    );
    removed.cabinId = cabinId;
  }

  // Insert the record (in the position of the cabin or in the pax section)
  if (isCabinDroppableSection(droppableDestination.droppableId)) {
    const cabinId = getCabinIdFromDroppableSectionName(
      droppableDestination.droppableId
    );

    const cabinItems = destClone.filter((i) => i.cabinId! === cabinId);
    cabinItems.splice(droppableDestination.index, 0, removed);

    const firstCabinItemIndex = destClone.findIndex(
      (c) => c.cabinId === cabinId
    );

    destClone.splice(
      firstCabinItemIndex === -1
        ? destClone.length
        : firstCabinItemIndex + droppableDestination.index,
      0,
      removed
    );
  } else {
    destClone.splice(droppableDestination.index, 0, removed);
  }

  const result: { [k: string]: any } = {};

  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const getListStyle = (
  isDraggingOver: boolean,
  droppableSectionId: DroppableId,
  isCabinFull: boolean = false
) => ({
  background: isDraggingOver
    ? lightBlue[200]
    : isCabinFull
    ? lightGreen[200]
    : grey[200],
  padding: 8,
  width: 320,
  minHeight: '2em',
});

const getDragAndDropItemsFromSharingGroups = (
  enhancedSharingGroups: ISharingGroupCombined[],
  cabinCategoryId?: number
): IDragAndDropItem[] => {
  const filteredSharingGroups = !cabinCategoryId
    ? enhancedSharingGroups
    : enhancedSharingGroups.filter(
        (x) => x.sharingGroup.cabinCategoryId === cabinCategoryId
      );

  return filteredSharingGroups.map((k) => ({
    groupId: k.sharingGroup.groupId,
    cabinId: k.sharingGroup.assignedCabinId,
    content: k,
  }));
};

class CabinAssignment extends React.Component<Props, IState> {
  readonly state: IState = {
    dragAndDropSourceItems: [],
    dragAndDropDestinationItems: [],
    changeMade: false,
  };

  componentDidMount() {
    const { selectedCabinCategoryId } = this.props;

    if (selectedCabinCategoryId) {
      this.refreshData(selectedCabinCategoryId);
    }
  }

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({
        dragAndDropSourceItems: [],
        dragAndDropDestinationItems: [],
      });
    }
  }

  private saveCabinConfiguration = async (e: any) => {
    const { enhancedSharingGroups, selectedCabinCategoryId } = this.props;

    // Get the values to send
    let cabinAssignments: ICabinAssignment[] = this.state.dragAndDropDestinationItems.map(
      (item) => {
        return { groupId: item.groupId, cabinId: item.cabinId };
      }
    );

    const originalSharingGroupsWithCabin = enhancedSharingGroups.filter(
      (sg) =>
        sg.sharingGroup.assignedCabinId &&
        sg.sharingGroup.cabinCategoryId === selectedCabinCategoryId
    );

    // Include in request Removed pax from cabin
    const originalSharingGroupsWithNoCabin = originalSharingGroupsWithCabin.filter(
      (sg) =>
        !cabinAssignments.some((ca) => ca.groupId === sg.sharingGroup.groupId)
    );

    if (originalSharingGroupsWithNoCabin.length > 0) {
      cabinAssignments.push.apply(
        cabinAssignments,
        originalSharingGroupsWithNoCabin.map((sg) => ({
          groupId: sg.sharingGroup.groupId,
        }))
      );
    }

    // Remove sharing groups assigned to a cabin that did not change
    remove(cabinAssignments, (ca) => {
      return originalSharingGroupsWithCabin.find(
        (sg) =>
          sg.sharingGroup.groupId === ca.groupId &&
          sg.sharingGroup.assignedCabinId === ca.cabinId
      );
    });

    // Save
    await this.props.onCabinConfigurationSave(cabinAssignments);
    this.setState({ changeMade: false });
  };

  private handleChangeCabinCategory = async (e: any) => {
    const cabinCategoryId = e.target.value;

    if (this.props.selectedCabinCategoryId === cabinCategoryId) {
      return;
    }

    if (
      !this.state.changeMade ||
      !this.props.selectedCabinCategoryId ||
      window.confirm(
        `Are you sure you want change the cabin category, your current cabin configuration won't be saved?`
      )
    ) {
      this.refreshData(cabinCategoryId);
      this.props.onSelectCabinCategory(cabinCategoryId);
    }
  };

  private refreshData(cabinCategoryId?: number) {
    const { voyage, enhancedSharingGroups } = this.props;
    const cabins = voyage.cabinCategories.flatMap((c) => c.cabins);

    // Fill Source items
    const dragAndDropSourceItems = getDragAndDropItemsFromSharingGroups(
      enhancedSharingGroups
        .filter((s) => !s.sharingGroup.assignedCabinId)
        .sort((a, b) => {
          const sortEndValue = '999999';

          const cabinNumber = (sg: ISharingGroupCombined) =>
            cabins.find((c) => c.id === sg.operationalInfo.requestedCabinId)
              ?.cabinNumber ?? sortEndValue;

          return cabinNumber(a).localeCompare(cabinNumber(b), 'en', {
            numeric: true,
          });
        }),
      cabinCategoryId
    );
    // Fill Destination Items
    const dragAndDropDestinationItems = getDragAndDropItemsFromSharingGroups(
      enhancedSharingGroups.filter((s) => s.sharingGroup.assignedCabinId),
      cabinCategoryId
    );

    this.setState({
      dragAndDropSourceItems: dragAndDropSourceItems,
      dragAndDropDestinationItems: dragAndDropDestinationItems,
      changeMade: false,
    });
  }

  private getList = (dragAndDropSection: string): IDragAndDropItem[] => {
    if (dragAndDropSection === 'sharingGroupSection')
      return this.state.dragAndDropSourceItems;
    return this.state.dragAndDropDestinationItems;
  };

  private validateSingleSuppCapacity = (
    cabinSharingGroups: ISharingGroup[]
  ) => {
    // Validate Single Sup
    if (
      cabinSharingGroups.length > 1 &&
      cabinSharingGroups.some((sg) => sg.singleSupplement)
    ) {
      alert(
        `The passengers can't be assigned to the cabin because the sharing group has single supplement`
      );
      return false;
    }

    return true;
  };

  private validateMixedGenders = (cabinSharingGroups: ISharingGroup[]) => {
    // Validate Not mixed genders allowed
    const uniqueGenders = uniq(
      cabinSharingGroups.flatMap((sg) =>
        sg.pax
          .filter((p) => isTakingCapacity(p.status))
          .flatMap((p) => p.gender)
      )
    );

    if (cabinSharingGroups.length > 1 && uniqueGenders.length > 1) {
      alert(
        `The passengers to be assigned have mixed genders, the sharing group can't be assigned to the cabin`
      );
      return false;
    }

    return true;
  };

  private validateCabinCapacity = (cabinSharingGroups: ISharingGroup[]) => {
    const cabinCapacity = this.getSelectedCabinCategoryCabinCapacity();

    const paxInCabin = this.getPaxInCabin(cabinSharingGroups);

    if (paxInCabin.length > cabinCapacity) {
      alert(
        `You can't assign a total of ${
          paxInCabin.length
        } passengers to the cabin, the capacity is ${
          this.getSelectedCabinCategory()?.cabinCapacity
        }`
      );
      return false;
    }

    return true;
  };

  private validateCabinAssignment = (
    sharingGroupsInCabin: ISharingGroup[],
    movedSharingGroup: ISharingGroup
  ) => {
    const cabinSharingGroups = [...sharingGroupsInCabin, movedSharingGroup];
    const rules = [
      this.validateSingleSuppCapacity,
      this.validateMixedGenders,
      this.validateCabinCapacity,
    ];

    return !rules.some((rule) => !rule(cabinSharingGroups));
  };

  private getSelectedCabinCategory = (): ICabinCategory => {
    const { voyage } = this.props;

    return voyage.cabinCategories.find(
      (cc) => cc.id === this.props.selectedCabinCategoryId
    )!;
  };

  private getSelectedCabinCategoryCabinCapacity = (): number => {
    const selectedCabinCategory = this.getSelectedCabinCategory();

    return selectedCabinCategory?.cabinCapacity ?? 0;
  };

  private getPaxInCabin = (cabinSharingGroups: ISharingGroup[]): IPax[] => {
    return cabinSharingGroups
      .flatMap((sg) => sg.pax)
      .filter((p) => isTakingCapacity(p.status));
  };

  private onDragEnd = (result: DropResult) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    // Can move sharing groups between cabins
    if (
      isCabinDroppableSection(source.droppableId) &&
      isCabinDroppableSection(destination.droppableId) &&
      source.droppableId !== destination.droppableId
    ) {
      alert(
        `You can't move passengers between cabins but you can bring it back to the passengers section and drag and drop it again`
      );
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        source,
        this.getList(source.droppableId),
        source.index,
        destination.index
      );

      if (isCabinDroppableSection(source.droppableId)) {
        this.setState({ dragAndDropDestinationItems: items });
        return;
      }

      this.setState({ dragAndDropSourceItems: items });
    } else {
      const sourceItems = this.getList(source.droppableId);
      const destinationItems = this.getList(destination.droppableId);

      if (isCabinDroppableSection(destination.droppableId)) {
        const cabinId = getCabinIdFromDroppableSectionName(
          destination.droppableId
        );

        // Sharing groups currently in the cabin
        const sharingGroupsInCabin = destinationItems
          .filter((m) => m.cabinId === cabinId)
          .map((i) => i.content.sharingGroup);

        // New sharing group to add
        const movedSharingGroup =
          sourceItems[source.index].content.sharingGroup;

        // Validate and stop execution if invalid
        if (
          !this.validateCabinAssignment(sharingGroupsInCabin, movedSharingGroup)
        ) {
          return;
        }
      }

      const result = move(sourceItems, destinationItems, source, destination);

      const cabinSections = Object.keys(result).reduce(
        (object: { [key: string]: IDragAndDropItem }, key) => {
          if (key !== 'sharingGroupSection') {
            object[key] = result[key];
          }
          return object;
        },
        {}
      );

      this.setState({
        dragAndDropSourceItems: result.sharingGroupSection,
        dragAndDropDestinationItems: ([] as IDragAndDropItem[]).concat(
          ...Object.values(cabinSections)
        ),
        changeMade: true,
      });
    }
  };

  private renderSharingGroup = (
    item: IDragAndDropItem,
    index: number,
    selectedVoyage: IVoyage,
    cabinCategoryId: number
  ) => {
    const { voyageAllotments, classes } = this.props;

    const pax = item.content.sharingGroup.pax.filter((p) =>
      isTakingCapacity(p.status)
    );

    if (pax.length === 0) return null;

    const cabins = selectedVoyage.cabinCategories.find(
      (cc) => cc.id === cabinCategoryId
    )!.cabins;

    const originalAllotment = voyageAllotments.find(
      (a) => a.id === item.content.sharingGroup.bookedAllotmentId
    )!;

    return (
      <Draggable draggableId={item.groupId} index={index}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <Transition in={snapshot.isDragging} timeout={transitionDuration}>
              {(state) => {
                const paxDetailsClasses = classNames({
                  [classes.cabinAssignmentPaxDetails]: true,
                  [classes.cabinAssignmentPaxDetailsEntering]:
                    state === ENTERING,
                  [classes.cabinAssignmentPaxDetailsEntered]: state === ENTERED,
                  [classes.cabinAssignmentPaxDetailsExiting]: state === EXITING,
                  [classes.cabinAssignmentPaxDetailsExited]: state === EXITED,
                  [classes.cabinAssignmentPaxDetailsUnmounted]:
                    state === UNMOUNTED,
                });
                return (
                  <CabinAssignmentPaxDetails
                    className={paxDetailsClasses}
                    sharingGroup={item.content}
                    allotment={originalAllotment}
                    cabins={cabins}
                  />
                );
              }}
            </Transition>
          </div>
        )}
      </Draggable>
    );
  };

  private renderSharingGroupList = (
    voyage: IVoyage,
    cabinCategoryId: number
  ) => {
    const { classes } = this.props;

    return (
      <div className={classes.dragAndDropSectionContainer}>
        <React.Fragment>
          <Typography variant="h5">Passengers</Typography>
          <Paper>
            <Droppable droppableId="sharingGroupSection">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={getListStyle(
                    snapshot.isDraggingOver,
                    provided.droppableProps['data-rbd-droppable-id']
                  )}
                >
                  <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="stretch"
                    spacing={1}
                  >
                    {this.state.dragAndDropSourceItems.map(
                      (item: IDragAndDropItem, index) => {
                        const sharingGroup = this.renderSharingGroup(
                          item,
                          index,
                          voyage,
                          cabinCategoryId
                        );
                        if (sharingGroup)
                          return (
                            <Grid key={item.groupId} item>
                              {sharingGroup}
                            </Grid>
                          );
                        else return null;
                      }
                    )}
                  </Grid>
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </Paper>
        </React.Fragment>
      </div>
    );
  };

  private renderCabinList = (
    voyage: IVoyage,
    cabinCategoryId: number,
    cabins: ICabin[] | undefined
  ) => {
    const { classes } = this.props;

    return (
      <div className={classes.dragAndDropSectionContainer}>
        <React.Fragment>
          <Typography variant="h5">Cabins</Typography>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="stretch"
            spacing={2}
          >
            {cabins?.map((c) => {
              const sharingGroups = this.state.dragAndDropDestinationItems
                .filter((i) => i.cabinId === c.id)
                .flatMap((n) => n.content.sharingGroup);

              const isCabinFull =
                this.getPaxInCabin(sharingGroups).length ===
                  this.getSelectedCabinCategoryCabinCapacity() ||
                sharingGroups.some((s) => s.singleSupplement);

              return (
                <Grid item key={c.cabinNumber}>
                  <Paper>
                    <Droppable droppableId={`cabin-${c.id}`}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          style={getListStyle(
                            snapshot.isDraggingOver,
                            provided.droppableProps['data-rbd-droppable-id'],
                            isCabinFull
                          )}
                        >
                          <Typography variant="subtitle2">
                            {c.cabinNumber}
                          </Typography>

                          <Typography
                            variant="subtitle2"
                            className={classes.cabinDetailsTypography}
                          >
                            {getCabinDetailsText(c)}
                          </Typography>

                          <Grid
                            container
                            direction="column"
                            justify="center"
                            alignItems="stretch"
                            spacing={1}
                          >
                            {this.state.dragAndDropDestinationItems
                              .filter((i) => i.cabinId === c.id)
                              .map((item: IDragAndDropItem, index) => (
                                <Grid item key={item.groupId}>
                                  {this.renderSharingGroup(
                                    item,
                                    index,
                                    voyage,
                                    cabinCategoryId
                                  )}
                                </Grid>
                              ))}
                            {provided.placeholder}
                          </Grid>
                        </div>
                      )}
                    </Droppable>
                  </Paper>
                </Grid>
              );
            })}
          </Grid>
        </React.Fragment>
      </div>
    );
  };

  render() {
    const {
      voyage,
      selectedCabinCategoryId,
      voyageAllotments,
      classes,
    } = this.props;

    const defaultAllotment = voyageAllotments.find((v) => v.defaultAllotment)!;

    const allocation = defaultAllotment.allocations.find(
      (a) => a.cabinCategoryId === selectedCabinCategoryId
    );

    const selectedCabins = voyage.cabinCategories
      .find((cc) => cc.id === selectedCabinCategoryId)
      ?.cabins.sort((a, b) =>
        a.cabinNumber.localeCompare(b.cabinNumber, 'en', {
          numeric: true,
        })
      );

    return (
      <React.Fragment>
        <PageHeader
          title="Assign Cabins"
          description="Assign passengers to the cabin they will occupy during the voyage."
          voyage={voyage}
        />
        <div className={classes.selectFieldsContainer}>
          <div>
            <CabinCategorySelect
              selectedCabinCategoryId={selectedCabinCategoryId}
              cabinCategories={voyage.cabinCategories}
              onChange={this.handleChangeCabinCategory}
            />
            {allocation && !allocation.availability.isOptimalAvailability && (
              <React.Fragment>
                <Tooltip title="Cabin assignment is not in an optimal state.">
                  <AnnouncementIcon className={classes.announcementIcon} />
                </Tooltip>
              </React.Fragment>
            )}
          </div>
        </div>
        <div
          className={classNames({
            [classes.disabledDragAndDrop]: !selectedCabinCategoryId,
          })}
        >
          {selectedCabinCategoryId && (
            <div className={classes.dragAndDropContainer}>
              <DragDropContext onDragEnd={this.onDragEnd}>
                {this.renderSharingGroupList(voyage, selectedCabinCategoryId)}
                {this.renderCabinList(
                  voyage,
                  selectedCabinCategoryId,
                  selectedCabins
                )}
              </DragDropContext>
            </div>
          )}
        </div>
        <div>
          <Button
            size="small"
            variant="contained"
            color="primary"
            disabled={!selectedCabinCategoryId}
            onClick={this.saveCabinConfiguration}
            className={classes.saveButton}
          >
            Save Cabins
          </Button>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(withStyles(styles)(CabinAssignment));
