export default function isBelowMaxLength(
  value: number | null | undefined,
  min: number
) {
  if (value !== undefined && value !== null && value < min) {
    return `Value must be ${min} or more`;
  }

  return undefined;
}
