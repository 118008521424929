import { createStyles } from '@material-ui/core/styles';

export default createStyles({
  search: {
    position: 'relative',
    borderRadius: '3px',
    backgroundColor: '#0000000F',
    '&:hover': {
      backgroundColor: '#00000019',
    },
    marginLeft: '4px',
    width: 'auto',
  },
  searchIcon: {
    padding: '0 8px',
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: '8px, 8px, 8px, 0',
    paddingLeft: 'calc(1em + 24px)',
    transition: 'width 300ms',
    width: '12ch',
    '&:focus': {
      width: '20ch',
    },
  },
});
