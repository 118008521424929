import React, { useState } from 'react';
import IPaxOperationalInfoForm from '../types/IPaxOperationalInfoForm';
import { FormState } from 'final-form';
import { Form, FormSpy } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { Grid, Container } from '@material-ui/core';
import IPax from '../../common/types/IPax';
import PaxOperationalInfo from './PaxOperationalInfo';
import { FieldArray } from 'react-final-form-arrays';
import FormSelectAutoCompleteField from '../../common/forms/FormSelectAutoCompleteField';
import ICabinCategory from '../../common/types/ICabinCategory';
import Routes from '../../common/constants/routes';
import { useHistory } from 'react-router';
import styles from './SharingGroupOperationalInfoForm.styles';
import { makeStyles } from '@material-ui/styles';
import FormButtons from '../../common/forms/FormButtons';
import IPaxOperationalInfo from '../../common/types/IPaxOperationalInfo';
import Brand from '../enums/brand';
import IPaxExtraService from '../../common/types/IPaxExtraService';
import IExtraService from '../../common/types/IExtraService';

const useStyles = makeStyles(styles);

interface IProps {
  initialValues?: IPaxOperationalInfoForm;
  pax: IPax[];
  onChange: (state: FormState<IPaxOperationalInfoForm>) => void;
  onSubmit: (paxOperationalInfo: IPaxOperationalInfoForm) => void;
  cabinCategory: ICabinCategory;
  paxExtraServices: IPaxExtraService[];
  extraServices: IExtraService[];
  brand: Brand;
  voyageId: number;
}

const SharingGroupOperationalInfoForm: React.FC<IProps> = ({
  initialValues,
  onChange,
  onSubmit,
  pax,
  cabinCategory,
  brand,
  voyageId,
  paxExtraServices,
  extraServices,
}) => {
  const [dirtyForm, setDirtyForm] = useState(false);
  const history = useHistory();
  const classes = useStyles();

  const handleFormChange = (state: FormState<IPaxOperationalInfoForm>) => {
    setDirtyForm(state.dirty && !state.submitting);
    return onChange(state);
  };

  return (
    <Form<IPaxOperationalInfoForm>
      onSubmit={onSubmit}
      mutators={{
        ...arrayMutators,
        setValue: ([field, value], state, { changeValue }) => {
          changeValue(state, field, () => value);
        },
      }}
      initialValues={initialValues}
      render={({ handleSubmit, submitting, pristine, form }) => {
        return (
          <Container maxWidth="md">
            <form onSubmit={handleSubmit}>
              <FormSpy
                subscription={{ dirty: true, submitting: true }}
                onChange={handleFormChange}
              />

              <Grid
                container
                direction="column"
                spacing={3}
                alignContent="center"
              >
                <Grid item className={classes.operationalInfoGridItem}>
                  <FormSelectAutoCompleteField
                    name="data.requestedCabinId"
                    label="Cabin Request"
                    options={
                      cabinCategory.cabins
                        ? [
                            {
                              value: null,
                              label: '',
                              view: <em>None</em>,
                            },
                            ...cabinCategory?.cabins
                              .map((c) => ({
                                value: c.id,
                                label: c.cabinNumber,
                              }))
                              .sort((a, b) =>
                                a.label.localeCompare(b.label, 'en', {
                                  numeric: true,
                                })
                              ),
                          ]
                        : [
                            {
                              value: null,
                              label: 'Please select a cabin category',
                              view: <em>Please select a cabin category</em>,
                            },
                          ]
                    }
                    className={classes.inputField}
                    toolTip={
                      <span>
                        Cabin request will be based on availability, this is not
                        a confirmed assignment
                      </span>
                    }
                  />
                </Grid>
                <FieldArray<IPaxOperationalInfo> name="data.pax">
                  {({ fields }) =>
                    fields.map((name, index) => {
                      const operationalInfo = fields.value[index];

                      const p = pax.find(
                        (p) => p.id === operationalInfo?.paxId
                      );
                      return (
                        p &&
                        operationalInfo && (
                          <Grid item key={name}>
                            <PaxOperationalInfo
                              paxKey={name}
                              pax={p}
                              paxExtraServices={paxExtraServices.filter(
                                (paxExtraService) =>
                                  paxExtraService.paxId === p.id
                              )}
                              extraServices={extraServices}
                            />
                          </Grid>
                        )
                      );
                    })
                  }
                </FieldArray>
                <Grid item>
                  <FormButtons
                    submit={{ disabled: pristine || submitting }}
                    reset={{
                      disabled: submitting,
                      onClick: () => {
                        !dirtyForm ||
                          (window.confirm(
                            'You have unsaved changes, are you sure you want to reset the form?'
                          ) &&
                            form.reset());
                      },
                    }}
                    cancel={{
                      disabled: submitting,
                      onClick: () =>
                        window.confirm('Are you sure you want to cancel?') &&
                        history.push(Routes.CheckAvailability.route),
                    }}
                  />
                </Grid>
              </Grid>
            </form>
          </Container>
        );
      }}
    />
  );
};

export default SharingGroupOperationalInfoForm;
