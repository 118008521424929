import formatDate from '../../common/helpers/formatDate';
import IVoyage from '../types/IVoyage';
import { differenceInCalendarDays, subDays } from 'date-fns';

export default function getVoyageDropDownLabel(voyage: IVoyage) {
  const diffInDays = differenceInCalendarDays(
    voyage.departureEndDate,
    subDays(voyage.departureDate, 1)
  );
  return `${voyage.season} - ${voyage.tripCode} ${formatDate(
    voyage.departureDate
  )} (${diffInDays}D/${diffInDays - 1}N)`;
}
