export default function isBelowMaxLength(
  value: string | null | undefined,
  maxLength: number
) {
  if (value && value.length > maxLength) {
    return `Value must not exceed ${maxLength} characters`;
  }

  return undefined;
}
