import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import styles from './FormButtons.styles';

const useStyles = makeStyles(styles);

export interface IProps {
  submit: { disabled?: boolean; onClick?: () => void };
  reset: { disabled?: boolean; onClick?: () => void };
  cancel?: { disabled?: boolean; onClick?: () => void };
  submitButtonType?: 'button' | 'submit';
}

const FormButtons: React.FC<IProps> = ({
  submit,
  reset,
  cancel,
  submitButtonType = 'submit',
}) => {
  const classes = useStyles();
  return (
    <div className={classes.buttons}>
      <Button
        variant="contained"
        type={submitButtonType}
        size="small"
        color="primary"
        {...submit}
      >
        Submit
      </Button>
      <Button
        variant="contained"
        type="button"
        size="small"
        color="secondary"
        {...reset}
      >
        Reset
      </Button>
      {cancel && (
        <Button variant="contained" type="button" size="small" {...cancel}>
          Cancel
        </Button>
      )}
    </div>
  );
};

export default FormButtons;
