const env = {
  REACT_APP_DATE_FORMAT: process.env.REACT_APP_DATE_FORMAT!,
  REACT_APP_DATETIME_FORMAT: process.env.REACT_APP_DATETIME_FORMAT!,
  REACT_APP_NAME: process.env.REACT_APP_NAME!,
  REACT_APP_WEB_BASE_URL: process.env.REACT_APP_WEB_BASE_URL!,
  REACT_APP_API_BASE_URL: process.env.REACT_APP_API_BASE_URL!,
  REACT_APP_API_DATE_FORMAT: process.env.REACT_APP_API_DATE_FORMAT!,
  REACT_APP_API_DATETIME_FORMAT: process.env.REACT_APP_API_DATETIME_FORMAT!,
  REACT_APP_AUTH0_DOMAIN: process.env.REACT_APP_AUTH0_DOMAIN!,
  REACT_APP_AUTH0_CLIENT_ID: process.env.REACT_APP_AUTH0_CLIENT_ID!,
  REACT_APP_AUTH0_AUDIENCE: process.env.REACT_APP_AUTH0_AUDIENCE!,
  REACT_APP_FORMS_URL: process.env.REACT_APP_FORMS_URL!,
  REACT_APP_OPTION_EXPIRY_DAYS: process.env.REACT_APP_OPTION_EXPIRY_DAYS!,
};

export default env;
