import React from 'react';
import { Grid, withStyles, WithStyles } from '@material-ui/core';
import IVoyage from '../../common/types/IVoyage';
import ISharingGroup from '../../common/types/ISharingGroup';
import VoyageSelect from '../../common/components/VoyageSelect';
import PaxDetailsTable from '../../common/components/PaxDetailsTable';
import styles from './ManageBookings.styles';
import PageHeader from '../../common/components/PageHeader';

interface IProps extends WithStyles<typeof styles> {
  voyages: IVoyage[];
  sharingGroups?: ISharingGroup[];
  voyageId: number | undefined;
  getSharingGroupsForVoyage: (voyageId: number) => Promise<void>;
  onSelectVoyage: (voyageId: number) => Promise<void>;
}

class ManageBookings extends React.Component<IProps> {
  private handleChangeVoyage = async (e: any) => {
    const voyageId = e.target.value;

    this.setState({ allotmentId: undefined });

    // update pax list
    await Promise.all([
      this.props.getSharingGroupsForVoyage(voyageId),
      this.props.onSelectVoyage(voyageId),
    ]);
  };

  render() {
    const { voyages, sharingGroups, voyageId, classes } = this.props;

    const selectedVoyage = voyages.find((v) => v.id === voyageId);

    return (
      <React.Fragment>
        <PageHeader
          title="Manage Bookings"
          description="Select a Voyage to view the existing passengers."
        />
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="flex-end"
              spacing={5}
            >
              <Grid item>
                <VoyageSelect
                  selectedVoyageId={voyageId}
                  voyages={voyages}
                  onChange={this.handleChangeVoyage}
                />
              </Grid>
            </Grid>
          </Grid>
          {selectedVoyage && sharingGroups && (
            <React.Fragment>
              <Grid item className={classes.gridItemPax}>
                <PaxDetailsTable
                  voyage={selectedVoyage}
                  sharingGroups={sharingGroups}
                  displayCabinCategory="booked"
                />
              </Grid>
            </React.Fragment>
          )}
        </Grid>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(ManageBookings);
