import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Button } from '@material-ui/core';
import IAllotment from '../../common/types/IAllotment';
import formatDate from '../../common/helpers/formatDate';
import AllotmentStatus from '../enums/allotmentStatus';
import styles from './AllotmentHeader.styles';
import Routes from '../../common/constants/routes';
import { brandLabels } from '../../pax/enums/brand';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(styles);

interface IProps {
  allotment: IAllotment;
  showStatus: boolean;
  showActionButtons: boolean;
  onReleaseAllotment?: (allotmentId: number) => void;
  voyageId: number;
}

const AllotmentHeader: React.FC<IProps> = ({
  allotment,
  onReleaseAllotment,
  showStatus = false,
  showActionButtons = false,
  voyageId,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.allotmentHeader}>
      <div className={classes.allotmentDetails}>
        <h3 className={classes.allotmentName}>{allotment.name}</h3>
        <p className={classes.allotmentHeaderContainer}>
          Brand: {allotment.brand && brandLabels[allotment.brand]}
        </p>
        <p className={classes.allotmentHeaderContainer}>
          Expires: {formatDate(allotment.expiryDate)}
        </p>
        <p className={classes.allotmentHeaderContainer}>
          {showStatus && <span>Status: {allotment.status}</span>}
        </p>
      </div>
      {showActionButtons && (
        <div
          onClick={(event) => event.stopPropagation()}
          onFocus={(event) => event.stopPropagation()}
        >
          <Button
            size="small"
            variant="contained"
            color="primary"
            component={RouterLink}
            to={Routes.AllotmentUpdate.getLocation(voyageId, allotment.id)}
            disabled={allotment.status === AllotmentStatus.Released}
            className={classes.allotmentButton}
          >
            Edit
          </Button>
          <Button
            size="small"
            variant="contained"
            color="secondary"
            disabled={allotment.status === AllotmentStatus.Released}
            onClick={(e) =>
              onReleaseAllotment && onReleaseAllotment(allotment.id)
            }
            className={classes.allotmentButton}
          >
            Release
          </Button>
        </div>
      )}
    </div>
  );
};

export default AllotmentHeader;
