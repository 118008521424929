import { createStyles } from '@material-ui/core/styles';

export default createStyles({
  inputField: {
    minWidth: '200px',
  },
  selectFieldsContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  paxAddButton: {
    float: 'right',
  },
  tabsPaper: {
    margin: '20px 0 10px 0',
  },
  tab: {
    minWidth: '250px',
  },
  allotmentAvailabilityGridItem: {
    width: '100%',
  },
  allotmentSelectContainer: {
    textAlign: 'left',
  },
  allotmentAvailabilityContainer: {
    marginTop: '16px',
  },
});
