import * as React from 'react';
import { TextField, MenuItem, withStyles, WithStyles } from '@material-ui/core';
import IVoyage from '../../common/types/IVoyage';
import styles from './VoyageSelect.styles';
import getVoyageDropDownLabel from '../helpers/getVoyageDropDownLabel';

interface IProps extends WithStyles<typeof styles> {
  selectedVoyageId: number | undefined;
  voyages: IVoyage[];
  onChange: (event: React.ChangeEvent<any> | any) => void;
}

var getVoyageSelectOptions = (voyages: IVoyage[]) => {
  const voyageOptions = voyages.map((v) => ({
    value: v.id,
    label: getVoyageDropDownLabel(v),
  }));

  return voyageOptions;
};

const VoyageSelect: React.FC<IProps> = ({
  selectedVoyageId,
  voyages,
  onChange,
  classes,
}) => {
  var options = getVoyageSelectOptions(voyages);

  return (
    <TextField
      select
      label="Voyage"
      value={selectedVoyageId || ''}
      className={classes.dropdown}
      onChange={onChange}
    >
      {options.map((option) => (
        <MenuItem key={option.label} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default withStyles(styles)(VoyageSelect);
