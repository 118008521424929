import { createStyles } from '@material-ui/core/styles';

export default createStyles({
  allotmentHeader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'left',
  },
  allotmentDetails: {
    display: 'contents',
  },
  allotmentName: {
    flexBasis: '30%',
  },
  allotmentButton: {
    margin: '5px',
  },
  allotmentHeaderContainer: {
    padding: '0px 10px 0px 10px',
    textTransform: 'capitalize',
  },
});
