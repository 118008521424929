import {
  setHours,
  setMinutes,
  setSeconds,
  getHours,
  getMinutes,
  getSeconds,
} from 'date-fns';

export default function setCurrentTimeToDate(value: Date | null) {
  if (value) {
    let valueWithTime = new Date(value.getTime());
    let now = new Date();
    valueWithTime = setHours(valueWithTime, getHours(now));
    valueWithTime = setMinutes(valueWithTime, getMinutes(now));
    valueWithTime = setSeconds(valueWithTime, getSeconds(now));
    return valueWithTime;
  }

  return value;
}
