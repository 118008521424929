import BaseService from './BaseService';
import IPaxForm, { IForm } from '../common/types/IPaxForm';
import IVoyage from '../common/types/IVoyage';
import ExternalUserType from '../common/enums/ExternalUserType';

class PaxFormService extends BaseService {
  resourceBase = '/paxform';

  async getPaxFormByVoyageId(voyageId: number): Promise<IPaxForm[]> {
    return await this.get(`${this.resourceBase}/voyage/${voyageId}`);
  }

  async getForms(): Promise<IForm[]> {
    return await this.get(`${this.resourceBase}/forms`);
  }

  async downloadPassengerDetailsCSV(voyage: IVoyage): Promise<void> {
    return await this.download(
      `${this.resourceBase}/download/${voyage.id}`,
      `PreTravelInformation_${voyage.tripCode}.csv`
    );
  }

  async generateExternalUserDownloadLink(
    voyageId: number,
    externalUserType: ExternalUserType
  ): Promise<string> {
    return await this.post(
      `${this.resourceBase}/generateExternalUserDownloadLink/voyage/${voyageId}/externalUserType/${externalUserType}`,
      null
    );
  }
}

const service = new PaxFormService();

export default service;
