import React from 'react';

interface IProps {
  shouldRender: boolean;
}

const ConditionalRenderer: React.FC<IProps> = ({ shouldRender, children }) => {
  return shouldRender ? <React.Fragment>{children}</React.Fragment> : null;
};

export default ConditionalRenderer;
