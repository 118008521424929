import React, { useMemo } from 'react';
import FancyTable from '../../common/components/FancyTable';
import IPax from '../../common/types/IPax';
import { Column, TableState, Filters } from 'react-table';
import createFullName from '../../common/helpers/createFullName';
import PaxStatus, { paxStatusLabels } from '../../pax/enums/paxStatus';
import { Container } from '@material-ui/core';
import formatDate from '../../common/helpers/formatDate';
import { IconButton, Tooltip } from '@material-ui/core';
import Routes from '../../common/constants/routes';
import { Link as RouterLink } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import IVoyage from '../../common/types/IVoyage';
import { brandLabels } from '../../pax/enums/brand';
import PaxSearchResult from '../../common/types/PaxSearchResult';
import MultipleSelectFilter from '../../common/components/MultipleSelectFilter';
import { cabinCatAllVoyages, voyage } from '../../common/helpers/cabinCat';
import { useUser } from '../../common/contexts/UserContext';

type Props = {
  pax: PaxSearchResult[];
  voyages: IVoyage[];
  onFetchData: ({
    filters,
  }: {
    filters?: Filters<PaxSearchResult>;
  }) => Promise<void>;
  totalRecords?: number;
  pageCount?: number;
};

const statusDate = (pax: IPax): Date | null | undefined => {
  switch (pax.status) {
    case PaxStatus.Cancelled:
      return pax.cancelledDate;
    case PaxStatus.Confirmed:
      return pax.confirmedDate;
    case PaxStatus.Option:
      return pax.optionExpiryDate;
    case PaxStatus.ExpiredOption:
      return pax.expiredOptionDate;
    default:
      return;
  }
};

const paxStatusDateTooltips: Readonly<Record<PaxStatus, string>> = {
  [PaxStatus.Option]: 'Option Expiry Date',
  [PaxStatus.Confirmed]: 'Confirmed Date',
  [PaxStatus.Cancelled]: 'Cancelled Date',
  [PaxStatus.ExpiredOption]: 'Expired Option Date',
};

const PaxOverview: React.FC<Props> = ({
  pax,
  voyages,
  onFetchData,
  totalRecords,
  pageCount,
}) => {
  const user = useUser();

  const columns: Column<PaxSearchResult>[] = useMemo(
    () => [
      {
        Header: 'Voyage',
        accessor: ({ bookedCabinCategoryId }) =>
          voyage(voyages)(bookedCabinCategoryId)?.tripCode,
      },
      {
        Header: 'Cabin Category',
        accessor: ({ bookedCabinCategoryId }) =>
          cabinCatAllVoyages(voyages)(bookedCabinCategoryId)?.name,
      },
      {
        Header: 'Brand',
        accessor: 'brand',
        Cell: ({ value }) => brandLabels[value],
        Filter: (x) =>
          user.brands.length > 1 ? MultipleSelectFilter(x, user.brands) : null,
        filter: (rows, _columnIds) => rows, // Disable filter as filter is server-side
      },
      {
        Header: 'Booking Ref',
        accessor: 'bookingReference',
        disableGlobalFilter: false,
      },
      {
        Header: 'Name',
        accessor: ({ firstName, middleName, surname }) =>
          createFullName(firstName, middleName, surname),
        disableGlobalFilter: false,
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value }) => paxStatusLabels[value],
        Filter: (x) =>
          MultipleSelectFilter(x, Object.values(PaxStatus), PaxStatus.Option),
        filter: (rows, _columnIds) => rows, // Disable filter as filter is server-side
      },
      {
        Header: 'Status Date',
        id: 'statusDate',
        accessor: statusDate,
        Cell: ({
          value,
          row: {
            values: { status },
          },
        }: {
          value: Date | null | undefined;
          row: { values: { status: PaxStatus } };
        }) => {
          return (
            value && (
              <Tooltip title={paxStatusDateTooltips[status]}>
                <span>{formatDate(value)}</span>
              </Tooltip>
            )
          );
        },
        sortType: 'datetime',
        disableSortBy: false,
      },
      {
        Header: 'Edit',
        accessor: 'groupId',
        Cell: ({ value }) => (
          <Tooltip title="Edit">
            <IconButton
              aria-label="edit"
              color="inherit"
              component={RouterLink}
              to={Routes.PaxUpdate.getLocation(value)}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        ),
      },
    ],
    [user.brands, voyages]
  );

  const initialState: Partial<TableState<PaxSearchResult>> = useMemo(
    () => ({
      sortBy: [{ id: 'statusDate', desc: false }],
      pageSize: 25,
    }),
    []
  );

  const defaultColumn = useMemo(
    () => ({
      Filter: <React.Fragment />,
      disableSortBy: true,
    }),
    []
  );

  return (
    <Container maxWidth="lg">
      <FancyTable
        columns={columns}
        data={pax}
        initialState={initialState}
        defaultColumn={defaultColumn}
        onFetchData={onFetchData}
        totalRecords={totalRecords}
        pageCount={pageCount}
        manualSortBy
      />
    </Container>
  );
};

export default PaxOverview;
