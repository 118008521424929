import React, { useState, useEffect } from 'react';
import IVoyage from '../../common/types/IVoyage';
import IPaxForm, { IForm } from '../../common/types/IPaxForm';
import { useApi } from '../../common/contexts/ApiContext';
import VoyagesService from '../../services/VoyagesService';
import PaxFormService from '../../services/PaxFormService';
import PreTravelInformation from './PreTravelInformation';
import ISharingGroup from '../../common/types/ISharingGroup';

interface IProps {}

const PreTravelInformationContainer: React.FC<IProps> = () => {
  const [voyages, setVoyages] = useState<IVoyage[]>([]);
  const [selectedVoyage, setSelectedVoyage] = useState<IVoyage | undefined>(
    undefined
  );
  const [paxForms, setPaxForms] = useState<IPaxForm[]>([]);
  const [sharingGroups, setSharingGroups] = useState<ISharingGroup[]>([]);
  const [forms, setForms] = useState<IForm[]>([]);

  const { wrapper } = useApi();

  useEffect(() => {
    const loadVoyages = async () => {
      const loadedVoyages = await wrapper(VoyagesService.getVoyages());
      const loadedForms = await wrapper(PaxFormService.getForms());
      setVoyages(loadedVoyages);
      setForms(loadedForms);
    };
    loadVoyages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const load = async () => {
      if (selectedVoyage?.id) {
        const loadedPaxForms = await wrapper(
          PaxFormService.getPaxFormByVoyageId(selectedVoyage?.id)
        );
        const loadedSharingGroups = await wrapper(
          VoyagesService.getSharingGroups(selectedVoyage.id)
        );
        setPaxForms(loadedPaxForms);
        setSharingGroups(loadedSharingGroups);
      }
    };
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVoyage?.id]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedVoyage(voyages.find((v) => v.id === +event.target.value));
  };

  return (
    <PreTravelInformation
      voyages={voyages}
      onChange={onChange}
      selectedVoyage={selectedVoyage}
      paxForms={paxForms}
      sharingGroups={sharingGroups}
      forms={forms}
    />
  );
};

export default PreTravelInformationContainer;
