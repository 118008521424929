import { createStyles } from '@material-ui/core/styles';

export default createStyles({
  paxExtraServiceHidden: {
    visibility: 'hidden',
  },
  paxDetailsContainer: {
    float: 'left',
    flexBasis: '50%',
    margin: '10px 20px 10px 20px',
    textAlign: 'left',
    padding: '15px 25px 15px 25px',
  },
  paxDetailsContainerNotTakingCapacity: {
    borderColor: 'red',
  },
  submitError: {
    textAlign: 'center',
  },
  buttons: {
    '& > *': {
      margin: '8px',
    },
  },
  paxExtraServicesContainer: {
    margin: 'auto',
  },
  confirmationDialogQuestionContainer: {
    paddingBottom: '10px',
  },
  confirmationDialogPaxNameListItem: {
    paddingBottom: '0px',
    paddingTop: '0px',
  },
  confirmationDialogExtraServiceList: {
    listStyleType: 'circle',
    margin: '5px 0px 5px 0px',
  },
});
