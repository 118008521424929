export default function isPopulated(
  value: Date | number | string | null | undefined
) {
  let val = value;
  if (typeof val === 'string') {
    val = val.trim();
  }

  if (typeof val === 'number' && val === 0) {
    return undefined;
  }

  if (!val) {
    return `Field is required`;
  }
}
