import React from 'react';
import { IconButton, Grid, makeStyles } from '@material-ui/core';
import { DeleteForever } from '@material-ui/icons';
import { FieldArray } from 'react-final-form-arrays';
import classNames from 'classnames';
import styles from './PaxListForm.styles';
import IVoyage from '../../../common/types/IVoyage';
import PaxForm from './PaxForm';
import IPax from '../../../common/types/IPax';
import PaxStatus, { isTakingCapacity } from '../../enums/paxStatus';
import { useUser } from '../../../common/contexts/UserContext';
import Role from '../../../common/enums/Role';

const useStyles = makeStyles(styles);

const PaxArray: React.FC<{ voyage: IVoyage }> = ({ voyage }) => {
  const classes = useStyles();
  const user = useUser();

  const canEditPax = (initial?: IPax, current?: IPax) => {
    // Cannot edit pax when initial status is not option, and user is not Operations Manager
    return (
      user.hasRole(Role.OperationsManager) ||
      initial?.status === undefined ||
      current?.status === PaxStatus.Option ||
      (current?.status === PaxStatus.Confirmed &&
        initial?.status !== PaxStatus.Confirmed)
    );
  };

  return (
    <Grid
      container
      direction="column"
      spacing={3}
      justify="center"
      alignItems="center"
    >
      <FieldArray<IPax | undefined> name="pax">
        {({ fields, meta }) =>
          fields.map((name, index) => {
            const pax = fields.value[index] ?? undefined;

            const paxFormClasses = classNames({
              [classes.paxDetailsContainer]: true,
              [classes.paxDetailsContainerCancelled]:
                pax?.status && !isTakingCapacity(pax.status),
            });

            const disabled = !canEditPax(
              meta?.initial?.[index],
              fields?.value?.[index]
            );

            return (
              <Grid item key={index}>
                <PaxForm
                  paxKey={name}
                  paxIndex={index}
                  voyage={voyage}
                  paxFormClasses={paxFormClasses}
                  disabled={disabled}
                />
                {fields.value[index]?.id === undefined && (
                  <IconButton
                    aria-label="remove"
                    color="secondary"
                    onClick={() =>
                      window.confirm(
                        'Are you sure you want to delete this passenger?'
                      ) && fields.remove(index)
                    }
                  >
                    <DeleteForever />
                  </IconButton>
                )}
              </Grid>
            );
          })
        }
      </FieldArray>
    </Grid>
  );
};

export default PaxArray;
