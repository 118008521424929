import React, { useEffect, useState, useMemo } from 'react';
import { useParams, useHistory } from 'react-router';
import VoyagesService from '../../services/VoyagesService';
import IVoyage from '../../common/types/IVoyage';
import IAllotment from '../../common/types/IAllotment';
import IAllotmentForm from '../types/IAllotmentForm';
import AllotmentForm from './AllotmentForm';
import Routes from '../../common/constants/routes';
import UnprocessableEntity from '../../services/errors/UnprocessableEntity';
import { FORM_ERROR } from 'final-form';
import ForbiddenError from '../../services/errors/ForbiddenError';
import AccessDenied from '../../common/components/AccessDenied';
import NotFound404 from '../../common/components/NotFound404';
import NotFoundError from '../../services/errors/NotFoundError';
import { useApi } from '../../common/contexts/ApiContext';
import { useSnackbar } from '../../common/contexts/SnackbarContext';
import { cabinCatSort } from '../../common/helpers/cabinCat';
import BadRequest from '../../services/errors/BadRequest';

const AllotmentUpdateContainer: React.FC = () => {
  const [voyage, setVoyage] = useState<IVoyage>();
  const [voyageAllotments, setVoyageAllotments] = useState<IAllotment[]>([]);

  const { voyageId, allotmentId } = useParams<{
    voyageId: string;
    allotmentId: string;
  }>();

  const { wrapper, error } = useApi();
  const { showMessage } = useSnackbar();

  const history = useHistory();

  useEffect(() => {
    wrapper(
      Promise.all([
        VoyagesService.getVoyageById(+voyageId),
        VoyagesService.getAllotmentsByVoyage(+voyageId),
      ])
    ).then(([voyage, voyageAllotments]) => {
      setVoyage(voyage);
      setVoyageAllotments(voyageAllotments);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const allotment = useMemo(
    () => voyageAllotments.find((a) => a.id === +allotmentId),
    [allotmentId, voyageAllotments]
  );

  const allotmentAllocations = useMemo(
    () =>
      voyage?.cabinCategories
        .filter(
          (x) =>
            !allotment?.allocations
              .flatMap((z) => z.cabinCategoryId)
              .includes(x.id)
        )
        .map((x) => ({
          cabinCategoryId: x.id,
          cabinCount: 0,
          availability: {
            femaleOnlyBerths: 0,
            maleOnlyBerths: 0,
            unallocatedBerths: 0,
          },
        })) ?? [],
    [allotment, voyage]
  );

  const initialValues = useMemo<IAllotmentForm>(
    () => ({
      data: {
        ...allotment,
        allocations:
          allotment?.allocations
            .concat(allotmentAllocations)
            .sort((a, b) =>
              cabinCatSort(voyage)(a.cabinCategoryId, b.cabinCategoryId)
            ) ?? [],
      },
    }),
    [allotment, allotmentAllocations, voyage]
  );

  const submitAllotment = async (allotmentForm: IAllotmentForm) => {
    try {
      if (!voyage) throw new Error();
      const allotmentUpdated = await wrapper(
        VoyagesService.updateAllotment(
          voyage.id,
          allotmentForm.data.id as number,
          allotmentForm.data as IAllotment
        )
      );

      showMessage('Allotment updated succesfully!', {
        autoHideDuration: 8000,
        disableClickaway: true,
      });

      history.push(Routes.AllotmentSubmitted.route, {
        allotment: allotmentUpdated,
        cabinCategories: voyage.cabinCategories,
        voyageId: voyage.id,
      });
    } catch (ex) {
      if (ex instanceof UnprocessableEntity || ex instanceof BadRequest) {
        return {
          [FORM_ERROR]: ex.errors,
        };
      }
    }
  };

  if (error instanceof NotFoundError) return <NotFound404 />;
  if (error instanceof ForbiddenError) return <AccessDenied />;
  if (!voyage || !voyageAllotments) return null;
  else if (!allotment) return <NotFound404 />;

  return (
    <AllotmentForm
      voyage={voyage}
      onSubmit={submitAllotment}
      updateMode
      initialValues={initialValues}
      defaultAllotment={voyageAllotments.find((a) => a.defaultAllotment)}
    />
  );
};

export default AllotmentUpdateContainer;
