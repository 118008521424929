enum Role {
  OperationsManager = 'Operations Manager',
  Administrator = 'Administrator',
  SalesConsultant = 'Sales Consultant',
  OE_OfficeBasedTeam = 'OE Office Based Team',
  OE_GroundTeam = 'OE Ground Team',
  OE_LandDoctors = 'OE Land Doctors',
}

export default Role;
