import { withStyles, WithStyles, LinearProgress } from '@material-ui/core';
import React, { createContext, useContext, useState } from 'react';
import ApiError from '../../services/errors/ApiError';
import InternalServerError from '../../services/errors/InternalServerError';
import { SnackbarContext } from './SnackbarContext';
import styles from './ApiContext.styles';
import classNames from 'classnames';

export interface IApiContext {
  loading: boolean;
  error?: ApiError;
  wrapper<T>(fn: Promise<T>): Promise<T>;
}

export interface IWithApi {
  api: IApiContext;
}

export const ApiContext = createContext<IApiContext>({
  loading: false,
  wrapper: (fn) => fn,
});

export function withApi<P extends IWithApi>(Component: React.ComponentType<P>) {
  return function WrappedComponent(
    props: Pick<P, Exclude<keyof P, keyof IWithApi>>
  ) {
    return (
      <ApiContext.Consumer>
        {(apiContext) => <Component {...(props as P)} api={apiContext} />}
      </ApiContext.Consumer>
    );
  };
}

export const useApi = () => useContext(ApiContext);

const Provider: React.FC<WithStyles<typeof styles>> = ({
  classes,
  children,
}) => {
  const [loadingCount, setLoadingCount] = useState(0);
  const [error, setError] = useState<ApiError | undefined>();
  const snackbar = useContext(SnackbarContext);

  function wrapper<T>(fn: Promise<T>): Promise<T> {
    setLoadingCount((loadingCount) => loadingCount + 1);
    return fn
      .then((result) => {
        setError(undefined);
        return Promise.resolve(result);
      })
      .catch<T>((e) => {
        if (e instanceof ApiError) setError(e);
        if (e instanceof InternalServerError)
          snackbar.showMessage(e.message, { severity: 'error' });
        return Promise.reject(e);
      })
      .finally(() => {
        setTimeout(
          () => setLoadingCount((loadingCount) => loadingCount - 1),
          100
        );
        return Promise.resolve();
      });
  }

  const loading = loadingCount > 0;
  const loadingBarClasses = classNames({
    [classes.loadingBar]: true,
    [classes.displayed]: loading,
    [classes.hidden]: !loading,
  });

  return (
    <ApiContext.Provider value={{ loading, error, wrapper }}>
      <LinearProgress className={loadingBarClasses} color="secondary" />
      {children}
    </ApiContext.Provider>
  );
};

export const ApiProvider = withStyles(styles)(Provider);
