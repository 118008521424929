import React from 'react';
import { FilterProps } from 'react-table';
import {
  MenuItem,
  IconButton,
  Menu,
  Checkbox,
  ListItemText,
} from '@material-ui/core';
import { FilterList } from '@material-ui/icons';

const SelectCompletionFilter = <T extends object>({
  column: { filterValue, setFilter },
}: FilterProps<T>) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (value?: boolean | null) => () => {
    setAnchorEl(null);
    setFilter(value);
  };

  return (
    <React.Fragment>
      <IconButton
        onClick={handleClick}
        color={filterValue === undefined ? 'default' : 'secondary'}
        size="small"
      >
        <FilterList />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose()}
      >
        <MenuItem onClick={handleClose()}>
          <Checkbox checked={filterValue === undefined} color="primary" />
          <ListItemText primary="All" />
        </MenuItem>
        <MenuItem onClick={handleClose(true)}>
          <Checkbox checked={!!filterValue} color="primary" />
          <ListItemText primary="Complete" />
        </MenuItem>
        <MenuItem onClick={handleClose(false)}>
          <Checkbox checked={filterValue === false} color="primary" />
          <ListItemText primary="Incomplete" />
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default SelectCompletionFilter;
