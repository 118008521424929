import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useHistory } from 'react-router';
import VoyagesService from '../../services/VoyagesService';
import IVoyage from '../../common/types/IVoyage';
import IAllotment from '../../common/types/IAllotment';
import IAllotmentForm from '../types/IAllotmentForm';
import AllotmentForm from './AllotmentForm';
import Routes from '../../common/constants/routes';
import UnprocessableEntity from '../../services/errors/UnprocessableEntity';
import { FORM_ERROR } from 'final-form';
import { useApi } from '../../common/contexts/ApiContext';
import NotFoundError from '../../services/errors/NotFoundError';
import NotFound404 from '../../common/components/NotFound404';
import ForbiddenError from '../../services/errors/ForbiddenError';
import AccessDenied from '../../common/components/AccessDenied';
import { useSnackbar } from '../../common/contexts/SnackbarContext';

const AllotmentInsertContainer: React.FC = () => {
  const [voyage, setVoyage] = useState<IVoyage>();
  const [allotments, setAllotments] = useState<IAllotment[]>();

  const { wrapper, error } = useApi();
  const { showMessage } = useSnackbar();
  const { voyageId } = useParams<{ voyageId: string }>();
  const history = useHistory();

  useEffect(() => {
    wrapper(
      Promise.all([
        VoyagesService.getVoyageById(+voyageId),
        VoyagesService.getAllotmentsByVoyage(+voyageId),
      ])
    ).then(([voyage, voyageAllotments]) => {
      setVoyage(voyage);
      setAllotments(voyageAllotments);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const defaultAllotment = useMemo(
    () => allotments?.find((a) => a.defaultAllotment),
    [allotments]
  );

  const submitAllotment = async (allotmentForm: IAllotmentForm) => {
    if (!voyage) return;
    try {
      const allotment = await wrapper(
        VoyagesService.insertAllotment(
          voyage.id,
          allotmentForm.data as IAllotment
        )
      );

      showMessage('Allotment created succesfully!', {
        autoHideDuration: 8000,
        disableClickaway: true,
      });

      history.push(Routes.AllotmentSubmitted.route, {
        allotment,
        cabinCategories: voyage.cabinCategories,
        voyageId: voyageId,
      });
    } catch (ex) {
      if (ex instanceof UnprocessableEntity) {
        return { [FORM_ERROR]: ex.errors };
      }
    }
  };

  if (error instanceof NotFoundError) return <NotFound404 />;
  if (error instanceof ForbiddenError) return <AccessDenied />;

  if (voyage === undefined || allotments === undefined) return null;

  return (
    <AllotmentForm
      voyage={voyage}
      onSubmit={submitAllotment}
      defaultAllotment={defaultAllotment}
    />
  );
};

export default AllotmentInsertContainer;
