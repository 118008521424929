import React, { useState, useMemo } from 'react';
import IVoyage from '../../common/types/IVoyage';
import ISharingGroup from '../../common/types/ISharingGroup';
import {
  withStyles,
  WithStyles,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import { HashLink } from 'react-router-hash-link';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import EditIcon from '@material-ui/icons/Edit';
import formatDate from '../../common/helpers/formatDate';
import styles from './PaxDetailsTable.styles';
import { sortBy } from 'lodash';
import ICabinCategory from '../../common/types/ICabinCategory';
import IAllotment from '../types/IAllotment';
import { ArrowUpward } from '@material-ui/icons';
import Routes from '../constants/routes';
import { Link as RouterLink } from 'react-router-dom';
import { withRouter, RouteComponentProps } from 'react-router';
import { brandLabels } from '../../pax/enums/brand';
import PaxStatus, {
  paxStatusLabels,
  paxStatusOpts,
} from '../../pax/enums/paxStatus';
import Filters, { IFilters } from './Filters';
import createFullName from '../helpers/createFullName';
import ISharingGroupOperationalInfo from '../types/ISharingGroupOperationalInfo';

interface IProps {
  voyage: IVoyage;
  sharingGroups: ISharingGroup[];
  allotments?: IAllotment[];
  displayCabinCategory: 'booked' | 'current';
  showCabinInfo?: boolean;
  sharingGroupOperationalInfo?: ISharingGroupOperationalInfo[];
}

type Props = IProps & RouteComponentProps & WithStyles<typeof styles>;

const PaxDetailsTable: React.FC<Props> = ({
  sharingGroups,
  voyage,
  allotments,
  displayCabinCategory,
  classes,
  history,
  showCabinInfo = false,
  sharingGroupOperationalInfo,
}) => {
  const [filters, setFilters] = useState<IFilters>({
    statuses: [PaxStatus.Option, PaxStatus.Confirmed],
  });

  const sharingGroupsFiltered = useMemo(
    () =>
      sharingGroups
        .map((s) => ({
          ...s,
          pax: s.pax.filter((p) => filters.statuses.includes(p.status)),
        }))
        .filter((s) => s.pax.length > 0),
    [filters.statuses, sharingGroups]
  );

  var cabinCategories: ICabinCategory[] = useMemo(
    () =>
      voyage.cabinCategories.sort((a, b) =>
        a.name.localeCompare(b.name, 'en', {
          numeric: true,
        })
      ),
    [voyage.cabinCategories]
  );

  const handleStatusChange = (e: React.ChangeEvent<any> | any) => {
    setFilters({ ...filters, statuses: e.target.value });
  };

  const currentLocation = history.location;

  return (
    <div className={classes.paxContainer}>
      <Grid container direction="column">
        <Grid item xs className={classes.paxSectionTitle}>
          Passenger List by Cabin Category
        </Grid>
        <Grid>
          <Filters
            filters={filters}
            handleStatusChange={handleStatusChange}
            statusesOptions={paxStatusOpts}
          />
        </Grid>
        <Grid item xs>
          <Table
            className={classes.paxTable}
            size="small"
            aria-label="a dense table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <TableCell>Brand</TableCell>
                <TableCell>Booking Ref</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Gender</TableCell>
                <TableCell>Date of Birth</TableCell>
                {allotments && <TableCell>Allotment</TableCell>}
                <TableCell>Single Supp.</TableCell>
                {sharingGroupOperationalInfo && <TableCell>Notes</TableCell>}
                {showCabinInfo && <TableCell>Cabin Requested</TableCell>}
                {showCabinInfo && <TableCell>Assigned Cabin</TableCell>}
                {showCabinInfo && <TableCell>Upgraded?</TableCell>}
                <TableCell>Created Date</TableCell>
                <TableCell>Updated Date</TableCell>
                <TableCell align="center">
                  <Tooltip title="Scroll to top">
                    <IconButton className={classes.scrollToTopButton}>
                      <HashLink
                        to={{
                          hash: '#root',
                          pathname: currentLocation.pathname,
                          search: currentLocation.search,
                        }}
                        smooth
                      >
                        <KeyboardArrowUpIcon />
                      </HashLink>
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cabinCategories.map((cc) => {
                const cabinCategoryShareGroups = sharingGroupsFiltered
                  .filter((sg) =>
                    displayCabinCategory === 'booked'
                      ? sg.bookedCabinCategoryId === cc.id
                      : sg.cabinCategoryId === cc.id
                  )
                  .sort((obj1, obj2) => {
                    if (obj1.bookedAllotmentId > obj2.bookedAllotmentId)
                      return 1;
                    return -1;
                  });

                return (
                  <React.Fragment key={'cabinCategory' + cc.id}>
                    <TableRow
                      key={'cabinCategoryHeader_' + cc.id}
                      className={classes.paxRowHeader}
                    >
                      <TableCell component="th" scope="row" colSpan={15}>
                        <div
                          data-attr={`cabinCategory_${cc.id}.name`}
                          id={`cabinCategoryId_${cc.id}`}
                          className={classes.cabinCategoryDetails}
                        >
                          {cc.name}
                        </div>
                      </TableCell>
                    </TableRow>

                    {cabinCategoryShareGroups.map((sharingGroup) => {
                      const sortedPaxList = sortBy(
                        sharingGroup.pax,
                        (pax) =>
                          (pax.surname
                            ? `${pax.surname.trim().toLowerCase()}`
                            : '') ||
                          (pax.firstName
                            ? `${pax.firstName.trim().toLowerCase()}`
                            : '')
                      );
                      const allotmentName = allotments?.find(
                        (a) =>
                          a.defaultAllotment === false &&
                          a.id === sharingGroup.bookedAllotmentId
                      )?.name;

                      const sgOperationalInfo = sharingGroupOperationalInfo?.find(
                        (oi) => oi.sharingGroupGuid === sharingGroup.groupId
                      );
                      return (
                        <React.Fragment key={'csId_' + sharingGroup.groupId}>
                          {sortedPaxList.map((pax) => {
                            const paxOi = sgOperationalInfo?.pax.find(
                              (p) => pax.id === p.paxId
                            );

                            return (
                              <React.Fragment key={'paxId_' + pax.id}>
                                <TableRow key={'cabinCategoryPaxId_' + pax.id}>
                                  <TableCell className={classes.paxCell}>
                                    <div data-attr={`pax_${pax.id}.brand`}>
                                      {brandLabels[sharingGroup.brand]}
                                    </div>
                                  </TableCell>
                                  <TableCell className={classes.paxCell}>
                                    <div
                                      data-attr={`pax_${pax.id}.bookingReference`}
                                    >
                                      {pax.bookingReference}
                                    </div>
                                  </TableCell>
                                  <TableCell className={classes.paxCell}>
                                    <div data-attr={`pax_${pax.id}.status`}>
                                      {pax.status &&
                                        paxStatusLabels[pax.status]}
                                    </div>
                                  </TableCell>
                                  <TableCell className={classes.paxCell}>
                                    <div data-attr={`pax_${pax.id}.name`}>
                                      {createFullName(
                                        pax.firstName,
                                        pax.middleName,
                                        pax.surname
                                      )}
                                    </div>
                                  </TableCell>
                                  <TableCell
                                    className={classes.paxCellCapitalize}
                                  >
                                    <div data-attr={`pax_${pax.id}.gender`}>
                                      {pax.gender}
                                    </div>
                                  </TableCell>
                                  <TableCell className={classes.paxCell}>
                                    <div
                                      data-attr={`pax_${pax.id}.dateOfBirth`}
                                    >
                                      {pax.dateOfBirth &&
                                        formatDate(pax.dateOfBirth)}
                                    </div>
                                  </TableCell>
                                  {allotments && (
                                    <TableCell className={classes.paxCell}>
                                      <div
                                        data-attr={`pax_${pax.id}.Allotment`}
                                      >
                                        {allotmentName}
                                      </div>
                                    </TableCell>
                                  )}
                                  <TableCell className={classes.paxCell}>
                                    <div
                                      data-attr={`pax_${pax.id}.singleSupplement`}
                                    >
                                      {(displayCabinCategory === 'booked'
                                        ? sharingGroup.bookedSingleSupplement
                                        : sharingGroup.singleSupplement) && 'Y'}
                                    </div>
                                  </TableCell>
                                  {sharingGroupOperationalInfo && (
                                    <TableCell
                                      className={`${classes.paxCell} ${classes.paxNotesColumn}`}
                                    >
                                      <div data-attr={`pax_${pax.id}.notes`}>
                                        {paxOi?.notes}
                                      </div>
                                    </TableCell>
                                  )}
                                  {sharingGroupOperationalInfo &&
                                    showCabinInfo && (
                                      <TableCell className={classes.paxCell}>
                                        <div>
                                          {/* Requested cabin only applies to the bookedCabinCategory
                                        so we search for the bookedCabinCategory
                                        as the cabinCategory in this closure can be booked or current*/}
                                          {
                                            cabinCategories
                                              .find(
                                                (x) =>
                                                  x.id ===
                                                  sharingGroup.bookedCabinCategoryId
                                              )
                                              ?.cabins?.find(
                                                (c) =>
                                                  c.id ===
                                                  sgOperationalInfo?.requestedCabinId
                                              )?.cabinNumber
                                          }
                                        </div>
                                      </TableCell>
                                    )}
                                  {showCabinInfo && (
                                    <TableCell className={classes.paxCell}>
                                      <div>
                                        {
                                          cc.cabins.find(
                                            (c) =>
                                              c.id ===
                                              sharingGroup.assignedCabinId
                                          )?.cabinNumber
                                        }
                                      </div>
                                    </TableCell>
                                  )}
                                  {showCabinInfo && (
                                    <TableCell className={classes.paxCell}>
                                      <div>
                                        {sharingGroup.bookedCabinCategoryId !==
                                          sharingGroup.cabinCategoryId && 'Y'}
                                      </div>
                                    </TableCell>
                                  )}
                                  <TableCell className={classes.paxCell}>
                                    <div
                                      data-attr={`pax_${pax.id}.createdDate`}
                                    >
                                      {formatDate(pax.createdDate)}
                                    </div>
                                  </TableCell>
                                  <TableCell className={classes.paxCell}>
                                    <div
                                      data-attr={`pax_${pax.id}.updatedDate`}
                                    >
                                      {formatDate(pax.updatedDate)}
                                    </div>
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.paxCell}
                                  >
                                    <div
                                      data-attr={`pax_${pax.id}.upgrade-edit-btn`}
                                    >
                                      {history.location.pathname.includes(
                                        Routes.VoyageManagement.route
                                      ) && (
                                        <Tooltip title="Upgrade Cabin Category">
                                          <IconButton
                                            aria-label="upgrade"
                                            color="inherit"
                                            component={RouterLink}
                                            to={Routes.PaxCabinCategoryUpgrade.getLocation(
                                              voyage.id,
                                              sharingGroup.groupId
                                            )}
                                          >
                                            <ArrowUpward />
                                          </IconButton>
                                        </Tooltip>
                                      )}
                                      <Tooltip title="Edit">
                                        <IconButton
                                          aria-label="edit"
                                          color="inherit"
                                          component={RouterLink}
                                          to={Routes.PaxUpdate.getLocation(
                                            sharingGroup.groupId
                                          )}
                                        >
                                          <EditIcon />
                                        </IconButton>
                                      </Tooltip>
                                    </div>
                                  </TableCell>
                                </TableRow>
                              </React.Fragment>
                            );
                          })}
                          <TableRow>
                            <TableCell scope="row" colSpan={15} />
                          </TableRow>
                        </React.Fragment>
                      );
                    })}
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </div>
  );
};

export default withRouter(withStyles(styles)(PaxDetailsTable));
