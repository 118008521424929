import React, { ReactNode, ReactNodeArray } from 'react';
import {
  Container,
  Typography,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import styles from './PageHeader.styles';
import PageTitle from './PageTitle';
import PageDescription from './PageDescription';
import IVoyage from '../types/IVoyage';
import IAllotment from '../types/IAllotment';

interface IProps extends WithStyles<typeof styles> {
  title: string;
  description?: ReactNode | ReactNodeArray;
  voyage?: IVoyage;
  allotment?: IAllotment;
  showAllotment?: boolean;
}

const PageHeader: React.FC<IProps> = ({
  children,
  classes,
  title,
  description,
  voyage,
  allotment,
  showAllotment = false,
}) => {
  return (
    <Container maxWidth="lg" className={classes.container}>
      <PageTitle label={title} />
      {description && <PageDescription text={description} />}
      {voyage && (
        <Typography variant="subtitle1">Voyage: {voyage.tripCode}</Typography>
      )}
      {allotment && (!allotment.defaultAllotment || showAllotment) && (
        <Typography variant="subtitle1">Allotment: {allotment.name}</Typography>
      )}
      {children}
    </Container>
  );
};

export default withStyles(styles)(PageHeader);
